
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class AdminChangePassword extends Vue {
  @Prop()
    old!: IAdmin;

  currentAdmin = stores.admin;
  user: IAdmin = JSON.parse(JSON.stringify(this.old));
  error: boolean = false;
  errorpasswordlength: boolean = false;
  errorpasswordmatch: boolean = false;
  /**
   * Checks if the 2 passwords match, then emits the changes to the main page
   */
  save(): void {
    if (this.user.password === this.user.passwordConfirmation) {
      this.$emit('save', this.user);

      this.error = false;
    } else {
      this.error = true;
    }
  }
   cancel(): void {
    this.error = false;
    this.user.password = '',
    this.user.passwordConfirmation = '';
    // @ts-ignore
    this.$children[0].close();
  }
  checkValiditys() {
    console.log('passwordRules');
    const password = document.getElementById('password');
    const passwordConfirmation = document.getElementById('password_confirmation');
    if (this.user.password.length < 8) {
      this.errorpasswordlength = true;
      this.errorpasswordmatch = false;
      // @ts-ignore
      password.style.borderColor = '#f46a6a';
    } else {
      if (this.user.password === this.user.passwordConfirmation) {
        console.log('passwordRules yes');
        // this.$emit('save', this.user);
        this.errorpasswordlength = false;
        this.errorpasswordmatch = false;
        // @ts-ignore
        password.style.borderColor = '#34c38f';
        // @ts-ignore
        passwordConfirmation.style.borderColor = '#34c38f';
      } else {
        console.log('passwordRules no', this.user.passwordConfirmation);
        this.errorpasswordlength = false;
        this.errorpasswordmatch = true;
        // @ts-ignore
        password.style.borderColor = '#f46a6a';
        // @ts-ignore
        passwordConfirmation.style.borderColor = '#f46a6a';
      }
    }
  }

}
