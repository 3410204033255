
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
// @ts-ignore
import draggable from 'vuedraggable';
import stores from '@/stores';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
import TvModule from '@/stores/modules/TvModule';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})

export default class AttachEPGToTvChannel extends Vue {
  @Prop()
    module!: TvModule;
  @Prop()
    epgs!: any;

  searchQuery: string = '';

  search() {
    this.$emit('search', this.searchQuery);
  }

  attach(channel: string) {
    // @ts-ignore
    this.$emit('attach', channel);
    this.cancel();
  }

  cancel(e: any = null) {
    if (e) {
      e.preventDefault();
    }
    // @ts-ignore
    this.$children[0].close();
  }

}
