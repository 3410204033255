
// @ts-ignore
import Switches from "vue-switches";
// @ts-ignore
import { Component, Vue } from "vue-property-decorator";
import stores from "@/stores";
import NevronHeader from "@/components/NevronHeader.vue";
import { Setting } from "@/stores/Setting";
import routerBase from "@/helpers/routerBase";
import Skeleton from "@/modules/Skeleton.vue";
import { showToaster } from '@/components/DashboardComposer/helpers';
@Component({
  components: {
    NevronHeader,
    Skeleton,
    showToaster,
    Switches,
  },
})
export default class AppSetting extends Vue {
  apiUrl = `${stores.master.url}/${routerBase}`;

  transcoder: ISetting = new Setting({ key: "transcoder", value: "" });
  recoderLink1: ISetting = new Setting({ key: "recoder_link1", value: "" });
  recoderLink2: ISetting = new Setting({ key: "recoder_link2", value: "" });
  clientUrl: ISetting = new Setting({ key: "client_url", value: "" });
  matomoUrl: ISetting = new Setting({ key: "matomo_url", value: "" });
  applicationMode?: ISetting = new Setting({ key: "application_mode", value: "" });
  pwaUrl: ISetting = new Setting({ key: "pwa_url", value: "" });
  reservationId: ISetting = new Setting({ key: "reservation_id", value: "" });
  mlAndriodAppIdentifier: ISetting = new Setting({
    key: "ml_android_app_identifier",
    value: "",
  });
  mlLinkToAppStore: ISetting = new Setting({ key: "ml_link_to_app_store", value: "" });
  mlAppleAppIdentifier: ISetting = new Setting({
    key: "ml_apple_app_identifier",
    value: "",
  });
  is_cart_email_enabled: any = '';
  is_cart_room_number_enabled: any = '';
  is_cart_email_required: any = '';
  is_cart_room_number_required: any = '';
  mlLinkToPlayStore: ISetting = new Setting({ key: "ml_link_to_play_store", value: "" });
  cart_room_number_enabled: ISetting = new Setting({ key: "cart_room_number_enabled", value: "" });
  cart_room_number_required: ISetting = new Setting({ key: "cart_room_number_required", value: "" });
  cart_email_enabled: ISetting = new Setting({ key: "cart_email_enabled", value: "" });
  cart_email_required: ISetting = new Setting({ key: "cart_email_required", value: "" });
  login_pwa: ISetting = new Setting({ key: "login_pwa", value: "" });
  login_app: ISetting = new Setting({ key: "login_app", value: "" });
  app_banners: ISetting = new Setting({ key: "app_banners", value: "" });

  copied = {
    apiUrl: false,
    clientUrl: false,
    matomoUrl: false,
  };

  srCharCount = 0;

  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName("app-configuration");
    stores.Setting.getSettings().then((response: any) => {
      this.settings(response);
    });
  }

  makeUpperCase() {
    this.reservationId.value = this.reservationId.value.toUpperCase();
    this.srCharCount = this.reservationId.value.length;
  }
  change_cart_room_number_enabled(cart_room_number_enabled_id:any,cart_room_number_enabled_value:any) {
    this.is_cart_room_number_enabled = cart_room_number_enabled_value ? true : false;
    this.cart_room_number_required.value = cart_room_number_enabled_value ? this.is_cart_room_number_required : false;
    this.updateSetting(cart_room_number_enabled_id, cart_room_number_enabled_value, 0);
    this.updateSetting(this.cart_room_number_required.id, this.cart_room_number_required.value, 0);
  }
  change_cart_email_enabled(cart_email_enabled_id:any,cart_email_enabled_value: any) {
    this.is_cart_email_enabled = cart_email_enabled_value ? true : false;
    this.cart_email_required.value = cart_email_enabled_value ? this.is_cart_email_required : false;
    this.updateSetting(cart_email_enabled_id, cart_email_enabled_value, 0);
    this.updateSetting(this.cart_email_required.id, this.cart_email_required.value,0);

  }
  settings(data: ISetting[]) {
    // @ts-ignore
    for (const index in data) {
      if (data[index].key === "transcoder_url") {
        this.transcoder = data[index];
      } else if (data[index].key === "recoder_url_1") {
        this.recoderLink1 = data[index];
      } else if (data[index].key === "recoder_url_2") {
        this.recoderLink2 = data[index];
      } else if (data[index].key === "client_url") {
        data[index].value += `/${routerBase}`;
        this.clientUrl = data[index];
      } else if (data[index].key === "matomo_url") {
        this.matomoUrl = data[index];
      } else if (data[index].key === "pwa_url") {
        this.pwaUrl = data[index];
      } else if (data[index].key === "reservation_id") {
        this.reservationId = data[index];
        this.srCharCount = this.reservationId.value.length;
      } else if (data[index].key === "ml_apple_app_identifier") {
        this.mlAppleAppIdentifier = data[index];
      } else if (data[index].key === "ml_andriod_app_identifier") {
        this.mlAndriodAppIdentifier = data[index];
      } else if (data[index].key === "ml_link_to_app_store") {
        this.mlLinkToAppStore = data[index];
      } else if (data[index].key === "ml_link_to_play_store") {
        this.mlLinkToPlayStore = data[index];
      } else if (data[index].key === "application_mode") {
        this.applicationMode = data[index];
      } else if (data[index].key === "cart_room_number_enabled") {
        this.cart_room_number_enabled = data[index];
        this.cart_room_number_enabled.value = this.cart_room_number_enabled.value == 0 ? false : true;
        this.is_cart_room_number_enabled = this.cart_room_number_enabled.value == 0 ? false : true;
      } else if (data[index].key === "cart_room_number_required") {
        this.cart_room_number_required = data[index];
        this.cart_room_number_required.value = this.cart_room_number_required.value == 0 ? false : true;
        this.is_cart_room_number_required = this.cart_room_number_required.value == 0 ? false : true;
      } else if (data[index].key === "cart_email_enabled") {
        this.cart_email_enabled = data[index];
        this.cart_email_enabled.value = this.cart_email_enabled.value == 0 ? false : true;
        this.is_cart_email_enabled=this.cart_email_enabled.value == 0 ? false : true;
      } else if (data[index].key === "cart_email_required") {
        this.cart_email_required = data[index];
        this.is_cart_email_required = this.cart_email_required.value == 0 ? false : true;
        this.cart_email_required.value = this.cart_email_required.value == 0 ? false : true;
      }else if (data[index].key === "login_app") {
        this.login_app = data[index];
        this.login_app.value = this.login_app.value == 0 ? false : true;
      }else if (data[index].key === "login_pwa") {
      this.login_pwa = data[index];
      this.login_pwa.value = this.login_pwa.value == 0 ? false : true;
      }
      else if (data[index].key === "app_banners") {
        this.app_banners = data[index];
        this.app_banners.value = this.app_banners.value == 0 ? false : true;
      }

    }
  }
  // updatingCartFlow(cart_room_number_enabled:any,cart_room_number_required:any,cart_email_required:any,cart_email_enabled:any){
  //   this.updateSetting(cart_room_number_enabled.id, cart_room_number_enabled.value,0);
  //   this.updateSetting(cart_room_number_required.id, cart_room_number_required.value,0);
  //   this.updateSetting(cart_email_required.id, cart_email_required.value,0);
  //   this.updateSetting(cart_email_enabled.id, cart_email_enabled.value, 0);
  //   showToaster('success', Vue.prototype.translate('setting'), 'successfully updated');
  // }
  updateSetting(id: any, value: any, settingType: 0) {
    stores.Setting.update(id, { value, settingType: settingType }).then(
      (response: any) => {
        console.log(response);
        console.log("setting updated successfully..");
      }
     );
  }

  copyText(text: string) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    if (text === this.apiUrl) {
      this.copied.apiUrl = true;
    } else if (text === this.clientUrl?.value) {
      this.copied.clientUrl = true;
    } else if (text === this.matomoUrl?.value) {
      this.copied.matomoUrl = true;
    }
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }

  updatingStayReservationId(id: any, value: any) {
    if (this.srCharCount >= 3) {
      this.updateSetting(id, value, 0);
    }
  }
}
