export const dynamicModules = ['VIDEO', 'SERIES', 'TV_RADIO', 'CATALOGUE', 'SERVICE', 'APPLICATION', 'MOD', 'AOD'];

export enum ModuleTypePathMap {
  'APPLICATION' = 'applications',
  'TV_APPLICATION' = 'applications',
  'WEBSITE_APPLICATION' = 'applications',
  'SHIP_POSITION_APPLICATION' = 'applications',
  'TV_RADIO' = 'tv',
  'SERIES' = 'series',
  'VIDEO' = 'video',
  'MOD' = 'mod',
  'AOD' = 'aod',
  'CATALOGUE' = 'catalogue',
  'SERVICE' = 'service',
  'TIME' = 'time',
  'WEATHER' = 'weather',
}

export interface IModuleField {
  name?: string;
  displayName?: string;
  type?: string;
  subFields?: string[];
  backupName?: string;
  emptyError?: string;
  notTranslatable?: boolean;
  route?: string; // for links
}

export interface IModuleMetaData {
  displayName?: string;
  createComponent?: string; // path to the create component
  tableName?: string;
  noActive?: boolean;
  fields?: IModuleField[];
  // For displaying items in a table
  tableSection?: {
    fields?: IModuleField[];
    noActive?: boolean;
  };

  [key: string]: any;
}

export const modulesMetaData: { [key: string]: IModuleMetaData } = {

  customers: {
    displayName: 'customers',
    createComponent: 'modules/Customers/Create',
    checkIn: 'modules/Customers/CheckIn',
    tableName: 'customers',
    noActive: true,
    fields: [
      {
        displayName: 'name',
        type: 'dual',
        name: 'firstName',
        backupName: 'lastName',
        emptyError: 'no-name-defined',
      },
      {
        displayName: 'location',
        type: 'object',
        name: 'stays',
        subFields: ['locations'],
      },
      {
        displayName: 'check-in',
        type: 'object',
        name: 'stays',
        subFields: ['checkinAt'],
        notTranslatable: true,

      },
      {
        displayName: 'check-out',
        type: 'object',
        name: 'stays',
        subFields: ['checkoutAt'],
        notTranslatable: true,

      },
      {
        displayName: 'stay',
        type: 'object',
        name: 'stays',
        subFields: ['status'],
        notTranslatable: true,
      },
      {
        displayName: 'status',
        name: 'isStaff',
        notTranslatable: true,

      },
    ],
    modalAttachToUser: {
      displayName: 'link-customer',
      selectOne: true,
    },
    modalAddRecipient: {
      displayName: 'add-recipients',
      selectOne: false,
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {
          displayName: 'name',
          type: 'dual',
          name: 'firstName',
          backupName: 'lastName',
          emptyError: 'no-name-defined',
        },
        {
          displayName: 'location',
          type: 'object',
          name: 'stays',
          subFields: ['locations'],
          emptyError: ' ',
        },
        {
          displayName: 'check-in',
          type: 'object',
          name: 'stays',
          subFields: ['checkinAt'],
          notTranslatable: true,

        },
        {
          displayName: 'check-out',
          type: 'object',
          name: 'stays',
          subFields: ['checkoutAt'],
          notTranslatable: true,

        },
        {
          displayName: 'stay',
          type: 'object',
          name: 'stays',
          subFields: ['status'],
          notTranslatable: true,

        },
        {
          displayName: 'status',
          name: 'isStaff',
          notTranslatable: true,

        },
      ],
    },
    tableSectionStays: {
      fields: [
        {
          displayName: 'name',
          type: 'dual',
          name: 'firstName',
          backupName: 'lastName',
          emptyError: 'no-name-defined',
        },
        {
          displayName: 'main-customer',
          type: 'radio',
          name: 'mainCustomer',
        },
      ],
    },
    tableCreateStay: {
      selectOne: true,
    },
  },

  interests: {
    displayName: 'interests',
    createComponent: 'modules/Interests/Create',
    fields: [
      {
        displayName: '',
        type: 'image',
        name: 'image',
      },
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  guestflowmodule: {
    displayName: 'guest-flow-modules',
    tableName: 'guest_flow_modules',
    fields: [
      {
        displayName: '',
        name: 'image',
        type: 'image',
      },
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
    options: {
      hideDelete: true,
    },
  },

  stays: {
    displayName: 'stays',
    createComponent: 'modules/Stays/Create',
    tableName: 'stays',
    noActive: true,
    fields: [
      {
        displayName: 'main-customer',
        type: 'object',
        name: 'mainCustomer',
        subFields: ['firstName', 'lastName'],
        emptyError: 'main-customer-not-set',
      },
      {
        displayName: 'number-of-customers',
        notTranslatable: true,
        name: 'customersCount',
      },
      {
        displayName: 'check-in-date',
        type: 'date',
        name: 'checkinAt',
        emptyError: 'no-date',
      },
      {
        displayName: 'check-out-date',
        type: 'date',
        name: 'checkoutAt',
        emptyError: 'no-date',
      },
      {
        displayName: 'status',
        name: 'status',
      },
    ],
    // table meta for show pages
    tableSection: {
      noActive: true,
      fields: [
        {
          displayName: 'main-customer',
          type: 'object',
          name: 'mainCustomer',
          subFields: ['firstName', 'lastName'],
          emptyError: 'main-customer-not-set',
        },
        {
          displayName: 'number-of-customers',
          notTranslatable: true,
          name: 'customersCount',
        },
        {
          displayName: 'check-in-date',
          type: 'date',
          name: 'checkinAt',
          emptyError: 'no-date',
        },
        {
          displayName: 'check-out-date',
          type: 'date',
          name: 'checkoutAt',
          emptyError: 'no-date',
        },
        {
          displayName: 'status',
          name: 'status',
        },
      ],
    },
  },

  stayTypes: {
    displayName: 'stay-types',
    createComponent: 'modules/Stays/Types/Create',
    tableName: 'stay_categories',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  promotions: {
    displayName: 'promotions',
    createComponent: 'modules/Promotions/Create',
    tableName: 'promotions',
    fields: [
      {
        displayName: '',
        name: 'image',
        type: 'image',
        subFields: ['image'],
      },
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
      {
        displayName: 'valid-to',
        name: 'validTo',
        emptyError: 'no-date-defined',
        notTranslatable: true,
      },
    ],
    // table meta for show pages
    tableSection: {
      fields: [
        {
          displayName: '',
          name: 'image',
          type: 'image',
          subFields: ['image'],
        },
        {
          displayName: 'name',
          name: 'name',
          emptyError: 'no-name-defined',
        },
        {
          displayName: 'valid-to',
          name: 'validTo',
          emptyError: 'no-date-defined',
          notTranslatable: true,
        },
      ],
    },

  },

  manageProperty: {
    displayName: 'manage-property',
  },
  assets: {
    displayName: 'assets',
    createComponent: 'modules/PropertyAssets/Assets/Create',
    tableName: 'property_assets',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },
  },
  assetCategory: {
    displayName: 'asset-categories',
    createComponent: 'modules/PropertyAssets/Assets/Category/Create',
    tableName: 'asset_categories',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  contactModule: {
    displayName: 'contacts',
    createComponent: 'modules/ContactModule/Create',
    tableName: 'contacts',
    fields: [
      {
        displayName: 'contact-name',
        name: 'name',
        emptyError: 'No Name Defined',
      },
      {
        displayName: 'contact-number',
        name: 'number',
        emptyError: 'No Name Defined',
        notTranslatable: true,
      },
    ],
  },
  location: {
    displayName: 'locations',
  },
  zones: {
    displayName: 'location',
    createComponent: 'modules/PropertyAssets/Locations/Zones/Create',
    tableName: 'locations',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
    modalAttachToDevice: {
      displayName: 'link-location',
      selectOne: true,
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },
  },

  zoneCategory: {
    displayName: 'location-type',
    createComponent: 'modules/PropertyAssets/Locations/Category/Create',
    tableName: 'location_categories',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  library: {
    displayName: 'documents',
    createComponent: 'modules/PropertyAssets/Library/Create',
    tableName: 'accommodation_libraries',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },
  },

  ratingPlatform: {
    displayName: 'rating-platforms',
    createComponent: 'modules/PropertyAssets/Media/RatingPlateform/Create',
    tableName: 'rating_platforms',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  commentProposal: {
    displayName: 'social-media-templates',
    createComponent: 'modules/PropertyAssets/Media/CommentProposals/Create',
    tableName: 'comment_proposals',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  socialMedia: {
    displayName: 'social-media-platforms',
    createComponent: 'modules/PropertyAssets/Media/SocialMedia/Create',
    tableName: 'social_media_platforms',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  stbDashboards: {
    displayName: 'stb-dashboards',
    createComponent: 'modules/Dashboards/Create',
    tableName: 'dashboards',
    fields: [
      {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      {displayName: 'default', name: 'default', type: 'radio'},
      {displayName: 'public', name: 'public', type: 'radio'},
    ],
    modalAttach: {
      selectOne: true,
      displayName: 'link-stb-dashboard',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },

  },

  mobileDashboards: {
    displayName: 'mobile-dashboards',
    createComponent: 'modules/Dashboards/Create',
    tableName: 'dashboards',
    fields: [
      {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      {displayName: 'default', name: 'default', type: 'radio'},
      {displayName: 'public', name: 'public', type: 'radio'},
    ],
    modalAttach: {
      selectOne: true,
      displayName: 'link-mobile-dashboard',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },
  },
  gfMobileDashboard: {
    displayName: 'gf-mobile-dashboards',
    tableName: 'dashboards',
    fields: [
      {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      {displayName: 'default', name: 'default', type: 'radio'},
      {displayName: 'public', name: 'public', type: 'radio'},
    ],
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },
  },

  modules: {
    displayName: 'modules',
    createComponent: 'modules/Modules/Create',
    tableName: 'modules',
    fields: [
      {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      {displayName: 'type', name: 'type', notTranslatable: true},
      {displayName: 'id', name: 'id', notTranslatable: true},
    ],
    attachModalPages: {
      displayName: 'link-catalog-modules',
      optionalParams: 'type=CATALOGUE',
    },
    attachModalService: {
      displayName: 'link-service-modules',
      optionalParams: 'type=SERVICE',
    },
  },

  accounts: {
    displayName: 'accounts',
    createComponent: 'modules/Accounts/Create',
    tableName: 'accounts',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
      {
        displayName: 'customer',
        type: 'object',
        name: 'customer',
        subFields: ['firstName', 'lastName'],
        emptyError: 'no-customer-defined',
        route: 'customers.show',
      },
    ],
    modalAttach: {
      displayName: 'link-accounts',
      selectOne: false, // when only one instance is needed (radio instead of checkbox)
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
        {
          displayName: 'customer',
          type: 'object',
          name: 'customer',
          subFields: ['firstName', 'lastName'],
          emptyError: 'no-customer-defined',
          route: 'customers.show',
        },
      ],
    },
    modalAttachToDevice: {
      displayName: 'link-accounts',
      selectOne: true,
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },
  },

  users: {
    displayName: 'users',
    createComponent: 'modules/Users/Create',
    tableName: 'users',
    fields: [
      {
        displayName: 'username',
        name: 'username',
        notTranslatable: true,
        emptyError: 'no-name-defined',
      },
      {
        displayName: 'customer',
        type: 'object',
        name: 'customer',
        subFields: ['firstName', 'lastName'],
        emptyError: 'no-customer-defined',
        route: 'customers.show',
      },
      {
        displayName: 'email',
        name: 'email',
        notTranslatable: true,
        emptyError: 'no-email-defined',
      },
    ],
    modalAttach: {
      displayName: 'link-users',
      selectOne: false, // when only one instance is needed (radio instead of checkbox)
      fields: [
        {
          displayName: 'name',
          name: 'username',
          emptyError: 'No Name Defined',
          notTranslatable: true,
        },
        {
          displayName: 'customer',
          type: 'object',
          name: 'customer',
          subFields: ['firstName', 'lastName'],
          emptyError: 'no-customer-defined',
          route: 'customers.show',
        },
      ],
    },
    modalAttachToCustomer: {
      displayName: 'link-users',
      selectOne: true,
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {
          displayName: 'username',
          name: 'username',
          notTranslatable: true,
          emptyError: 'no-name-defined',
        },
        {
          displayName: 'email',
          name: 'email',
          notTranslatable: true,
          emptyError: 'no-email-defined',
        },
      ],
    },
  },

  devices: {
    displayName: 'devices',
  },
  device: {
    displayName: 'device',
    createComponent: 'modules/Devices/Create',
    tableName: 'devices',
    fields: [
      {
        displayName: 'mac-address',
        name: 'macEth',
        backupName: 'macWifi',
        emptyError: 'no-mac-address-defined',
        notTranslatable: true,
      },
      {
        displayName: 'location',
        name: 'location',
        subFields: ['name'],
        route: 'zones.show',
        emptyError: 'no-location-defined',
      },
      {
        displayName: 'type',
        type: 'object',
        name: 'deviceType',
        subFields: ['name'],
        route: 'devices.types.show',
      },
    ],
    modalAttach: {
      displayName: 'link-device',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {
          displayName: 'mac-address',
          name: 'macEth',
          backupName: 'macWifi',
          emptyError: 'no-mac-address-defined',
          notTranslatable: true,
        },
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
        {
          displayName: 'type',
          type: 'object',
          name: 'deviceType',
          subFields: ['name'],
          route: 'devices.types.show',
        },
      ],
    },
  },
  deviceGroup: {
    displayName: 'device-group',
    createComponent: 'modules/Devices/Group/Create',
    tableName: 'device-groups',
    fields: [
      { displayName: 'name', name: 'name' },
      {displayName: 'type', name: 'type'},
    ],
    modalAttach: {
      displayName: 'link-device',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name'},
        {displayName: 'default', name: 'default', },

      ],
    },
  },
  deviceType: {
    displayName: 'device-type',
    createComponent: 'modules/Devices/Types/Create',
    tableName: 'device_types',
    fields: [
      {displayName: 'name', name: 'name'},
      {displayName: 'default', name: 'default'},

    ],
    modalAttach: {
      displayName: 'link-device',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name'},
        {displayName: 'default', name: 'default'},
      ],
    },
  },

  packages: {
    displayName: 'packages',
    createComponent: 'modules/Packages/Create',
    tableName: 'packages',
    fields: [
      {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      {displayName: 'default', name: 'default', type: 'radio'},
      {displayName: 'type', name: 'type', emptyError: ''},
    ],
    modalAttach: {
      displayName: 'link-package-items',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      ],
    },
  },
  notifications: {
    displayName: 'notifications',
    createComponent: 'modules/Notifications/Create',
    showComponent: 'modules/Notifications/Create',
    tableName: 'notifications',
    fields: [
      {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
      {displayName: 'status', type: 'diff', name: 'status', notTranslatable: true},
      {displayName: 'recipients', name: 'viewList', route: 'devices.types.show', emptyError: ''},

    ],
    modalAttach: {
      displayName: 'add-recipients',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined'},
        {displayName: 'status', type: 'diff', name: 'status', notTranslatable: true},
        {displayName: 'recipients', name: 'viewList', route: 'devices.types.show', emptyError: ''},
        {displayName: 'sort', name: 'sort', type: 'sort'},
      ],
    },
  },

  digitalSignage: {
    createComponent: 'modules/DigitalSignage/CreatePlaylist',
    displayName: 'Digital Signage',
    tableName: 'playlists',
    fields: [
      {
        displayName: 'name',
        name: 'title',
        notTranslatable: true,
      },
      {
        displayName: 'from',
        name: 'onDisplayStart',
        notTranslatable: true,
      },
      {
        displayName: 'to',
        name: 'onDisplayEnd',
        notTranslatable: true,
      },
      {
        displayName: 'status',
        name: 'status',
        notTranslatable: true,
      },
    ],
  },

  languages: {
    displayName: 'languages',
    createComponent: 'modules/Languages/Create',
    tableName: 'languages',
    type: 'language',
    fields: [
      {displayName: 'flag', type: 'image'},
      {displayName: 'name', name: 'name', emptyError: 'no-name-defined', notTranslatable: true},
      {displayName: 'locale-code', name: 'localCode', notTranslatable: true},
      {displayName: 'sort', name: 'sort', type: 'sort'},
    ],
    modalAttach: {
      displayName: 'link-languages',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {displayName: 'flag', type: 'image'},
        {displayName: 'name', name: 'name', emptyError: 'no-name-defined', notTranslatable: true},
        {displayName: 'locale-code', name: 'localCode', notTranslatable: true},
        {displayName: 'sort', name: 'sort', type: 'sort'},
      ],
    },
  },

  clock: {
    createComponent: 'modules/Time/Create',
    showComponent: 'modules/Time/Edit',
    tableName: 'world_clock_locations',
    fields: [
      {displayName: 'city', name: 'city'},
      {displayName: 'timezone', name: 'timeZone', notTranslatable: true},
    ],
  },

  locations: {
    createComponent: 'modules/Weather/Create',
    showComponent: 'modules/Weather/Edit',
    tableName: 'weather_locations',
    fields: [
      {displayName: 'city', name: 'city'},
      {displayName: 'country', name: 'country', notTranslatable: true},
      {displayName: 'latitude', name: 'latitude', emptyError: 'N/A', notTranslatable: true},
      {displayName: 'longitude', name: 'longitude', emptyError: 'N/A', notTranslatable: true},
    ],
  },

  tv: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'tv_channels',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'channel-number', name: 'channelNumber', type: 'sort'},
    ],
  },

  video: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'vod_items',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'sort', name: 'sort', type: 'sort'},
    ],
  },

  series: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'series',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'sort', name: 'sort', type: 'sort'},
    ],
    crewEdit: {
      displayName: 'edit-position',
      fields: [
        {
          displayName: 'position', type: 'radio', name: 'role', subFields: [
            {displayName: 'director', name: 'DIRECTOR'},
            {displayName: 'writer', name: 'WRITER'},
          ],
        },
      ],
    },
    castEdit: {
      displayName: 'edit-position',
      fields: [
        {
          displayName: 'position', type: 'radio', name: 'role', subFields: [
            {displayName: 'actor', name: 'ACTOR'},
          ],
        },
      ],
    },
  },
  mod: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'series',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'sort', name: 'sort', type: 'sort'},
    ],
    crewEdit: {
      displayName: 'edit-position',
      fields: [
        {
          displayName: 'position', type: 'radio', name: 'role', subFields: [
            {displayName: 'director', name: 'DIRECTOR'},
            {displayName: 'writer', name: 'WRITER'},
          ],
        },
      ],
    },
    castEdit: {
      displayName: 'edit-position',
      fields: [
        {
          displayName: 'position', type: 'radio', name: 'role', subFields: [
            {displayName: 'actor', name: 'ACTOR'},
          ],
        },
      ],
    },
  },
  aod: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'series',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'sort', name: 'sort', type: 'sort'},
    ],
    crewEdit: {
      displayName: 'edit-position',
      fields: [
        {
          displayName: 'position', type: 'radio', name: 'role', subFields: [
            {displayName: 'director', name: 'DIRECTOR'},
            {displayName: 'writer', name: 'WRITER'},
          ],
        },
      ],
    },
    castEdit: {
      displayName: 'edit-position',
      fields: [
        {
          displayName: 'position', type: 'radio', name: 'role', subFields: [
            {displayName: 'actor', name: 'ACTOR'},
          ],
        },
      ],
    },
  },

  applications: {
    dynamicModule: true,
    tableName: 'application_items',
    createComponent: 'modules/Application/Items/Create',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'type', name: 'type'},
    ],
  },

  tv_applications: {
    dynamicModule: true,
    tableName: 'application_items',
    createComponent: 'modules/Application/Items/Create',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'type', name: 'type'},
    ],
  },

  website_applications: {
    dynamicModule: true,
    tableName: 'application_items',
    createComponent: 'modules/Application/Items/Create',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'type', name: 'type'},
    ],
  },

  ship_position_applications: {
    dynamicModule: true,
    tableName: 'application_items',
    createComponent: 'modules/Application/Items/Create',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'type', name: 'type'},
    ],
  },

  catalogue: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'catalogue_items',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'sort', name: 'sort', type: 'sort'},
    ],
    categories: {
      fields: [
        {displayName: '', type: 'image'},
        {displayName: 'name', name: 'name'},
        {
          displayName: 'sort',
          type: 'object',
          name: 'pivot',
          subFields: ['sort'],
        },
      ],
    },
    linkModuleEdit: {
      displayName: 'edit-linked-module',
      fields: [
        {displayName: 'name', type: 'nevron-input', name: 'name'},
        {displayName: 'link-action-label', type: 'nevron-input', name: 'action_label'},
        {displayName: 'sort', type: 'number', name: 'sort'},
        {displayName: 'active', type: 'checkbox', name: 'active'},
      ],
    },
  },
  service: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'catalogue_items',
    fields: [
      {displayName: '', type: 'image'},
      {displayName: 'name', name: 'name'},
      {displayName: 'sort', name: 'sort', type: 'sort'},
    ],
    categories: {
      fields: [
        {displayName: '', type: 'image'},
        {displayName: 'name', name: 'name'},
        {
          displayName: 'sort',
          type: 'object',
          name: 'pivot',
          subFields: ['sort'],
        },
      ],
    },
    linkModuleEdit: {
      displayName: 'edit-linked-module',
      fields: [
        {displayName: 'name', type: 'nevron-input', name: 'name'},
        {displayName: 'link-action-label', type: 'nevron-input', name: 'action_label'},
        {displayName: 'sort', type: 'number', name: 'sort'},
        {displayName: 'active', type: 'checkbox', name: 'active'},
      ],
    },
  },

  accessories: {
    displayName: 'accessory',
    createComponent: 'modules/Accessories/Create',
    fields: [
      {
        displayName: 'Name',
        type: 'nevron-input',
        name: 'name',
        emptyError: 'No Name Defined',
      },
      {
        displayName: 'price',
        type: 'number',
        name: 'price',
      },
      {
        displayName: 'countable',
        type: 'checkbox',
        name: 'countable',
      },
      {
        displayName: 'active',
        type: 'checkbox',
        name: 'active',
      },
    ],
    attachAccessories: {
      optionalParams: 'moduleId',
      displayName: 'link-accessories',
    },
    accessoriesEdit: {
      displayName: 'edit-accessories',
      fields: [
        {
          displayName: 'name',
          type: 'nevron-input',
          name: 'name',
          emptyError: 'No Name Defined',
        },
        {
          displayName: 'price',
          type: 'number',
          name: 'price',
        },
        {
          displayName: 'countable',
          type: 'checkbox',
          name: 'countable',
        },
        {
          displayName: 'active',
          type: 'checkbox',
          name: 'active',
        },
      ],
    },
  },
};
