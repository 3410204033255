
import {Component, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import NevronHeader from '@/components/NevronHeader.vue';
import {Setting} from '@/stores/Setting';
import Skeleton from '@/modules/Skeleton.vue';

@Component({
  components: {
    NevronHeader,
    Skeleton,
  },
})
export default class Other extends Vue {
  timeout: ISetting = new Setting({'key': 'timeout'});
  isEnabled: ISetting = new Setting({'key': 'is_enabled'});
  reconnectStream: ISetting = new Setting({'key': 'reconnected_stream'});
  catalogMail: ISetting = new Setting({'key': 'catalog_mail'});
  deepLUrl: ISetting = {
    id: 0,
    key: 'deepl_url',
    value: '',
  };
  deepLAuthKey: ISetting = {
    id: 0,
    key: 'deepl_auth_key',
    value: '',
  };
  testMode: ISetting = {
    id: 0,
    key: 'test_mode',
    value: false,
  };
  fcmServerKey: ISetting = {
    id: 0,
    key: 'fcm_server_key',
    value: '',
  };

  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('other');
    this.fetch();
  }

  fetch() {
    stores.Setting.getSettings()
      .then((response) => {
        this.settings(response);
      });
  }

  settings(data: ISetting[]) {
    // @ts-ignore
    for (const index in data) {
      if (data[index].key === 'channel_timeout') {
        this.timeout = data[index];
      } else if (data[index].key === 'test_mode') {
        this.testMode = data[index];
        this.testMode.value = data[index].value;
      } else if (data[index].key === 'is_channel_timeout') {
        this.isEnabled = data[index];
        this.isEnabled.value = this.isEnabled.value !== '0';
      } else if (data[index].key === 'reconnect_stream') {
        this.reconnectStream = data[index];
        this.reconnectStream.value = this.reconnectStream.value !== '0';
      } else if (data[index].key === 'catalog_default_mail') {
        this.catalogMail = data[index];
      } else if (data[index].key === 'fcm_server_key') {
        this.fcmServerKey = data[index];
        this.fcmServerKey.value = data[index].value;
      } else if (data[index].key === 'deepl_url') {
        this.deepLUrl = data[index];
        this.deepLUrl.value = data[index].value;
      } else if (data[index].key === 'deepl_auth_key') {
        this.deepLAuthKey = data[index];
        this.deepLAuthKey.value = data[index].value;
      }
    }
  }

  updateSetting(id: any, value: any) {
    console.log(id);
    stores.Setting.update(id, {value})
      .then((response) => {
        this.fetch();
      });
  }
}
