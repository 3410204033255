
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {SweetModal} from 'sweet-modal-vue';
// @ts-ignore
import Sortable from 'vue-drag-sortable';
import NevronInput from '@/components/NevronInput.vue';

@Component({
  components: {
    SweetModal,
    Sortable,
    NevronInput,
  },
})
export default class EditPanel extends Vue {
  @Prop()
  panel: any | null;
  @Prop()
  panelIndex: any | null;
  @Prop()
  noImageIcon: any | null;
  localPanel = null;

  dragData: any = {};

  @Watch('panel')
  loadProp() {
    this.localPanel = JSON.parse(JSON.stringify(this.panel));
  }

  mounted() {
    console.log('localPanel', this.localPanel);
  }

  sort(e: any) {
    const {oldIndex, newIndex} = e;
    console.log(oldIndex, newIndex);
  }

  openTranslator(localText: any, localKey: any, referenceKey: any) {
    // @ts-ignore
    this.$bus.$emit('openTranslator', localText, localKey, referenceKey);
  }

  sorting(e: any) {
    const {oldIndex, newIndex} = e;
    this.rearrange(this.panel.elements, oldIndex, newIndex);
  }

  sortend(e: any, list: any) {
    const {oldIndex, newIndex} = e;
    this.rearrange(list, oldIndex, newIndex);
  }

  rearrange(array: any, oldIndex: any, newIndex: any) {
    if (oldIndex > newIndex) {
      array.splice(newIndex, 0, array[oldIndex]);
      array.splice(oldIndex + 1, 1);
    } else {
      array.splice(newIndex + 1, 0, array[oldIndex]);
      array.splice(oldIndex, 1);
    }
  }

  updateRatio(option: string) {
    // @ts-ignore
    this.localPanel.elementType = option;
  }

  isSelected(ration: string) {
    // @ts-ignore
    return this.localPanel.elementType === ration;
  }

  cancel() {
    if (localStorage.getItem('createPanel') === 'true') {
      this.$emit('close');
    }
    // @ts-ignore
    this.$children[0].close();
  }

  next() {
    const output = {
      panel: JSON.parse(JSON.stringify(this.localPanel)),
      panelIndex: this.panelIndex,
    };
    this.$emit('next', output);
    // @ts-ignore
    this.$children[0].close();
  }
}
