
import {Component, Prop, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import axiosInstance from '@/helpers/axios';
import AttachImage from '@/modules/Media/Attach.vue';

@Component({
  components: {
    AttachImage,
  },
})
export default class DashboardSettings extends Vue {

  @Prop()
  settings!: any;

  @Prop()
  dashboardType!: string;

  defaultDashboardSetting: any = {
    radius: '10',
    bgColor: '',
    neutralColor: '',
    primaryColor: '',
    logo: null,
    imageId: null,
    bgImageId: null,
    backGroundImage: null,
    gradiantStartColor: null,
    gradiantEndColor: null,
    imageUrl: '',
    isGradiant: '',
    buttonTextColor: null,
    selectionColor: null,
    alertColor: null,
    reminderColor: null,
    topGradiant: null,
    bottomGradiant: null,
    floatMenuColor: null,
    isImageOnDashboard: false,
    focusColor: '#ff0000',
    bgColorTransparency: '100',
  };
  attachableContent: any = null;
  contentUrl: any = '';
  type: string = '';

  buttonWidth: any = 511;

  mounted() {
    this.defaultDashboardSetting = this.settings;
    this.type = this.dashboardType;
    if(this.defaultDashboardSetting.isGradiant == "0"){
      this.defaultDashboardSetting.isGradiant = false;
    }
  }

  radiusControl() {
    const list = document.getElementsByClassName('b-radius');
    for (const i in list) {
      // @ts-ignore
      if (i && list[i] && list[i].style) {
        // @ts-ignore
        list[i].style.borderRadius = this.defaultDashboardSetting.radius + '%';
      }
    }
  }

  sendReload(files: any, result: any) {
    this.defaultDashboardSetting.imageUrl = result.imageUrl;
    this.getImage(result.id);
  }

  getImage(id: number) {
    return stores.File.getImage(id)
      .then((response) => {
        this.defaultDashboardSetting.imageUrl = response.imageUrl;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  attachWithImage(res: any) {
    this.defaultDashboardSetting.imageUrl = res.result.imageUrl;
    this.defaultDashboardSetting.bgImageId = res.result.id;
    this.emitDashboard();
    // @ts-ignore
    this.$refs.image.$children[0].close();
    if (this.defaultDashboardSetting.imageUrl === '' || this.defaultDashboardSetting.imageUrl === null) {
      this.buttonWidth = `margin-top: ${511}px;`;
    } else {
      this.buttonWidth = `margin-top: ${860}px;`;
    }
    this.getImage(res.result.id);
  }

  getHeaders() {
    return stores.File.getHeader();
  }

  getImages() {
    return stores.Folder.getFolder(1)
      .then((response) => {
        this.attachableContent = response;
        this.attachableContent.children = this.attachableContent.folders.concat(
          this.attachableContent.files,
        );
        // @ts-ignore
        this.$refs.image.$children[0].open();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getFilePath(): string {
    return axiosInstance.defaults.baseURL + '/' + stores.File.uploadUrl();
  }

  removeBackGroundImage() {
    this.defaultDashboardSetting.imageUrl = null;
    this.defaultDashboardSetting.bgImageId = null;
    this.emitDashboard();
  }

  emitDashboard() {
    this.$emit('dashboardSetting', this.defaultDashboardSetting);
  }

}
