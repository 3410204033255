

import Vue from 'vue';
import {Component, Prop, Watch} from "vue-property-decorator";
import NevronAdvanceTextBox from "@/components/NevronAdvanceTextBox.vue";
import NevronInput from "@/components/NevronInput.vue";
import {mixin as Clickaway} from "vue-clickaway";

@Component({
  components: {
    NevronAdvanceTextBox,
    NevronInput
  },
  mixins: [Clickaway],
})
export default class NevronAvailabilityOptions extends Vue {
  @Prop()
  details: any;

  validFrom: any | null;
  validTo: any | null;
  validLimit: any | true;

  created() {
    this.checkValue();
  }

  @Watch('details')
  getDetails() {
    this.checkValue();
  }

  checkValue() {
    if (!this.details.validFrom) {
      this.details.validFrom = this.validFrom;
    }
    if (!this.details.validTo) {
      this.details.validTo = this.validTo;
    }
    if (!this.details.validLimit) {
      this.details.validLimit = this.validLimit;
    }
    if (this.details.validLimit === undefined)
    {
      this.details.validLimit = true;
    }
    if  (this.details.validLimit && this.details.validLimit == 1){
      this.details.validLimit = true;
    } else {
      this.details.validLimit = false;
    }
  }
}
