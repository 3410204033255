
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import EditContact from '@/modules/Customers/EditContact.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import EditCustomer from '@/modules/Customers/EditCustomer.vue';
import PreEdit from '@/modules/Customers/Address/PreEdit.vue';
import {mixin as Clickaway} from 'vue-clickaway';
import CustomerAddressEdit from '@/modules/Customers/Address/Edit.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import _ from 'lodash';
import {deletePath} from '@/helpers/DeleteHelper';
import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead.vue';
import axiosInstance from '../../helpers/axios';
import NevronInput from '@/components/NevronInput.vue';
import CreateAccount from '@/modules/Accounts/Create.vue';
import NevronImageField from '@/components/NevronImageField.vue';
import Axios from 'axios';
import MultiSelect from 'vue-multiselect';
// @ts-ignore
import EventBus from '../../../src/EventBus';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';

Vue.prototype.$bus = EventBus;
@Component({
  components: {
    NevronFooter,
    EditContact,
    virtualList,
    EditCustomer,
    TableModalGeneric,
    TableGeneric,
    PreEdit,
    CustomerAddressEdit,
    NevronImageField,
    NevronHeader,
    Skeleton,
    NevronSearch,
    VueBootstrapTypeahead,
    NevronInput,
    MultiSelect,

  },
  mixins: [Clickaway],
})

export default class CustomerDetails extends Vue {

  @Prop()
  id: any = '';

  stays: any = '';
  customer: any = null;
  document!: IDocument;
  fullRes: any = null;
  fullResponse: any = null;
  customerIndex: any = '';
  index: number = 1;
  ids: number[] = [];
  getCustomerIndex: any = '';

  customersInstance = stores.Customer;

  tempAcc!: IAccount;
  interests: any[] = [];
  selectedInterests: any[] = [];
  relatedCustomers: any[] = [];
  accountItems: IAccount[] = [];
  searchQuery: string = '';

  response: any = null;
  frontUrl: any = null;
  backUrl: any = null;
  search: any = {
    query: '',
  };
  selectedStay: any = '';
  defaultAddress: ICustomerAddress | null = null;
  selectedContact: IContact | null = null;
  CancelToken: any = Axios.CancelToken;
  source: any;
  indexAccountList: number[] = [];
  allAccountsChecked: boolean = false;
  searchInProgress: boolean = false;
  dragOver: boolean = false;
  preLeave: boolean = false;
  attachableContent: any = null;
  stores = stores;
  toastResponse: any;

  openNewCustomer() {
    this.getCustomerIndex = 'relatedCustomer';
    // @ts-ignore
    this.$refs.createCustomer.$children[0].open();
  }

  attachRelatedCustomer(data: any) {
    stores.Stays.attachCustomer([data], this.selectedStay)
      .then((resp: any) => {
        this.loadPage();
        setTimeout(() => {
          this.$router.push({name: 'customers.show', params: {id: data}});
        }, 1000);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  attachInterest(selectedInterest: []) {
    return stores.Customer.attachInterest(selectedInterest, Number(this.$route.params.id))
      .then((response) => {
        console.log('response', response);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  save() {
    console.log((this.customer.locked));
    if (this.customer.locked === 1 || this.customer.locked === '1' || this.customer.locked === true) {
      showToaster('danger', ' ', 'The content is locked by the CMS integration module!', 500);
    } else {
      this.onSubmitPersonalData();
      return stores.Customer.updateItem(this.customer.id, this.customer)
        .then(async (response) => {
          if (this.toastResponse.responseCode === 200) {
            if (response.responseCode === 200) {
              await this.fetchData(Number(this.$route.params.id));
              showToaster('success', Vue.prototype.translate(this.customer.firstName) + ' ' + Vue.prototype.translate(this.customer.lastName), 'successfully updated');
            } else {
              showToaster('danger', Vue.prototype.translate(this.customer.firstName) + ' ' + Vue.prototype.translate(this.customer.lastName), 'Fail to update', response.code);
            }
          } else {
            showToaster('danger', Vue.prototype.translate(this.customer.firstName) + ' ' + Vue.prototype.translate(this.customer.lastName), 'Fail to update', response.code);
          }
        })
        .catch((e: any) => {
          console.log(e);
          showToaster('danger', Vue.prototype.translate(this.customer.firstName) + ' ' + Vue.prototype.translate(this.customer.lastName), 'Fail to update');
        });
    }

  }

  detachCategory(selectedInterest: []) {
    return stores.Customer.detachInterest(selectedInterest, Number(this.$route.params.id))
      .then((response) => {
        console.log('response', response);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onSubmitPersonalData() {
    const formdata = new FormData();
    formdata.append('identity_number', this.customer.identityNumber);
    formdata.append('document_type', this.customer.documentType);
    formdata.append('frontImagePath', this.customer.documents.frontImagePath);
    formdata.append('backImagePath', this.customer.documents.backImagePath);
    console.log('formdata', formdata);
    console.log('documents', this.customer.documents);

    if (this.customer.documents) {
      return stores.Customer.UpdateDocuments(this.customer.id, formdata, this.customer.documents.id)
        .then((response: any) => {
          console.log('first', response);
          this.toastResponse = response;

          this.fetchData(Number(this.$route.params.id));
        })
        .catch((e: any) => {
          console.log(e);
          showToaster('danger', Vue.prototype.translate(this.customer.firstName) + ' ' + Vue.prototype.translate(this.customer.lastName), 'Fail to update');
        });
    } else {

      return stores.Customer.saveDocuments(this.customer.id, formdata)
        .then((response: any) => {
          console.log('Success', response);
        })
        .catch((e: any) => {
          console.log(e);
          showToaster('danger', Vue.prototype.translate(this.customer.firstName) + ' ' + Vue.prototype.translate(this.customer.lastName), 'Fail to update');
        });
    }

  }

  // /*
  //  *
  //  */
  // onSubmitPersonalData() {
  //   const data = {
  //     identityNumber: this.customer.identityNumber,
  //     taxNumber: this.customer.taxNumber,
  //     dateOfBirth: this.customer.dateOfBirth,
  //     placeOfBirth: this.customer.placeOfBirth,
  //     nationality: this.customer.nationality,
  //   };
  //
  //   return stores.Customer.updateItem(this.customer.id, data)
  //     .then((response: any) => {
  //       console.log('Success');
  //     })
  //     .catch((e: any) => {
  //       console.log(e);
  //     });
  // }
  onFrontFileChange(e: any) {
    // @ts-ignore
    this.$refs.pickFrontFile.click();
  }

  pickFrontFile(e: any) {
    const file = e.target.files[0];
    this.customer.documents.frontImagePath = file;
    this.frontUrl = URL.createObjectURL(file);

  }

  onBackFileChange(e: any) {
    console.log('yes');
    // @ts-ignore
    this.$refs.pickBackFile.click();
  }

  pickBackFile(e: any) {
    const file = e.target.files[0];
    this.customer.documents.backImagePath = file;
    this.backUrl = URL.createObjectURL(file);
  }

  sortArrays(arrays: any) {
    return _.orderBy(arrays, 'sort', 'asc');
  }

  // user
  attachUser(user: IUser[]) {
    this.customer.user = user;
  }

  detachUser(user: IItem) {
    this.customer.user = null;
  }

  // stays
  attachStays(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.stayIds.includes(el.id));
    this.customer.stays = this.customer.stays.concat(newItems);
  }

  get stayIds() {
    return this.customer.stays.map((el: IItem) => el.id);
  }

  detachStay(item: IItem) {
    this.customer.stays = this.customer.stays.filter((el: any) => el.id !== item.id);
  }

  // promotions
  attachPromotions(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.promotionIds.includes(el.id));
    this.customer.promotions = this.customer.promotions.concat(newItems);
  }

  get promotionIds() {
    return this.customer.promotions.map((el: IItem) => el.id);
  }

  detachPromotion(item: IItem) {
    this.customer.promotions = this.customer.promotions.filter((el: any) => el.id !== item.id);
  }

  detachLanguage(language: ILanguage) {
    this.customer.languages = this.customer.languages.filter((el: ILanguage) => el.id !== language.id);
  }

  /*
   *
   */
  onSubmitStatus() {
    // @ts-ignore
    return stores.Customer.update(this.customer.id, {active: this.customer.active})
      .then(() => {
        console.log('success');
      }).catch((e: any) => {
        console.log(e);
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.fetchData(Number(this.$route.params.id));
    this.getAutocompleteCategories();
    localStorage.removeItem('selectedCustomers');
  }

  @Watch('$route.params.id', {immediate: true})
  loadPage() {
    this.fetchData(Number(this.$route.params.id));
    console.log('page loaded');
  }

  /**
   *
   */
  popoverMethod() {
    if (this.customer && this.customer.id) {
      return deletePath(this.customer.id);
    }
  }

  deleteCustomer() {
    stores.Customer.deleteItem(this.customer.id)
      .then((response: any) => {
        this.$router.push({
          name: 'customers',
          query: {page: this.$route.query.lastpage, perpage: this.$route.query.perpage},
        });
      });
  }

  getAutocompleteCategories() {
    this.CancelToken = Axios.CancelToken;
    this.source = this.CancelToken.source();
    this.interests = [];
    setTimeout(() => {
      return stores.Customer.getAutocompleteCategories(this.searchQuery, this.source.token)
        .then((response) => {
          this.interests = response.data.data;
          this.interests.forEach((interest: any) => {
            interest.name = Vue.prototype.translate(interest.name);
          });
          this.interests = this.interests.filter((interest: any) => interest.parents.length !== 0);
          this.searchInProgress = false;
        })
        .catch((e) => {
          console.log(e);
        });
    }, 500);
  }

  /*
   *
   */
  fetchData(id: number) {
    return stores.Customer.getItem(id)
      .then((response: any) => {
        this.customer = response;
        this.selectedInterests = [];
        this.customer.interests.forEach((interest: any) => {
          interest.name = Vue.prototype.translate(interest.name);
        });

        for (const address of this.customer.addresses) {
          if (address.default === 1) {
            this.defaultAddress = address;
            break;
          }
        }
      })
      .catch(() => {
        // this.$router.push({name: 'customers'});
      });

  }

  imageSelected(image: IMediaItem) {
    if (this.customer) {
      this.customer.imageId = (image) ? image.id : null;
    }
  }

  removeContact(contact: IContact) {
    return stores.Customer.deleteCustomerContact(contact)
      .then((response: any) => {
        console.log('success');
        // @ts-ignore
        this.$refs.contact.$children[0].close();
        for (let index = 0; index < this.customer.contacts.length; index++) {
          if (this.customer.contacts[index].id === contact.id) {
            this.customer.contacts.splice(index, 1);
            console.log('removed index:', index);
            break;
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // createContact() {
  //   return stores.Customer.addCustomerContact({id: null, key: null, value: '', customerId: this.customer.id})
  //     .then((response) => {
  //       console.log('success');
  //       this.customer.contacts.push(response);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  saveContact(contact: IContact) {
    return stores.Customer.editCustomerContact(contact)
      .then((response: any) => {
        // @ts-ignore
        this.$refs.contact.$children[0].close();
        this.fetchData(Number(this.$route.params.id));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  createNewAddress(newAddress: ICustomerAddress) {
    return stores.Location.createNewAddress(Number(this.$route.params.id), newAddress)
      .then((response: any) => {
        console.log('You have successfully created a customer address!');
        this.fetchData(Number(this.$route.params.id));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  editExistingAddress(newAddress: ICustomerAddress) {
    if (newAddress.id) {
      return stores.Location.editAddress(newAddress.id, Number(this.$route.params.id), newAddress)
        .then((response: any) => {
          console.log('You have successfully edited a customer address!');
          this.fetchData(Number(this.$route.params.id));
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }

  removeAddress(id: number) {
    if (this.customer.id) {
      return stores.Location.deleteAddress(id, this.customer.id)
        .then((response: any) => {
          console.log('You have successfully deleted this address');
          this.defaultAddress = null;
          if (this.customer.id) {
            this.fetchData(this.customer.id);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  setDefault(selected: ICustomerAddress) {
    if (selected.id) {
      return stores.Location.setDefaultAddress(selected.id, Number(this.$route.params.id))
        .then((response: any) => {
          this.customer.addresses = response;
          for (const address of this.customer.addresses) {
            if (address.default === 1) {
              this.defaultAddress = address;
              break;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  updateCheck() {
    if (this.indexAccountList.length === this.customer.accounts.length) {
      this.allAccountsChecked = true;
    } else {
      this.allAccountsChecked = false;
    }
  }

  /*
   *
   */
  onSubmitGeneral() {
    const data = {
      salutation: this.customer.salutation,
      gender: this.customer.gender,
      firstName: this.customer.firstName,
      lastName: this.customer.lastName,
      isCompany: this.customer.isCompany,
      company: this.customer.company,
    };

    // @ts-ignore
    return stores.Customer.update(this.customer.id, data)
      .then((response: any) => {
        console.log('Success');
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  /*
  save() {
    // Why do we need two seperate update calls?
    const generalData = {
      salutation: this.customer.salutation,
      gender: this.customer.gender,
      firstName: this.customer.firstName,
      lastName: this.customer.lastName,
      isCompany: this.customer.isCompany,
      company: this.customer.company,
      imageId: this.customer.imageId,
    };
    const persoanlData = {
      identityNumber: this.customer.identityNumber,
      taxNumber: this.customer.taxNumber,
      dateOfBirth: this.customer.dateOfBirth,
      placeOfBirth: this.customer.placeOfBirth,
      nationality: this.customer.nationality,
    };

    stores.Customer.update(this.customer.id, generalData)
      .then((response: any) => {
        console.log('Success');
      })
      .catch((e: any) => {
        console.log(e);
      });

    stores.Customer.update(this.customer.id, persoanlData)
      .then((response: any) => {
        console.log('Success');
      })
      .catch((e: any) => {
        console.log(e);
      });
  }
  */

  // setupEditlocation(address: ICustomerAddress | null) {
  //   this.tempLoc = null;
  //   if (!address) {
  //     this.tempLoc = {
  //       address: '',
  //       address2: '',
  //       city: '',
  //       country: '',
  //       post: '',
  //       id: null,
  //       customerId: null,
  //       default: 0,
  //       firstName: '',
  //       lastName: '',
  //       company: '',
  //       phoneNumber: '',
  //     };
  //   } else {
  //     this.tempLoc = address;
  //   }

  //   // @ts-ignore
  //   // this.$children[0].close();
  //   // @ts-ignore
  //   this.$refs.loc.$children[0].open();

  // }
  setupEditAccount(account: IAccount) {
    this.tempAcc = account;
  }

  closeAndRefresh() {
    return stores.Customer.getAllCustomerAccounts(Number(this.$route.params.id))
      .then((response: any) => {
        this.customer.accounts = response;
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  removeLocation(id: number) {
    return stores.Location.deleteAddress(id, Number(this.$route.params.id))
      .then((response: any) => {
        console.log('You have successfully deleted an address');
        this.fetchData(Number(this.$route.params.id));
      })
      .catch((e: any) => {
        console.log(e);
      });
  }
}
