
import {Vue, Component, Prop} from 'vue-property-decorator';
import TabManager from '@/mixins/TabManager';
import stores from '@/stores';

import ItemsIndexGeneric from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
import GuestFlowModuleShow from '@/modules/GuestFlowModule/ShowBasic.vue';
import MyInterestsShow from "@/modules/GuestFlowModule/ShowBasic.vue";
import {hasPermission} from "@/helpers/permissions";


@Component({
  methods: {hasPermission},
  components: {
    MyInterestsShow,
    ItemsIndexGeneric,
    GuestFlowModuleShow,
  },
})
export default class DashboardsIndex extends TabManager {

  stores = stores;
  gfDashboardModuleId = 1;

  get TAB_KEYS(): string[] {
    const keys = [];
    if (hasPermission('feature.STB_DASHBOARDS'))
      keys.push('tv');
    if (hasPermission('feature.MOBILE_DASHBOARDS'))
      keys.push('mobile');
    if (hasPermission('guest-flow-type.GF_DASHBOARD'))
      keys.push('gfMobile');
    return keys;
  }
}
