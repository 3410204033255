
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '../../stores';
import Skeleton from '../Skeleton.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';

@Component({
  components: {Skeleton},
})

export default class ProjectsItem extends Vue {
  config = stores.globalConfig.config;
  master = stores.master;

  logo: ISetting = {
    id: 0,
    value: '',
    key: '',
  };

  deleteProject(project: IManagerProject) {
    if (project.id === 'nevrondefault') {
      showToaster('danger', ' ', 'you can not remove default tenant', 500);

    } else {
      if (confirm(`Permanently delete project ${project.name}?`)) {
        this.master.projects = null;
        this.master.deleteProject(project.id)
          .then((response) => {
            this.master.getProjects();
          });
      }
      return;
    }

  }

  editProject(project: IManagerProject) {
    this.$router.push({
      name: 'projects.edit',
      params: {id: project.id},
    });
  }

  copyProject(project: IManagerProject) {
    this.$router.push({
      name: 'projects.copy',
      params: {id: project.id},
    });
  }

  defaultLogo(event?: any) {
    if (!event) {
      return require('../../../assets/nevron_logo.png');
    }
    event.target.src = require('../../../assets/nevron_logo.png');
  }
}
