
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import vPagination from '@/components/VuePlainPagination.vue';
import NevronHeader from '@/components/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronEmpty from '@/components/NevronEmpty.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import hexColorCode from '@/helpers/hexColorCode';
import getInitials from '@/helpers/initials';
import PageHeader from '@/components/partials/page-header.vue';
import _ from 'lodash';
import PerPageDropdown from '@/components/PerPageDropdown.vue';
import CreatePromotion from '@/modules/Promotions/Create.vue';

@Component({
  components: {
    vPagination,
    NevronHeader,
    Skeleton,
    NevronEmpty,
    NevronSearch,
    NevronSearchNoResults,
    PageHeader,
    PerPageDropdown,
    CreatePromotion,
  },
})

export default class PromotionsList extends Vue {
  promotions = stores.Promotions;
  response: any = null;

  perpage = 20;
  indexList: number[] = [];
  allChecked = false;

  firstLoad = true;

  search: any = {
    query: '',
    current: '',
  };

  file: any = '';
  uploadResponse: any = '';
  exportData: any = '';

  message: any = {
    import: '',
    export: '',
    message: '',
  };

  searchItems = _.debounce(() => {
    this.fetchData(1, this.search.query, this.perpage);
  }, 400);

  /*
   * Watch for pagination
   */
  @Watch('response.currentPage')
  changedPage() {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }
    this.search.query = this.search.current;
    this.fetchData(this.response.currentPage, this.search.current, this.perpage);
  }

  perPageChange() {
    this.fetchData(1, this.search.query, this.perpage);
  }

  openCreatePage() {
    console.log('res');
    // @ts-ignore
    this.$refs.create.$children[0].open();
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    console.log('component', this.$refs.create);
    // set search query from URL
    if (this.$route.query.search) {
      this.search.query = this.$route.query.search;
      this.search.current = this.$route.query.search;
    }

    // set page from URL
    let index = 1;
    if (this.$route.query.page) {
      index = Number(this.$route.query.page);
    }
    if (this.$route.query.perpage) {
      this.perpage = Number(this.$route.query.perpage);
    }

    this.fetchData(index, this.search.current, this.perpage);
  }

  refresh() {
    this.fetchData(this.response.currentPage, null, this.perpage);
  }
  detachPromotion(id: number, e: any) {
    e.preventDefault();
    if (this.promotions && confirm('Do you really want to delete Promotions?')) {
      return stores.Promotions.deleteItem(id)
        .then((response: any) => {
          this.refresh();
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }
  /**
   * Fetches data on the specified page and with the specified query
   */
  fetchData(index: number | null, search: string | null, perpage: number | 10): any {
    stores.Promotions.fetchData(index, search, perpage)
      .then((response: any) => {
        console.log('response', response);
        this.response = response;
        this.promotions = this.response.data;
        this.search.current = this.search.query;
        this.updateCheck();
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  importFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadResponse = e.target ? e.target.result : '';
    };
    reader.readAsText(file);
  }

  /*
   * Get initials from admin's name
   */
  getInitials(str: string) {
    return getInitials(str);
  }

  /*
   * Get hex color code from string
   */
  hexColorCode(str: string) {
    return hexColorCode(str);
  }

  /* removes or appends all items ids on current page to indexList */
  checkAll() {
    if (!this.allChecked) {
      this.indexList = Array.from(new Set([...this.indexList, ...this.itemIds]));
      this.allChecked = true;
    } else {
      this.indexList = this.indexList.filter((id) => !this.itemIds.includes(id));
      this.allChecked = false;
    }
  }

  /* when item is checked */
  updateCheck() {
    if (this.itemIds.every((id: number) => this.indexList.includes(id))) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
  }

  get itemIds(): number[] {
    // @ts-ignore
    return this.promotions.map((item: any) => item.id);
  }
}
