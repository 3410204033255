
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {SweetModal} from 'sweet-modal-vue';
    import axiosInstance from '../../helpers/axios';
    import session from '@/stores/Session';
    import stores from '@/stores';
    import VODModule from '@/stores/modules/VODs';
    import {DashboardPanel, PanelElement} from '@/stores/Dashboard';
    import {dynamicModules} from '@/helpers/constants';
    import {createItemPanelElement} from './helpers';
    import _ from 'lodash';

    @Component({
        components: {
            SweetModal,
        },
    })
    export default class Items extends Vue {
        option: any = 'collection';
        modules = stores.modules.models.filter((mod: IModule) => dynamicModules.includes(mod.type));
        categories: ICategories[] = [];
        panelElements: any = '';
        selectedModule: any = '';
        selectedCategory: any = '';
        ratio = 'card-poster';
        quantity = 'all';

        loadModules() {
          this.modules = stores.modules.models.filter((mod: IModule) => dynamicModules.includes(mod.type));
          const contactModule = {name: 'contact-module'};
          for (const index in contactModule) {
            if (index) {
              // @ts-ignore
              this.modules.push(contactModule);
            }
          }
        }

  loadModuleCategory(service: any) {
    console.log('res', this.selectedModule);
    this.categories = [];
    // commit for guestflow
    if (this.selectedModule.name === 'guestFlow') {
      // @ts-ignore
      stores.GuestFlow.listofServices()
        .then((response) => {
          for (const index in response.data) {
            // @ts-ignore
            if (index && ( response.data[index].sort === 4 || response.data[index].sort === 8)) {
              // @ts-ignore
              this.categories.push( response.data[index]);
            }
          }
        });
    }  else if (this.selectedModule.name === 'contact-module') {
      stores.ContactModule.fetchData(1, '', 100)
        .then((response) => {
          this.categories = response.data;
        });

    } else {
      this.selectedModule.getAllCategories()
        .then((response: any) => {
          this.categories = response.data;
        });
    }

  }

  mounted() {
    const guestflow = {name: 'guestFlow'};
    for (const index in guestflow) {
      if (index) {
        // @ts-ignore
        this.modules.push(guestflow);
      }
    }

  }

  cancel() {
    this.selectedModule = null;
    this.selectedCategory = null;
    // @ts-ignore
    this.$children[0].close();
  }

  update(option: string) {
    this.option = option;
  }

  updateRatio(option: string) {
    this.ratio = option;
  }

  updateQuantity(option: string) {
    this.quantity = option;
  }

  isSelected(ratio: string) {
    return this.ratio === ratio;
  }

  next() {
    const panel = new DashboardPanel();
    panel.name = Vue.prototype.translate(`${this.selectedCategory.name}`);
    if (this.selectedModule.name === 'contact-module') {
      panel.name = 'Contact Module';
    }
    panel.elementType = this.ratio;
    panel.panelType = 'row';
    panel.position = 'top';
    panel.active = true;
    if (this.selectedModule.name === 'guestFlow') {
      if (this.selectedCategory.sort === 4) {
        stores.Library.listofLibrary()
          .then((response) => {
            // this.panelElements = response.data;
            let items = response.data;
            const itemsCount = this.quantity === 'all' ? items.length : 10;
            items = items.slice(0, itemsCount);
            const key = 'moduleId';
            // @ts-ignore
            this.selectedModule[key] = this.selectedCategory.sort;
            for (const item of this.sortArrays(items)) {
              const panelElement = createItemPanelElement(this.selectedModule, item);
              panel.elements.push(panelElement);
            }
            this.$emit('next', panel);
            this.cancel();
          });
      } else {
        stores.Promotions.listofPromotions()
          .then((response) => {
            // this.panelElements = response.data;
            let items = response.data;
            const itemsCount = this.quantity === 'all' ? items.length : 10;
            items = items.slice(0, itemsCount);
            const key = 'moduleId';
            // @ts-ignore
            this.selectedModule[key] = this.selectedCategory.sort;
            for (const item of this.sortArrays(items)) {
              const panelElement = createItemPanelElement(this.selectedModule, item);
              panel.elements.push(panelElement);
            }
            this.$emit('next', panel);
            this.cancel();
          });

      }

    } else if (this.selectedModule.name === 'contact-module') {

      const panelElement = createItemPanelElement(this.selectedModule, this.selectedCategory);
      panel.elements.push(panelElement);
      this.$emit('next', panel);
      this.cancel();

    } else {
      if (this.selectedModule && this.selectedCategory && typeof this.selectedModule!.getSpecificCategory !== 'undefined') {
        this.selectedModule.getSpecificCategory(this.selectedCategory.id!)
          .then((response: any) => {
            let items = (this.selectedModule.routeName === 'catalogue' || this.selectedModule.routeName === 'service') ? response.data.subitems : response.data.children;
            items = items.filter((item: any) => {
              if (item.active === 1) {
                return item;
              }
            });
            const itemsCount = this.quantity === 'all' ? items.length : 10;
            items = items.slice(0, itemsCount);
            for (const item of this.sortArrays(items)) {
              const panelElement = createItemPanelElement(this.selectedModule, item);
              panel.elements.push(panelElement);
            }
            this.$emit('next', panel);
            this.cancel();
          });
      }
    }
  }
  sortArrays(arrays:  any) {
    console.log(arrays);
    return _.orderBy(arrays, 'sort', 'asc');
  }
}
