
import {Component, Vue} from 'vue-property-decorator';
import NevronHeader from '@/components/NevronHeader.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import AddMenu from '@/components/DashboardComposer/AddMenu.vue';
import AddSection from '@/components/DashboardComposer/AddSection.vue';
import EServices from '@/components/DashboardComposer/EServices.vue';
import CollectionItem from '@/components/DashboardComposer/CollectionItem.vue';
import Categories from '@/components/DashboardComposer/Categories.vue';
import Items from '@/components/DashboardComposer/Items.vue';
import AppendItems from '@/components/DashboardComposer/AppendItems.vue';
import AddItems from '@/modules/Packages/Attachpackageitem.vue';
import EditPanel from '@/components/DashboardComposer/EditPanel.vue';
import axiosInstance from '../../../../helpers/axios';
import stores from '@/stores';
import {DashboardPanel, Dashboards, PanelElement} from '@/stores/Dashboard';
// @ts-ignore
import Sortable from 'vue-drag-sortable';
import NevronInput from '@/components/NevronInput.vue';
import DynamicContent from '@/modules/Translation/DynamicContent.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import Skeleton from '@/modules/Skeleton.vue';
import DashboardSettings from '@/components/DashboardComposer/DashboardSettings.vue';
import GuestFlow from '@/components/DashboardComposer/guestFlow.vue';
import {RawLocation} from "vue-router/types/router";
import {hasPermission} from "@/helpers/permissions";

@Component({
  components: {
    EditPanel,
    AddItems,
    AppendItems,
    NevronHeader,
    AttachImage,
    AddSection,
    EServices,
    CollectionItem,
    Categories,
    Items,
    Sortable,
    AddMenu,
    NevronInput,
    DynamicContent,
    Skeleton,
    DashboardSettings,
    GuestFlow,
  },
})
export default class DashboardComposer extends Vue {
  // background color list
  bgColors: any = [
    '#EE1414',
    '#B97506',
    '#06156C',
    '#53066C',
    '#C317C3',
    '#A90B34',
  ];
  dragData: any = {};
  // text color list
  txtColors: any = [
    '#000000',
    '#ffffff',
  ];
  // header background attributes
  selectedFolderId: any = 1;
  attachableContent: any = null;
  // container for composer dashboard component
  panels: any = [];
  dashboardSetting: any = {
    radius: '10',
    bgColor: '',
    neutralColor: '',
    primaryColor: '#ffffff',
    focusColor: '#ff0000',
    bgColorTransparency: '100',
    logo: '',
  };
  dashboard: IDashboards = new Dashboards({
    name: 'STB Composer Dashboard',
    type: 'STB',
    deviceTypeId: 1,
    active: 1,
  });
  // one slider only for one dashboard therefor separate instant
  slider: any = {
    elements: [] // Initialize elements as an empty array
  };
  showSlider: any = {
    elements: [] // Initialize elements as an empty array
  };
  // showSlider: any = {};
  navigation: any = null;
  header: any = null;
  sliderInterval: any = null;
  // helper veriables
  selectedPanelIndex: any = null;
  selectedElementIndex: any = null;
  selectedPanel: any = null;
  tempPanel: any = null;
  panelCounter: number = 0;
  panel: any = {
    id: null,
    dashboardId: null,
    name: '',
    sort: null,
    elements: [],
  };
  notification: any = {
    result: false,
    message: '',
  };
  localText: any = '';
  localKey: any = '';
  notificationTimer: any;
  windowTop: any = 669;
  noImageIcon = '';
  checkFloatingSection: any = null;
  sectionCounter: number = 0;
  sectionLimit: number = 0;

  get isWithinSelectionsLimit(): boolean {
    return !this.sectionLimit || this.sectionCounter < this.sectionLimit || hasPermission('global-settings.PRIVILEGED_EDITOR');
  }

  get backRoute(): RawLocation  {
    return {name: 'dashboards', query: {tab: 'tv', page: this.$route.query.lastpage, perpage: this.$route.query.perpage}};
  }

  mounted() {
    // this.slider.elements = [];
    // this.showSlider.elements = [];
    clearInterval(this.sliderInterval);
    this.selectedElementIndex = 0;
    this.loadComposerData(this.$route.params.id);
    this.dashboardSetting.bgColorTransparency = '100';
    this.dashboardSetting.primaryColor = '#ffffff';
    console.log(this.dashboardSetting.bgColorTransparency);
    // @ts-ignore
    this.$bus.$on('openTranslator', (localText: any, localKey: any, referenceKey: any) => {
      this.localText = localText;
      this.localKey = localKey;
      // @ts-ignore
      this.$refs.translator.$children[0].open();
    });
  }

  isImageFile(fileExtension: any) {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'eps', 'bmp', 'tiff', 'tif', 'svg', 'raw'];
    return imageExtensions.includes(fileExtension);
  };

  isVideoFile(fileExtension: any) {
    const videoExtensions = ['mp4', 'mov', 'avi', 'wmv', 'webm', 'mkv', 'mpeg-4', 'ogg'];
    return videoExtensions.includes(fileExtension);
  };

  videoType(type: any) {
    return 'video/' + type;
  }

  guestFlowResponse(guestFlow: any = []) {
    console.log('guestFlow >>', guestFlow);
    if (guestFlow.length > 0) {
      const temp = new DashboardPanel({active: 1});
      temp.name = 'guestFlow';
      // @ts-ignore
      temp.elementType = this.$refs.services.data.ratio;
      for (const index in guestFlow) {
        if (index && guestFlow[index]) {
          temp.elements.push(new PanelElement({
            name: guestFlow[index].name,
            linkAction: 'GuestFlow',
            linkModuleId: guestFlow[index].id,
            linkLayout: 'element',
            image: guestFlow[index].image ? guestFlow[index].image.imageUrl : null,
            imageId: guestFlow[index].image ? guestFlow[index].image.id : null,
            active: true,
          }));
        }
      }
      this.selectedPanel = temp;
      this.selectedPanelIndex = this.panels.length;

      this.panels.push(temp);
      this.countSections();
      // @ts-ignore
      this.$refs.editPanel.$children[0].open();
    }
  }

  closePanel() {
    this.panels.pop(this.tempPanel);
    this.selectedPanel = null;
    localStorage.removeItem('createPanel');
    // @ts-ignore
    this.$refs.editPanel.$children[0].close();
  }

  handleTranslation(data: any) {
    console.log('resonse data', data);
    this.localText = data.value;
    this.localKey = data.key;
    // @ts-ignore
    this.$refs.translator.$children[0].open();
  }

  // navigation handling
  addMenu(el: PanelElement) {
    this.navigation.elements.push(el);
  }

  // navigation handling
  addIcon(el: PanelElement) {
    this.header.elements.push(el);
  }

  removeHeaderIcon(menu: any) {
    // removeing element from session list
    this.header.elements = this.header.elements.filter((item: any) => {
      return item !== menu;
    });
    // rearrang navigation elements
    const temp: PanelElement[] = [];
    for (const index in this.header.elements) {
      if (index) {
        temp.push(this.header.elements[index]);
      }
    }
    this.header.elements = temp;
  }

  removeMenu(menu: any) {
    // removeing element from session list
    this.navigation.elements = this.navigation.elements.filter((item: any) => {
      return item !== menu;
    });
    // rearrang navigation elements
    const temp: PanelElement[] = [];
    for (const index in this.navigation.elements) {
      if (index) {
        temp.push(this.navigation.elements[index]);
      }
    }
    this.navigation.elements = temp;
  }

  // load dashboard data
  loadComposerData(id: any) {
    let _this = this;
    stores.STBComposer.getDashboard(id)
      .then((response: any) => {
        console.log(response);
        if (response.dashboard.type === 'Mobile' || response.dashboard.type === 'mobile') {
          this.$router.push({name: 'mobileDashboards.show', params: {id: this.$route.params.id}});
        }
        this.panels = [];
        const tempArray = response.panels;
        for (const index in tempArray) {
          if (index && tempArray[index]) {
            this.panels.push(tempArray[index]);
          }
        }
        this.navigation = response.navigation;
        this.header = response.header;
        this.slider = JSON.parse(JSON.stringify(response.slider));
        this.showSlider = JSON.parse(JSON.stringify(response.slider));
        this.sectionLimit = response.limitOfInstance;
        this.noImageIcon = (response.noImageIcon) ? response.noImageIcon.value : '';
        if (this.showSlider) {
          this.showSlider.linkElementId = 0;
        }
        this.dashboardSetting = response.setting;
        if (!this.dashboardSetting.primaryColor) {
          this.dashboardSetting.primaryColor = '#000000';
        }
        if (!this.dashboardSetting.focusColor) {
          this.dashboardSetting.focusColor = '#000000';
        }
        if (!this.dashboardSetting.bgColor) {
          this.dashboardSetting.bgColor = '#000000';
        }
        if (!this.dashboardSetting.hbgColor) {
          this.dashboardSetting.hbgColor = '#000000';
        }
        if (!this.dashboardSetting.bgColorTransparency) {
          this.dashboardSetting.bgColorTransparency = '100';
        }
        this.dashboard = response.dashboard;
      })
      .then(() => {
        this.countSections();
      });
    this.selectedElementIndex = 0;
  }

  countSections() {
    this.sectionCounter = this.panels.length + (this.slider ? 1 : 0) + (this.navigation ? 1 : 0) + (this.header ? 1 : 0);
  }

  cancel() {
    this.$router.push(this.backRoute);
  }

  save() {
    clearTimeout(this.notificationTimer);
    const dashboardData = JSON.parse(JSON.stringify(this.dashboard));
    dashboardData.setting = this.dashboardSetting;
    dashboardData.panels = this.panels;
    dashboardData.slider = this.slider;
    dashboardData.navigation = this.navigation;
    dashboardData.header = this.header;

    if (dashboardData.setting.isGradiant) {
      dashboardData.setting.isGradiant == "1";
    } else {
      dashboardData.setting.isGradiant == "0";
    }
    delete dashboardData.public;
    console.log(dashboardData);
    stores.STBComposer.updateItem(this.dashboard.id, dashboardData)
      .then(async (response) => {
        if (!response.success) {
          throw new Error(response.error.message);
        }

        await this.loadComposerData(this.$route.params.id);
        showToaster('success', Vue.prototype.translate(this.dashboard.name), 'successfully updated');

      }).catch((error) => {
      showToaster('danger', '', error.message);
    });
  }

  setNotification(result: any, message: any) {
    this.notification = {
      result,
      message,
    };
    this.notificationTimer = setTimeout(() => {
      this.notification = {
        result: false,
        message: '',
      };
    }, 5000);
  }

  // sorting functionality
  sort(e: any) {
    const {oldIndex, newIndex} = e;
    console.log(oldIndex, newIndex);
  }

  sorting(e: any) {
    const {oldIndex, newIndex} = e;
    this.rearrange(this.panels, oldIndex, newIndex);
  }

  sortend(e: any, list: any) {
    const {oldIndex, newIndex} = e;
    this.rearrange(list, oldIndex, newIndex);
  }

  rearrange(panelList: [], oldIndex: any, newIndex: any) {
    if (oldIndex > newIndex) {
      this.panels.splice(newIndex, 0, this.panels[oldIndex]);
      this.panels.splice(oldIndex + 1, 1);
    } else {
      this.panels.splice(newIndex + 1, 0, this.panels[oldIndex]);
      this.panels.splice(oldIndex, 1);
    }
  }

  sortMenu(e: any, list: any) {
    const {oldIndex, newIndex} = e;
    if (oldIndex > newIndex) {
      this.navigation.elements.splice(newIndex, 0, this.navigation.elements[oldIndex]);
      this.navigation.elements.splice(oldIndex + 1, 1);
    } else {
      this.navigation.elements.splice(newIndex + 1, 0, this.navigation.elements[oldIndex]);
      this.navigation.elements.splice(oldIndex, 1);
    }
  }

  showSectionOption() {
    // @ts-ignore
    this.$refs.section.$children[0].open();
  }

  // perform action on add saction model
  sectionStepOne(option: string) {
    // @ts-ignore
    this.$refs.section.$children[0].close();
    console.log('option > > ', option);
    if (option === 'banner') {
      this.slider = new DashboardPanel({
        dashboardId: this.dashboard.id,
        name: 'Slider',
        panelType: 'slider',
        linkElementId: 0,
        elements: [],
        active: true,
        showMore: true,
        position: 'default',
        sort: 10,
        showName: true,
        elementType: 'icon',
      });
      this.showSlider = new DashboardPanel({
        dashboardId: this.dashboard.id,
        name: 'Slider',
        panelType: 'slider',
        linkElementId: 0,
        elements: [],
        active: true,
        showMore: true,
        position: 'default',
        sort: 10,
        showName: true,
        elementType: 'icon',
      });
      // @ts-ignore
      document.getElementById('stb-scroll').scrollTop = 0;
      // }, 500)
    } else if (option === 'navigation') {
      this.navigation = new DashboardPanel({
        dashboardId: this.dashboard.id,
        name: 'Navigation',
        panelType: 'navigation',
        elements: [],
        active: true,
        showMore: true,
        position: 'default',
        sort: 9,
        showName: true,
        elementType: 'card-poster',
      });
    } else if (option === 'header') {
      this.header = new DashboardPanel({
        name: 'Header',
        panelType: 'header',
        elements: [],
        active: true,
        showMore: true,
        position: 'default',
        sort: 9,
        showName: true,
        elementType: 'icon',
      });
    } else if (option === 'collection') {
      // @ts-ignore
      this.$refs.collections.$children[0].open();
    }
    this.countSections();
  }

  // handle type of collection
  collectionResponse(option: string) {
    // @ts-ignore
    this.$refs.collections.$children[0].close();
    if (option === 'services') {
      // @ts-ignore
      this.$refs.services.$children[0].open();
    } else if (option === 'guestflow') {
      // @ts-ignore
      this.$refs.guestflow.$children[0].open();
    } else if (option === 'categories') {
      // @ts-ignore
      this.$refs.categories.$children[0].open();
    } else if (option === 'items') {
      // @ts-ignore
      this.$refs.items.$children[0].open();
    }
  }

  // add services to dashboard
  servicesResponse(services: []) {
    const container = document.querySelector('.stb-body');
    // @ts-ignore
    document.getElementById('stb-scroll').scrollTop = container.scrollTop - 80;
    console.log('services >>', services);
    if (services.length > 0) {
      const temp = new DashboardPanel({active: 1});
      temp.name = 'eServices';
      // @ts-ignore
      temp.elementType = this.$refs.services.data.ratio;
      for (const index in services) {
        if (index && services[index]) {
          temp.elements.push(new PanelElement({
            // @ts-ignore
            name: services[index].name,
            // @ts-ignore
            linkModuleId: services[index].id,
            linkLayout: 'element',
            // @ts-ignore
            image: services[index].image ? services[index].image.imageUrl : null,
            // @ts-ignore
            imageId: services[index].image ? services[index].image.id : null,
            active: true,
          }));
        }
      }
      this.selectedPanel = temp;
      this.selectedPanelIndex = this.panels.length;
      this.tempPanel = temp;
      this.panels.push(temp);
      this.countSections();
      localStorage.setItem('createPanel', 'true');
      // @ts-ignore
      this.$refs.editPanel.$children[0].open();
    }
    console.log('panels >>', this.panel);
    // // @ts-ignore
    // const container = document.querySelector('.stb-body');
    // // @ts-ignore
    // document.getElementById('stb-scroll').scrollTop = container.scrollTop - 80;
  }

  categoriesResponse(categories: []) {
    console.log('services >>', categories);
    if (categories.length > 0) {
      const temp = new DashboardPanel({active: 1});
      temp.name = 'Categories';
      for (const index in categories) {
        if (index && categories[index]) {
          temp.elements.push(new PanelElement({
            // @ts-ignore
            name: services[index].name,
            // @ts-ignore
            linkModuleId: services[index].id,
            linkLayout: 'element',
            active: true,
          }));
        }
      }
      this.panels.push(temp);
      this.countSections();
    }
    console.log('panels >>', this.panel);
  }

  // add collection of items
  itemsResponse(result: PanelElement) {
    this.panels.push(result);
    this.countSections();
    // @ts-ignore
    const container = document.querySelector('.stb-body');
    // @ts-ignore
    document.getElementById('stb-scroll').scrollTop = container.scrollTop - 80;
  }

  addElementsToPanel(elements: IPanelElement[]) {
    for (const element of elements) {
      this.panels[this.selectedPanelIndex].elements.push(element);
    }
  }

  createPanelElement(module: IModule, item: any) {
    const tempItem = new PanelElement();
    // TODO make panel elements universal for all modules
    tempItem.linkElementId = (module.type === 'APPLICATION') ? item.packageName : item.id;
    tempItem.image = item.image ? item.image.imageUrl : null;
    tempItem.imageId = item.image ? item.image.id : null;
    tempItem.name = item.name;
    tempItem.linkModuleId = String(module.id);
    tempItem.linkLayout = (module.type === 'APPLICATION') ? 'application' : 'element';
    tempItem.linkAction = (module.type === 'APPLICATION') ? item.type : 'play';
    tempItem.active = true;
    return tempItem;
  }

  // show pop to add image with element of panel
  showImagesForElement(panelId: any, elementId: any) {
    this.selectedPanelIndex = panelId;
    this.selectedElementIndex = elementId;
    // @ts-ignore
    this.$refs.elementImage.$children[0].open();
  }

  // attach image with element
  attachImageToElement(img: any) {
    const element = this.panels[this.selectedPanelIndex].elements[this.selectedElementIndex];
    element.image = img.result.imageUrl;
    element.imageId = img.result.id;
  }

  // banner slider handlers start
  removeSlider() {
    this.slider = null;
    this.showSlider = null;
    clearInterval(this.sliderInterval);
    this.countSections();
  }

  removeSlide(slide: any) {
    // before remove current slide point next slide
    // this.nextSlide();
    // remove slide from session
    this.slider.elements = this.slider.elements.filter((item: any) => {
      return item !== slide;
    });
    this.showSlider.elements = this.showSlider.elements.filter((item: any) => {
      return item !== slide;
    });
    console.log(this.slider);
    console.log(this.showSlider);
    this.showSlider = this.slider;

    // rearrang items
    const temp: PanelElement[] = [];
    for (const index in this.slider.elements) {
      if (index) {
        temp.push(this.slider.elements[index]);
      }
    }
    this.slider.elements = temp;
    // rearrang items
    const temp1: PanelElement[] = [];
    for (const index in this.showSlider.elements) {
      if (index) {
        temp1.push(this.showSlider.elements[index]);
      }
    }
    this.showSlider.elements = temp1;
    if (this.showSlider.elements[this.showSlider.linkElementId]) {
      this.nextSlide();
    } else {
      this.showSlider.linkElementId--;
      this.preSlide();
    }

  }

  showAddSlide(index: any) {
    // @ts-ignore
    this.$refs.slide.$children[0].open();
  }

  attachSlide(imgObj: any) {
    console.log(imgObj);
    const ele = new PanelElement();
    ele.image = imgObj.result.imageUrl;
    ele.imageId = imgObj.result.id;
    ele.active = true;
    ele.elementImage.type = imgObj.result.type;
    this.slider.elements.push(ele); // Push element to the array
    console.log(this.slider.elements);
    this.showSlider.elements = this.slider.elements;
    console.log(this.showSlider.elements);
// this.nextSlide();
    this.$delete(ele, 'foo');
  }

  nextSlide() {
    let _this = this;
    console.log('next');
    console.log(_this.slider);
    console.log('next');
    if (_this.showSlider.elements.length > 0) {
      const activeElement = _this.showSlider.linkElementId;
      if (activeElement === _this.showSlider.elements.length - 1) {
        _this.showSlider.elements[activeElement].active = false;
        if (_this.showSlider.elements[0]) {
          _this.showSlider.elements[0].active = true;
        }
        _this.showSlider.linkElementId = 0;
      } else {
        _this.showSlider.elements[activeElement].active = false;
        _this.showSlider.elements[activeElement + 1].active = true;
        _this.showSlider.linkElementId = activeElement + 1;
      }
    }
    console.log(_this.showSlider);

  }

  preSlide() {
    console.log('pre');

    if (this.showSlider.elements.length > 0) {

      let activeElement = this.showSlider.linkElementId;
      if (activeElement !== 0) {
        this.showSlider.elements[activeElement].active = false;
        this.showSlider.elements[activeElement - 1].active = true;
        this.showSlider.linkElementId = activeElement - 1;
      } else {
        this.showSlider.elements[activeElement].active = false;
        activeElement = this.showSlider.elements.length - 1;
        this.showSlider.elements[activeElement].active = true;
        this.showSlider.linkElementId = activeElement;
      }
    }
  }

  // banner slider handlers end here
  removePanel(panel: any) {
    // remove panel from session list
    this.panels = this.panels.filter((item: any) => {
      return panel !== item;
    });
    this.countSections();
  }

  updatePanel(result: any) {
    localStorage.removeItem('createPanel');
    stores.Language.loadTranslations();
    this.$set(this.panels, result.panelIndex, result.panel);
  }

  removeElement(element: any, index: any) {
    console.log('remove element cliked');
    // remove panel element from session list
    this.panels[index].elements = this.panels[index].elements.filter((item: any) => {
      return item !== element;
    });
  }

  /**
   * Lift Side module Setting Area End
   */

  getHeaders() {
    return stores.File.getHeader();
  }

  getFilePath(): string {
    return axiosInstance.defaults.baseURL + '/' + stores.File.uploadUrl();
  }

  attachLogo(imgObj: any) {
    this.dashboardSetting.logo = imgObj.result.imageUrl;
    // @ts-ignore
    this.$refs.logo.$children[0].close();
  }

  selectLogo() {
    // @ts-ignore
    this.$refs.logo.$children[0].open();
  }

  toRGBA(color: string) {
    const opacity = 1 - Number(this.dashboardSetting.bgColorTransparency) / 100;
    if (color && color.charAt(0) === '#' && color.length < 8) {
      const hexOpacity = Math.round(opacity * 255).toString(16);
      return `#${color.slice(1)}${hexOpacity}`;
    }
    if (color && color.slice(0, 4) === 'rgb(') {
      const rgba = color.replace('rgb', 'rgba').replace(')', `,${opacity})`);
      return rgba;
    }
    return color;
  }

  dashboardHandler(dashboard: any) {
    this.dashboardSetting = dashboard;
  }

  /**
   * Logo setting end here
   */
}
