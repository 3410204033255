
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';

import ItemsIndexGeneric from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
import TranslationIndex from '@/modules/Translation/Index.vue';


@Component({
  components: {
    ItemsIndexGeneric,
    TranslationIndex
  },
})

export default class Localization extends Vue {
  // Component logic goes here
  stores = stores;
};
