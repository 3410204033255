
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '../../stores';

@Component({
  components: {},
})

export default class DeleteAccount extends Vue {
  /**
   * Fired when this module is created
   */
  created() {
    this.delete();
  }

  /**
   * Deletes the desired item
   */
  delete() {
    return stores.accounts.deleteItem(this.$route.params.id)
      .then(() => {
        this.$router.push({name: 'accounts'});
      })
      .catch((reason: any) => {
        console.error('Could not delete', reason);
      });
  }
}
