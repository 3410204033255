
import {Component, Prop, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import {SweetModal} from 'sweet-modal-vue';
import GlobalFunctions from '@/GlobalFunctions';

@Component({
  components: {
    SweetModal,
  },
})
export default class CreateGuestFlow extends Vue {
  @Prop()
  username!: any;
  @Prop()
  password!: any;

  mounted() {
    console.log(this.username);
  }

  onSubmit() {
    this.$emit('save', this.username, this.password );
    this.cancel();
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }
}
