import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import Axios from 'axios';
// @ts-ignore
import i18n from '../i18n.js';
import {generateLog} from '@/helpers/functions';

const CancelToken: any = Axios.CancelToken;
let cancel: any;
const transURL = '/translations';

// @ts-ignore
export class Translation extends Model<ITranslation> implements ITranslation {
  value!: any;
  key!: string;
  id!: number;
  translationId!: number;
  type?: string;

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {...{id: null, key: '', value: '', languageId: ''}, ...attributes}, // Default values
      parentCollection,
    );
  }

  urlRoot(): string {
    return transURL;
  }
}

export default class TranslationCollection extends Collection<Translation> {

  routeName = 'translations';

  // promise resolved when translations are updated
  fetchingTranslationsPromise: Promise<boolean | void> = Promise.resolve(true);

  url(): string {
    return transURL;
  }

  model(): Constructor<Translation> {
    return Translation;
  }

  translationList(index: number, perpage: number, search: string) {
    if (cancel !== undefined) {
      cancel();
    }
    return axiosInstance.get(`${this.url()}?perpage=${perpage}&page=${index}&search=${search}`, {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    })
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  translationLanguages() {
    return axiosInstance.get(`${this.url()}/languages`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  loadTranslations() {
    this.fetchingTranslationsPromise = this.localMessages()
      .then((response: any) => {
        const locales = response;
        for (const key in locales) {
          if (key) {
            i18n.setLocaleMessage(key, locales[key]);
          }
        }
        return true;
      }).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
  localMessages() {
    return axiosInstance.get(`${this.url()}/translation/content`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  createTranslation(translation: ITranslation) {
    return axiosInstance.post(`${this.url()}`, translation)
      .then((response) => response.data.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  exportTranslations(name: any) {
    return axiosInstance.get(`${this.url()}/export/${name}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  importTranslations(data: any) {
    return axiosInstance.post(`${this.url()}/import`, {data})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  showTranslationsByKey(key: string) {
    return axiosInstance.get(`${this.url()}/${key}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  checkKey(key: string, languageId: any) {
    return axiosInstance.get(`${this.url()}/check/${key}/${languageId}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  languagesWithTranslation(key: string) {
    return axiosInstance.get(`/translation/${key}/languages`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  deepLTranslate(data: any) {
    return axiosInstance.post('/translate', data).then((response) => {
      return response.data;
    }).catch((error) => {
      console.log('error', error.message);
    });
  }
}
