
  import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
  import {SweetModal} from 'sweet-modal-vue';

  @Component({
    components: {
      SweetModal,
    },
  })
  export default class AddSection extends Vue {
    option: any = 'collection';
    @Prop({required: true})
    isBanner!: boolean;
    @Prop()
    isNavigation!: boolean;
    @Prop()
    isHeader!: boolean;

    cancel() {
      // @ts-ignore
      this.$children[0].close();
      this.$emit('close', this.option);
    }

    update(option: string) {
      this.option = option;
    }

    next() {
      this.$emit('next', this.option);
      // @ts-ignore
      this.$children[0].close();
    }
  }
