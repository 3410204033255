
import { Component, Vue, Emit, Prop, Watch} from 'vue-property-decorator';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class EditActivationCode extends Vue {
  codeTaken = false;

   @Prop()
   error!: boolean;
  @Prop()
    code!: string;
  localActivationCode: string = '';
  /**
   * Watches for changes in the activation code
   */
  @Watch('code', {immediate: true})
    setCode() {
      this.localActivationCode = JSON.parse(JSON.stringify(this.code));
  }
  @Watch('error', {immediate: true})
    setError() {
      this.codeTaken = this.error;
  }
  @Watch('localActivationCode')
    formatCorrection() {
    this.codeTaken = false;
    const code = this.localActivationCode;
    this.localActivationCode = code.replace(/[^0-9]+/g, '');
    if (code.length > 9) {
      this.localActivationCode = code.slice(0, 9);
    }
  }

  submit() {
    const form = this.$refs.form as HTMLFormElement;
    if (form!.checkValidity()) {
      event!.stopPropagation();
      this.save();
    }
    if (form!.checkValidity() === false) {
      event!.stopPropagation();
    }
    form!.classList.add('was-validated');
  }

  /**
   * Emits save with the new activation code
   */
  save(): void {
      this.$emit('save', this.localActivationCode);
  }

  /**
   * Retracts any changes to the activation code, then closes the popup
   */
  cancel(e: any = null): void {
    if (e) {
      e.preventDefault();
    }
    this.localActivationCode = JSON.parse(JSON.stringify(this.code));
    // @ts-ignore
    this.$children[0].close();
  }
}
