
import { Component, Vue } from 'vue-property-decorator';
import stores from '@/stores';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {
    SweetModal,
  },
})

export default class CreateNewDevice extends Vue {
  deviceType: IDeviceType | null = {
    id: null,
    name: '',
    default: null,
    active: true,
  };

  mounted() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event: any) => {
        event.preventDefault();
        if (form.checkValidity() === true) {
          event.stopPropagation();
          this.save();
        }
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }
   save() {
      if (this.deviceType)  {
        return stores.DeviceType.createDeviceType(this.deviceType)
          .then((response) => {
            this.cancel();
            this.$router.push({name: 'deviceType.show', params: {id: response.id}});
          })
          .catch((e) => {
            console.log(e);
          });
    }
  }
}
