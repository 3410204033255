
import {Component, Prop, Vue} from 'vue-property-decorator';
import stores from '@/stores';

import GuestFlowModuleShow from '@/modules/GuestFlowModule/ShowBasic.vue';
import ItemsIndexGeneric from '@/components/ListNevronComponents/Items/IndexGeneric.vue';

@Component({
  components: {
    GuestFlowModuleShow,
    ItemsIndexGeneric,
  },
  watch: {
    '$route.query.tab': 'updateActiveTabFromQuery',
  },
})
export default class SocialMediaIndex extends Vue {

  activeTab = 0;
  guestFlowModule: IGuestFlowModule | null = null;
  ratingPlatformModule = stores.SocialMedia;

  async created() {
    this.updateActiveTabFromQuery();
    const response = await stores.GuestFlow.fetchAll();
    const modules = response.data;
    if (modules) {
      this.guestFlowModule = modules.find((module: IGuestFlowModule) => module.type === 'SOCIAL_MEDIA');
    }
  }


  updateActiveTabFromQuery() {
    const tab = this.$route.query.tab;
    if (typeof tab === 'string') {
      this.activeTab = parseInt(tab);
    }
  }

  updateActiveTab(tabIndex: number) {
    this.activeTab = tabIndex;
    this.$router.replace({query: {...this.$route.query, tab: String(tabIndex)}});
  }
}

