
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import stores from '@/stores';
import Edit from '@/modules/Modules/Edit.vue';
@Component({
  components: {
    Edit,
  },
})

export default class ModuleView extends Vue {
  @Prop()
  moduleId!: number;

  @Prop({required: true})
  moduleType!: ModuleType;

  module: any = null;

  rerenderKey = 0;

  @Watch('moduleId', { immediate: true })
  setModuleFromProps() {
    this.module = stores.modules.get(this.moduleId);
    Vue.prototype.WebsiteTitle.setModuleName(this.module.name);
    this.triggerRerender();
  }

  triggerRerender() {
    this.rerenderKey++;
  }
}
