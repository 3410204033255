import Axios from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import stores from '@/stores/index';
import {generateLog} from '@/helpers/functions';

const URL: string = '/features';
const CancelToken: any = Axios.CancelToken;
let cancel: any;

export class Feature extends Model<IFeature> implements IFeature {

  id!: number | null;
  name!: string;
  permission!: string;
  group!: string;
  setting?: string;
  settingType?: string;
  settingKey?: string;

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {
        ...{
          id: null,
          name: '',
          permission: '',
          group: '',
          setting: '',
          settingType: '',
          settingKey: '',
        }, ...attributes,
      }, // Default values
      parentCollection,
    );
  }

  urlRoot(): string {
    return URL;
  }
}

export default class FeatureCollection extends Collection<Feature> {

  routeName = 'roles';

  model(): Constructor<Feature> {
    return Feature;
  }

  url(): string {
    return URL;
  }

  getAll() {
    return axiosInstance.get(`${this.url()}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
}
