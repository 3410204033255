
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { modulesMetaData } from '@/helpers/constants';
import PerPageDropdown from '@/components/PerPageDropdown.vue';
import vPagination from '@/components/VuePlainPagination.vue';
import ListNevronHeader from '@/components/ListNevronComponents/ListNevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronEmpty from '@/components/ListNevronComponents/EmptyNevronHeader.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import _ from 'lodash';
import { exportData, importData, translatedDisplayName } from '@/helpers/functions';
// @ts-ignore
import EventBus from '../../EventBus';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import { showToaster } from '@/components/DashboardComposer/helpers';
import DynamicContent from '@/modules/Translation/DynamicContent.vue';
import NevronCopyMovePopupModal from '@/components/NevronCopyMovePopupModal.vue';
import axios from "axios";
// @ts-ignore
import config from '../../../config/config.js';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import stores from '@/stores';

@Component({
  components: {
    ListNevronHeader,
    Skeleton,
    NevronEmpty,
    NevronSearch,
    NevronSearchNoResults,
    PerPageDropdown,
    vPagination,
    TableGeneric,
    DynamicContent,
    NevronCopyMovePopupModal,
    TableModalGeneric
  },
})

export default class IndexGeneric extends Vue {
  @Prop()
  instance: any;
  @Prop({ type: Boolean, default: true })
  showPageTitle!: boolean;
  
  response: any = null;
  items: IItem[] = [];
  openSpecificPage: boolean = false;

  selectedArray: number[] = [];
  allChecked = false;
  resetSelected = false;

  loading = true;
  file: any = '';
  uploadResponse: any = '';
  firstLoad = true;
  perpage = 20;

  search: any = {
    query: '',
    current: '',
  };
  ids: any = [];
  moveCopy: any = '';
  checkModule: any = '';
  recipientsList: any = '';

  createComponent: any = null;
  showComponent: any = null;
  selectedItem: IItem | null = null;
  showDynamicModule: boolean = false;
  stores = stores;

  localKey: any = '';
  localText: any = '';
  referenceKey: any = '';
  index: any = '';
  list: any = '';
  timer: any = 0;
  btnDisable: boolean = false;
  searchItems = _.debounce(() => {
    this.fetchData(1);
  }, 400); // fetches data with debounce (ms)

  openCustomerPopup(recipientsList: any) {
    this.recipientsList = recipientsList;
    // @ts-ignore
    this.$refs.addRecipients.$children[0].open();
  }
  openCreatePage() {

    this.openSpecificPage = false;
    // @ts-ignore
    this.$refs.create.$children[0].open()

  }

  get pageTitle() {
    if (this.instance.name) {
      return this.instance.name;
    } else {
      return this.instanceMeta.displayName;
    }
  }

  get instanceMeta(): any {
    // @ts-ignore
    return modulesMetaData[this.instance.routeName];
  }

  get fields() {
    return this.instanceMeta.fields;
  }
  copySelected() {
    if (this.selectedArray.length === 0) {
      return;
    }
    this.moveCopy = 'copy';
    this.ids = this.selectedArray.map((el: any) => el.id);
    // @ts-ignore
    this.$refs.copymove.$children[0].open();

  }
  moveSelected() {
    if (this.selectedArray.length === 0) {
      return;
    }
    this.moveCopy = 'move';
    this.ids = this.selectedArray.map((el: any) => el.id);
    // @ts-ignore
    this.$refs.copymove.$children[0].open();

  }
  mounted() {

    //@ts-ignore
    this.$bus.$on('recipient', () => {
      console.log('rrr');
      this.openSpecificPage = true;
      //@ts-ignore
      this.$refs.create.$children[0].open();
    });
    // set search query from URL
    if (this.$route.query.search) {
      this.search.query = this.$route.query.search;
      this.search.current = this.$route.query.search;
    }
    // set perpage from URL
    if (this.$route.query.perpage) {
      this.perpage = Number(this.$route.query.perpage);
    }
    this.moduleChanged();

    // @ts-ignore
    this.$bus.$on('openTranslator', (localText: any, localKey: any, referenceKey: any) => {
      this.localText = localText;
      this.localKey = localKey;
      this.referenceKey = referenceKey;
      // @ts-ignore
      this.$refs.translator.$children[0].open();
    });
    this.showDynamicModule = this.instanceMeta['dynamicModule'];

  }

  @Watch('instance')
  moduleChanged() {
    this.index = (this.firstLoad && this.$route.query.page) ? Number(this.$route.query.page) : 1;
    this.perpage = 20;
    this.search.current = '';
    this.firstLoad = true;
    this.fetchData(this.index);
    if (!this.instanceMeta.dynamicModule) {
      Vue.prototype.WebsiteTitle.setModuleName(this.pageTitle);
    }
    this.checkModule = this.instance.routeName;
    if (this.checkModule === 'customers') {
      this.createComponent = () => import(`@/${this.instanceMeta.checkIn}.vue`);
    } else {
      if (this.instanceMeta.createComponent) {
        this.createComponent = () => import(`@/${this.instanceMeta.createComponent}.vue`);
      } else {
        this.createComponent = null;
      }
    }

    if (this.instanceMeta.showComponent) {
      this.showComponent = () => import(`@/${this.instanceMeta.showComponent}.vue`);
    } else {
      this.showComponent = null;
    }
  }

  fetchData(index: number | null) {
    this.btnDisable = true;
    if (this.firstLoad) {
      this.loading = true;
    }
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {

      return this.instance.fetchData(index, this.search.query, this.perpage)
        .then((response: any) => {
          this.response = response;
          this.items = this.response.data;
          this.search.current = this.search.query;
          this.btnDisable = false;
          if (!response) {
            showToaster('danger', '', 'Failed to fetch Data', response.code);
          }

          if (this.items.length === 0 && this.response.currentPage > 1) {
            this.fetchData(this.response.currentPage - 1);
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.firstLoad = false;
        });
    }, 500);

  }

  deleteSelected() {
    if (this.selectedArray.length === 0) {
      return;
    }
    const ids = this.selectedArray.map((el: any) => el.id);
    this.instance.deleteItems(ids)
      .then((response: any) => {
        this.refresh();
        this.resetSelected = !this.resetSelected;
        if (response.responseCode && !response.success) {
          const error = response.error.message;
          showToaster('danger', 'Error in deleting Multiple Items', Vue.prototype.translate(error));
          return;
        }
        showToaster('success', '', Vue.prototype.translate('successfully-deleted'));
        if (this.instance.routeName === 'modules') {
          // @ts-ignore
          this.$router.go();
        }
      });
  }

  checkOutSelected() {
    if (this.selectedArray.length === 0) {
      return;
    }
    const instance = axios.create({
      baseURL: config.apiUrl,
      timeout: config.apiTimeout,
    });
    const items = this.selectedArray.filter((el: any) => {
      console.log(el);
      if (el.stays.status === 'Active' || el.stays.status === 'active') {
        return this.selectedArray.map((item: any) => item.id);
      }
    });
    console.log('selected ids', items, items === [], !items);
    if (items === []) {
      console.log('selected ids', items);
      showToaster('danger', ' ', 'Only Active stay can be checkout..!!', 400);

    }
    const guests = items.map(items => {
      // @ts-ignore
      return { "request": "out", "guest_id": items.id, 'checkin_type': 'mn-checkin' };
    });
    const array: any = {
      "isDatabaseResync": true,
      "guests": []
    };
    guests.forEach(guest => {
      array.guests.push(guest);
    });
    console.log(array);

    const currentPaths = window.location.pathname.split('/').filter((el) => el.length > 0);
    instance.post(currentPaths[0], array)
      .then(response => {
        console.log(response);
        showToaster('success', ' ', 'check Out is completed..!!', 200);

      })
      .catch(error => {
        console.log(error);
        if (error.response.status !== 200) {
          showToaster('danger', ' ', error.response.data.message, error.response.status);
        }
      }); 4
  }

  openItem(item: IItem) {
    console.log(item);
    if (this.showComponent) {
      this.selectedItem = item;
      // @ts-ignore
      this.$refs.show.$children[0].open();
      return;
    }
    const showPath = (this.instanceMeta.dynamicModule) ? 'items.show' : 'show';
    this.$router.push({
      name: `${this.instance.routeName}.${showPath}`,
      params: { id: String(item.id) },
      query: { lastpage: String(this.response.currentPage), perpage: String(this.perpage) },
    });
  }

  isItemLink(fieldIndex: number) {
    const hasImage = this.fields[0].type === 'image';
    return (!hasImage && fieldIndex === 0) || (hasImage && fieldIndex === 1);
  }

  deleteItem(item: any) {
    this.instance.deleteItem(item.id).then((response: any) => {
      this.refresh();
      if (response.responseCode && !response.success) {
        const error = response.error.message;
        showToaster('danger', translatedDisplayName(item), Vue.prototype.translate(error));
        return;
      }
      showToaster('success', translatedDisplayName(item), Vue.prototype.translate('successfully-deleted'));

      if (this.instance.routeName === 'modules') {
        // @ts-ignore
        this.$router.go();
      }
    });
  }

  updateRadioValue(item: IItem, fieldName: string) {
    const data = { id: item.id };
    // @ts-ignore
    data[fieldName] = true;
    this.instance.updateItem(item.id, data);
  }

  refresh() {
    this.fetchData(this.index);
  }

  redirect(value: any) {
    if (this.instance.routeName === 'clock' || this.instance.routeName === 'locations') {
      this.refresh();
    } else {
      const showPath = (this.instanceMeta.dynamicModule) ? 'items.show' : 'show';
      this.$router.push({
        name: `${this.instance.routeName}.${showPath}`,
        params: { id: String(value) },
        query: { lastpage: String(this.response.currentPage), perpage: String(this.perpage) },
      });
    }
  }

  @Watch('response.currentPage')
  pageChange() {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }
    // this.search.query = this.search.current;
    this.fetchData(this.response.currentPage);
  }

  exportSelected() {
    // @ts-ignore
    const ids = this.selectedArray.map((item) => item.id);
    exportData(this.instanceMeta.tableName, ids).then((response) => {
      if (response.result === true) {
        const csv = response.data;
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        anchor.download = 'Nevron-' + this.instance.routeName + '.csv';
        anchor.click();
      }
    });
  }

  @Watch('uploadResponse')
  importCsv() {
    let moduleId = {};
    if (this.instance.id) {
      moduleId = { 'data': this.uploadResponse, 'module_id': this.instance.id };
    } else {
      moduleId = this.uploadResponse;
    }
    importData(moduleId, this.instanceMeta.tableName,)
      .then((response) => {
        if (response.result === true) {
          console.log(response.message);
          this.refresh();
        } else {
          console.log(response.message);
        }
      });
  }

  importFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadResponse = e.target ? e.target.result : '';
    };
    reader.readAsText(file);
  }

  get shouldHideDelete() {
    return !!this.instanceMeta.options?.hideDelete;
  }

}
