import Axios from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import stores from '@/stores/index';
import {generateLog} from '@/helpers/functions';

const CancelToken: any = Axios.CancelToken;
let cancel: any;
const URL: string = '/stays';
const TYPES: string = '/stay/categories';

export class Stays extends Model<IStays> implements IStays {

  id!: number | null;
  mainCustomerName!: string;
  numberofCustomers!: number | null;
  checkInDate!: string;
  checkOutDate!: string;
  checkInTime!: string;
  checkOutTime!: string;
  status!: string;

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {
        ...{
          id: null,
          mainCustomerName: '',
          numberofCustomers: '',
          checkInDate: '',
          checkOutDate: '',
          checkInTime: '',
          checkOutTime: '',
          status: '',
        }, ...attributes,
      }, // Default values
      parentCollection,
    );
  }

  urlRoot(): string {
    return URL;
  }
}

export default class StaysCollection extends Collection<Stays> {

  routeName = 'stays';

  model(): Constructor<Stays> {
    return Stays;
  }

  url(): string {
    return URL;
  }

  typesUrl(): string {
    return TYPES;
  }

  fetchData(index: number | null, search: string | null, perPage: number | 10) {
    if (cancel !== undefined) {
      cancel();
    }

    if (index === null) {
      index = 1;
    }

    if (search) {
      return axiosInstance.get(`${this.url()}?search=${search}&page=${index}&perpage=${perPage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    } else {
      return axiosInstance.get(`${this.url()}?page=${index}&perpage=${perPage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    }
  }

  fetchStayTypesData(index: number | null, search: string | null, perPage: number | 10) {
    if (cancel !== undefined) {
      cancel();
    }

    if (index === null) {
      index = 1;
    }

    if (search) {
      return axiosInstance.get(`${this.typesUrl()}?search=${search}&page=${index}&perpage=${perPage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    } else {
      return axiosInstance.get(`${this.typesUrl()}?page=${index}&perpage=${perPage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    }
  }

  createNewItem(newStay: any) {
    return axiosInstance.post(`${this.url()}`, newStay)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  attachStay(idList: number[], customerId: any) {
    return axiosInstance.post(`customers/${customerId}/stays/attach`, {ids: idList})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  detachStay(idList: number[], customerId: any) {
    return axiosInstance.post(`customers/${customerId}/stays/detach`, {ids: idList})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  attachCatgories(selectedInterest: [], stayId: any) {
    return axiosInstance.post(`stays/${stayId}/categories/attach`, {id: selectedInterest})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  detachCatgories(selectedInterest: [], stayId: any) {
    return axiosInstance.post(`stays/${stayId}/categories/detach`, {id: selectedInterest})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  attachCustomer(idList: number[], stayId: any) {
    return axiosInstance.post(`stays/${stayId}/customers/attach`, {ids: idList})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  detachRelatedCustomer(idList: number[], stayId: any) {
    return axiosInstance.post(`stays/${stayId}/customers/detach`, {ids: [idList]})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  detachCustomers(idList: number[], customerId: any) {
    return axiosInstance.post(`customers/${customerId}/stays/detach`, {ids: idList})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  attachStays(idList: number[], stayId: any) {
    return axiosInstance.post(`stays/${stayId}/locations/attach`, {ids: [idList]})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getDetail(staysId: any) {
    return axiosInstance.get(`/stays/${staysId}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getTypesDetail(staysId: any) {
    return axiosInstance.get(`${this.typesUrl()}/${staysId}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  /*
   * Patch
   */
  updateCategory(id: any, data: any) {
    return axiosInstance.patch(`${this.typesUrl()}/${id}`, data)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  deleteCategory(id: any) {
    return axiosInstance.delete(`${this.typesUrl()}/${id}`)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  deleteItem(id: number) {
    return axiosInstance.delete(`${this.url()}/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getAutocompleteCategories(query: string, source: any) {
    return axiosInstance.get(`/stay/categories`, {cancelToken: source.token})
      .then((response) => response.data.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  updateItem(id: any, stays: any) {
    return axiosInstance.put(`${this.url()}/${id}`, stays)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getSelectedStays(id: number) {
    return axiosInstance.get(`${this.url()}/${id}`)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  searchStays(input: string, page: number, source: any, perPage: number | 10) {
    return axiosInstance.get(`${this.url()}?query=${input}&page=${page}&perPage=${perPage}`, {cancelToken: source.token})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  createNewTypes(newStaysCategory: IStaysCategory) {
    return axiosInstance.post(`${this.typesUrl()}`, newStaysCategory)
      .then((response) => {
        stores.Language.loadTranslations();
        return response.data;
      }).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  setmainCustomer(stayId: any, customerId: any) {
    console.log(`customers/${customerId}/stays/${stayId}/main-guest`);
    return axiosInstance.get(`customers/${customerId}/stays/${stayId}/main-guest`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getStaysOnPage(index: number) {
    return axiosInstance.get(`${this.url()}?page=${index}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getAllStays() {
    return axiosInstance.get(`${this.url()}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getStaysValidationRules() {
    return axiosInstance.get(`Stays/validation`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  importStays(data: any, tableName: string) {
    return axiosInstance.post(`import/${tableName}`, {data})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  exportStays(tableName: string) {
    return axiosInstance.get(`export/${tableName}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  deleteItems(ids: any[]) {
    return axiosInstance.post(`${this.url()}/delete`, {ids})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
}
