import { render, staticRenderFns } from "./AddDestination.vue?vue&type=template&id=5e6e4fc3&v-on-clickaway=cancel&"
import script from "./AddDestination.vue?vue&type=script&lang=ts&"
export * from "./AddDestination.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports