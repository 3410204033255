
import {Component, Vue, Watch} from 'vue-property-decorator';
import EditContact from '@/modules/Customers/EditContact.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import EditCustomer from '@/modules/Customers/EditCustomer.vue';
import PreEdit from '@/modules/Customers/Address/PreEdit.vue';
import {mixin as Clickaway} from 'vue-clickaway';
import CustomerAddressEdit from '@/modules/Customers/Address/Edit.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import _ from 'lodash';
import {deletePath} from '@/helpers/DeleteHelper';
import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead.vue';
import session from '../../stores/Session';
import axiosInstance from '../../helpers/axios';
import NevronInput from '@/components/NevronInput.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import NevronImageField from '@/components/NevronImageField.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import CreateInterest from '@/modules/Interests/Create.vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';

@Component({
  components: {
    NevronFooter,
    EditContact,
    virtualList,
    EditCustomer,
    PreEdit,
    // AccountAttach,
    CustomerAddressEdit,
    AttachImage,
    NevronHeader,
    Skeleton,
    NevronSearch,
    VueBootstrapTypeahead,
    NevronInput,
    NevronImageField,
    CreateInterest,
    TableGeneric,
    NevronAdvanceTextBox,
  },
  mixins: [Clickaway],
})

export default class InterestShow extends Vue {
  interest: any = null;
  document!: IDocument;
  fullRes: any = null;
  fullResponse: any = null;
  module = stores.Interests;
  tempAcc!: IAccount;

  accountItems: IAccount[] = [];

  response: any = null;
  frontUrl: any = null;
  backUrl: any = null;
  search: any = {
    query: '',
  };

  defaultAddress: ICustomerAddress | null = null;
  selectedContact: IContact | null = null;

  indexAccountList: number[] = [];
  allAccountsChecked: boolean = false;
  searchInProgress: boolean = false;
  dragOver: boolean = false;
  preLeave: boolean = false;
  attachableContent: any = null;

  get interestId() {
    return this.$route.query.interestId || 0;
  }

  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.fetchData(Number(this.interestId));
  }

  dragEnter(num: number) {
    this.dragOver = true;
    this.preLeave = true;
    console.log('ENTER', num);
  }

  imageSelected(image: IMediaItem) {
    if (this.interest) {
      this.interest.imageId = (image) ? image.id : null;
    }
  }

  getAccountsOnPage(index: number) {
    return stores.accounts.fetchData(index, null, 10)
      .then((response) => {
        this.fullResponse = response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  dragLeave(num: number) {
    if (this.preLeave) {
      this.preLeave = false;
    } else {
      this.preLeave = false;
      this.dragOver = false;
    }
    console.log('LEAVE', num);
  }

  sortArrays(arrays: any) {
    return _.orderBy(arrays, 'sort', 'asc');
  }

  detachSubInterests(item: IItem) {
    this.interest.subinterest = this.interest.subinterest.filter((el: any) => el.id !== item.id);
  }

  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }

  /**
   * Attaches the list of languages to this customer
   */
  attachLanguages(ids: number[]) {
    return stores.Customer.attachLanguage(ids, Number(this.interestId))
      .then((response: any) => {
        this.fetchData(Number(this.interestId));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getHeaders() {
    return {'X-Project': session.project!.id};
  }

  transmitFinish(files: any, res: any) {
    this.preLeave = false;
    this.dragOver = false;
    this.attachWithImage({result: res});
  }

  onSubmitStatus() {
    return stores.Interests.updateItem(this.interest.id, this.interest)
      .then(async (response) => {
        console.log('success');
        if (response.responseCode === 200) {
          await this.fetchData(Number(this.interestId));
          showToaster('success', Vue.prototype.translate(this.interest.name), 'successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.interest.name), 'Fail to update');
        }
      }).catch((e: any) => {
        console.log(e);
        showToaster('danger', Vue.prototype.translate(this.interest.name), 'Fail to update');
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.fetchData(Number(this.interestId));
  }

  loadPage() {
    this.fetchData(Number(this.interestId));
  }

  popoverMethod() {
    if (this.interest && this.interest.id) {
      return deletePath(this.interest.id);
    }
  }

  deleteInterest() {
    return stores.Interests.deleteItem(this.interest.id)
      .then((response: any) => {
        this.$router.go(-1);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  fetchData(id: number) {
    id = id || 0;
    return stores.Interests.getDetail(Number(id))
      .then((response: any) => {
        this.interest = response.data;
      })
      .catch(() => {
        // this.$router.push({name: 'customers'});
      });

  }

  attachWithImage(imgObj: any) {
    const data = {
      imageId: null,
    };
    if (imgObj) {
      this.interest.imageId = imgObj.result.id;
      this.interest.image = this.interest.image || {};
      this.interest.image.imageUrl = imgObj.result.imageUrl;
      // @ts-ignore
      this.$refs.image.$children[0].close();
      data.imageId = imgObj.result.id;
    } else {
      this.interest.imageId = null;
      this.interest.image = null;
    }
    // @ts-ignore
    return stores.Interests.updateItem(this.interestId, data)
      .then((response: any) => {
        this.loadPage();
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  openInterest(id: number | string) {
    this.$router.push({query: {interestId: String(id)}});
  }

}
