
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import {SweetModal} from 'sweet-modal-vue';
import NevronInput from '@/components/NevronInput.vue';
import {modulesMetaData} from '@/helpers/constants';

@Component({
  components: {
    SweetModal,
    NevronInput,
  },
})

export default class NevronTablePopupModal extends Vue {
  @Prop({required: true})
  instance!: IModule;

  @Prop()
  metaKey!: string | null;

  @Prop()
  referenceKey!: string | null;

  @Prop()
  item!: any;

  @Prop()
  onlyName!: boolean;

  get instanceMeta(): any {
    // @ts-ignore
    return modulesMetaData[this.instance.routeName];
  }

  get tableMeta(): any {
    if (this.metaKey && this.instanceMeta[this.metaKey]) {
      return this.instanceMeta[this.metaKey];
    }
    return this.instanceMeta;
  }

  get fields(): any {
    if (!this.tableMeta.fields) {
      return this.instanceMeta.fields;
    }
    return this.tableMeta.fields;
  }

  cancel() {
    // @ts-ignore
    this.$refs.editPopup.close();
  }

  update() {
    this.$emit('updateItem', this.item);
    this.cancel();
  }

  openModelTranslator(localText: any, localKey: any, referenceKey: any) {
    this.$emit('openTranslators', localText, localKey, referenceKey);
  }
}
