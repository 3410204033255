
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import vPagination from '@/components/VuePlainPagination.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronEmpty from '@/components/NevronEmpty.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import _ from 'lodash';
import CreateDeviceType from './Create.vue';
import PerPageDropdown from '@/components/PerPageDropdown.vue';
import ListNevronHeader from '@/components/ListNevronComponents/ListNevronHeader.vue';
import EmptyNevronHeader from '@/components/ListNevronComponents/EmptyNevronHeader.vue';

@Component({
  components: {
    vPagination,
    ListNevronHeader,
    EmptyNevronHeader,
    Skeleton,
    NevronEmpty,
    NevronSearchNoResults,
    NevronSearch,
    CreateDeviceType,
    PerPageDropdown,
  },
})

export default class DeviceTypesList extends Vue {
  response: any = null;
  items = stores.Devices;

  perpage = 20;
  firstLoad = true;

  search: any = {
    query: '',
    current: '',
  };
  searchItems = _.debounce(() => {
    this.fetchData(1, this.search.query, this.perpage);
  }, 400);

  indexList: number[] = [];
  allChecked: boolean = false;

  @Watch('response.currentPage')
  changedPage() {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }
    this.search.query = this.search.current;
    this.fetchData(this.response.currentPage, this.search.current, this.perpage);
  }

  perPageChange() {
    this.fetchData(this.response.currentPage, this.search.query, this.perpage);
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    // set search query from URL
    if (this.$route.query.search) {
      this.search.query = this.$route.query.search;
      this.search.current = this.$route.query.search;
    }

    // set page from URL
    let index = 1;
    if (this.$route.query.page) {
      index = Number(this.$route.query.page);
    }
    // set perpage from URL
    if (this.$route.query.perpage) {
      this.perpage = Number(this.$route.query.perpage);
    }
    // load data
    this.fetchData(index, this.search.current, this.perpage);
  }

  openItem(itemId: string | number) {
    this.$router.push({
      name: 'devices.types.show',
      params: {id: String(itemId)},
      query: {
        lastpage: String(this.response.currentPage),
        perpage: String(this.perpage),
      },
    });
  }

  /* removes or appends all items ids on current page to indexList */
  checkAll() {
    if (!this.allChecked) {
      this.indexList = Array.from(new Set([...this.indexList, ...this.itemIds]));
      this.allChecked = true;
    } else {
      this.indexList = this.indexList.filter((id) => !this.itemIds.includes(id));
      this.allChecked = false;
    }
  }

  /* when item is checked */
  updateCheck() {
    if (this.itemIds.every((id: number) => this.indexList.includes(id))) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
  }

  get itemIds(): number[] {
    // @ts-ignore
    return this.items.map((item: any) => item.id);
  }

  refresh() {
    this.fetchData(this.response.currentPage, null, this.perpage);
  }

  /**
   * Fetches data on the specified page and with the specified query
   */
  fetchData(index: number | 1, search: string | null, perpage: number | 10) {
    return stores.Devices.fetchDeviceTypesData(index, search, perpage)
      .then((response) => {
        console.log('page', response);
        // wrong page
        if (response.currentPage < 0) {
          this.fetchData(1, search, perpage);
        } else if (response.currentPage > response.lastPage) {
          this.fetchData(response.lastPage, search, perpage);
        } else {
          this.response = response;
          this.items = this.response.data;
          // TODO to je treba rešiti lepše
          // če je page=1 potem tega ne dodamo :)
          // if (this.search.query === '') {
          //   this.$router.push({query: {page: String(this.response.currentPage)}});
          // } else {
          //   this.$router.push({query: {page: String(this.response.currentPage), search: this.search.query}});
          // }

          this.search.current = this.search.query;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
