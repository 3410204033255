
import {Component, Prop, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import Series from '../../../stores/modules/Series';
import VueTagsInput from '@johmun/vue-tags-input';
import Axios from 'axios';
import axiosInstance from '../../../helpers/axios';
import session from '@/stores/Session';
import { SweetModal } from 'sweet-modal-vue';
import SeriesModules from '@/stores/modules/Series';

@Component({
  components: {
    VueTagsInput,
    SweetModal,
  },
})
export default class CreateCrew extends Vue {
  @Prop()
  module!: SeriesModules;

  langTag: string = '';
  langTags: object[] = [];
  autocompleteLangItems: any[] = [];
  CancelToken: any = Axios.CancelToken;
  source: any;
  searchInProgress: boolean = false;
  imageUrl: string = '';
  attachableContent: any = null;
  contentUrl: any = '';
  selectedFolderId: any = 1;
  categriesName: any = [];
  crewCast: any = {
    id: null,
    name: '',
    role: 'director',
    sort: 1,
    crewId: null,
    castId: null,
  };
  castRollSeries: any = {
    role: 'director',
    sort: 1,
    castId: null,
  };
  seriesRollSeries: any = {
    role: 'director',
    sort: 1,
    crewId: null,
  };

  serTag: string = '';
  serTags: object[] = [];
  autocompleteSerItems: any[] = [];

  get filteredSerItems() {
    return this.autocompleteSerItems.filter((i) => {
      return i.text.toLowerCase().indexOf(this.serTag.toLowerCase()) !== -1;
    });
  }
  crew: ICrew = {
    id: null,
    name: '',
    sort: null,
    position: '',
  };
  // @ts-ignore
  cast: ICast = {
    id: null,
    name: '',
    position: '',
    sort: null,
    gender: '',
  };

  mounted() {
    console.log('yes');
    const forms = document.getElementsByClassName('needs-validation');
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event: any) => {
        event.preventDefault();
        if (form.checkValidity() === true) {
          event.stopPropagation();
          this.onSubmit();
        }
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  onSubmit() {
   return this.createCrewCast(this.crewCast)
      .then((response) => {
        this.cancel();
        this.$emit('success');
        this.crewCast.name = '';
        // this.$router.push({name: 'tv.series'});
        // this.$router.push(`/vod/${this.module.id}/movies`);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  createCrewCast(item: any) {
    if (this.crewCast.role === 'ACTOR') {
      return axiosInstance.post(`/casts`, this.crewCast)
        .then((response) => {
          this.crewCast.castId = response.data.data.id;
          for (const index in this.crewCast) {
            if (index && this.crewCast[index]) {
              if (index === 'role') {
                this.castRollSeries.role = this.crewCast[index];
              }
              if (index === 'castId') {
                this.castRollSeries.castId = this.crewCast[index];
              }
            }
          }

          return axiosInstance.post(`series/${this.module.id}/series/${this.$route.params.id}/casts/attach`, {ids: [response.data.data.id]})
            .then((res) => {
              console.log('res', res);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {

      return axiosInstance.post(`/crews`, this.crewCast)
        .then((response) => {
          this.crewCast.crewId = response.data.data.id;
          for (const index in this.crewCast) {
            if (index && this.crewCast[index]) {
              if (index === 'role') {
                this.seriesRollSeries.role = this.crewCast[index];
              }
              if (index === 'crewId') {
                this.seriesRollSeries.crewId = this.crewCast[index];
              }
            }
          }

          return axiosInstance.post(`series/${this.module.id}/series/${this.$route.params.id}/crews/attach`, {ids: [response.data.data.id], role: [this.crewCast.role]})
            .then((res) => {
              console.log('res', res);
          });

        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

}
