window.GlobalConfig = window.GlobalConfig ||  {
  appName: 'Nevron IPTV/OTT Solutions',
  url: 'http://127.0.0.1:8000',
  path: '/api/middleware',
};

function globalConfig() {
  let config: any = {};

  if (typeof window.GlobalConfig !== 'undefined') {
    config = window.GlobalConfig;
  }
  config.path = (config.path[0] === '/' ? config.path.slice(1) : config.path);

  return config;
}
export default globalConfig();
