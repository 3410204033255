
import {Component, Vue, Watch} from 'vue-property-decorator';
import EditContact from '@/modules/Customers/EditContact.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import EditCustomer from '@/modules/Customers/EditCustomer.vue';
import PreEdit from '@/modules/Customers/Address/PreEdit.vue';
import {mixin as Clickaway} from 'vue-clickaway';
import AttachImage from '@/modules/Media/Attach.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import {deletePath} from '@/helpers/DeleteHelper';
import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead.vue';
import NevronInput from '@/components/NevronInput.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import MultiSelect from 'vue-multiselect';
import NevronImageField from '@/components/NevronImageField.vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import {dynamicModules} from '@/helpers/constants';

@Component({
  components: {
    EditContact,
    virtualList,
    EditCustomer,
    PreEdit,
    AttachImage,
    NevronHeader,
    Skeleton,
    NevronSearch,
    VueBootstrapTypeahead,
    NevronInput,
    NevronFooter,
    MultiSelect,
    NevronImageField,
    TableGeneric,
    TableModalGeneric,
  },
  mixins: [Clickaway],
})

export default class ContactDetails extends Vue {
  contact: any = null;
  document!: IDocument;
  fullRes: any = null;
  fullResponse: any = null;
  tempAcc!: IAccount;
  accountItems: IAccount[] = [];
  response: any = null;
  frontUrl: any = null;
  backUrl: any = null;
  search: any = {
    query: '',
  };
  indexAssets = 1;
  modules = stores.modules.models.filter((mod: IModule) => dynamicModules.includes(mod.type));
  selectedModule: any = null;
  selectedModuleItem: any = null;
  defaultAddress: ICustomerAddress | null = null;
  selectedContact: IContact | null = null;
  module = stores.ContactModule;
  relatedItem: boolean = true;
  moduleItems: any = null;

  indexAccountList: number[] = [];
  allAccountsChecked: boolean = false;
  searchInProgress: boolean = false;
  dragOver: boolean = false;
  preLeave: boolean = false;
  attachableContent: any = null;
  loadModule: any = null;
  file: boolean = true;
  richText: boolean = true;
  stores = stores;
  loadMoreOption: boolean = false;
  isReady: boolean = false;
  workingHour = {
    monFri: [9, 17],
    monFriClose: false,
    monFriTimeSlotClose: false,
    monFriCheckboxClose: false,
    fiveDays: {
      mo: [9, 17],
      moClose: false,
      tu: [9, 17],
      tuClose: false,
      we: [9, 17],
      weClose: false,
      th: [9, 17],
      thClose: false,
      fr: [9, 17],
      frClose: false,
    },
    sa: [9, 17],
    saClose: false,
    su: [9, 17],
    suClose: false,
  };
  min = 0;
  max = 24;
  value = [0, 50];

  openMoreOption() {
    if (this.loadMoreOption) {
      this.loadMoreOption = false;
    } else {
      this.loadMoreOption = true;
    }
  }

  /**
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.fetchData(Number(this.$route.params.id));
  }

  loadModuleItems() {
    if (this.selectedModule) {
      if (this.selectedModule !== '0') {
        this.modules.forEach((item, index) => {
          if (item.id === this.selectedModule) {
            this.loadModule = item;
          }
        });
        this.loadModule.fetchData(1, '', '')
          .then((response: any) => {
            this.moduleItems = response.data;
          });
        this.relatedItem = false;
      } else {
        this.moduleItems = null;
        this.relatedItem = true;
      }
    } else {
      this.relatedItem = true;
    }
  }

  /*
   *
   */
  onSubmitStatus() {
    this.contact.workingHour = this.workingHour;
    this.contact.moduleId = this.selectedModule;
    this.contact.itemId = this.selectedModuleItem;
    const indexes = this.contact.workingHour.fiveDays.mo;
    // console.log(this.contact);
    this.contact.workingHour.monFriTimeSlotClose = false;
    this.contact.workingHour.monFriCheckboxClose = false;
    for (const index in this.contact.workingHour.fiveDays) {
      if (this.contact.workingHour.fiveDays[index].length === 2) {
        if (indexes[0] !== this.contact.workingHour.fiveDays[index][0] || indexes[1] !== this.contact.workingHour.fiveDays[index][1]) {
          this.contact.workingHour.monFriTimeSlotClose = true;
        }
      } else {
        console.log(this.contact.workingHour.fiveDays[index]);
        if (this.contact.workingHour.fiveDays[index] === true) {
          this.contact.workingHour.monFriCheckboxClose = true;
        }
      }
    }
    console.log(this.contact);

    return stores.ContactModule.updateItem(Number(this.$route.params.id), this.contact)
      .then(async (response) => {
        this.contact.workingHour.monFriClose = false;
        console.log(response);
        if (response.responseCode === 200) {
          await this.fetchData(Number(this.$route.params.id));
          showToaster('success', Vue.prototype.translate(this.contact.name), 'successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.contact.name), 'Fail to update', response.code);
        }
      }).catch((e: any) => {
        console.log(e);
        showToaster('danger', Vue.prototype.translate(this.contact.name), 'Fail to update');
      });
  }

  onSubmitActive() {
    // @ts-ignore
    return stores.ContactModule.updateItem(Number(this.$route.params.id), this.contact)
      .then((response: any) => {
        this.contact = response.data;
        console.log('success', response);
      }).catch((e: any) => {
        console.log(e);
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    setTimeout(() => {
      this.isReady = true;
      // @ts-ignore
      this.$refs.test.$children[0].open();
    }, 5000);
    this.fetchData(Number(this.$route.params.id));
    this.value = [0, 50];
    this.min = 0;
    this.max = 24;
  }

  loadPage() {
    this.fetchData(Number(this.$route.params.id));
  }

  /**
   *
   */
  popoverMethod() {
    if (this.contact && this.contact.id) {
      return deletePath(this.contact.id);
    }
  }

  deleteContact() {
    return stores.ContactModule.deleteItem(this.contact.id)
      .then((response: any) => {
        this.$router.go(-1);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  fetchData(id: number) {
    return stores.ContactModule.getDetail(id)
      .then((response: any) => {
        console.log(response.data);
        this.contact = response.data;
        if (this.contact.workingHour.saClose === '1' || this.contact.workingHour.saClose === 1) {
          this.contact.workingHour.saClose = true;
        }
        if (this.contact.workingHour.suClose === '1' || this.contact.workingHour.suClose === 1) {
          this.contact.workingHour.suClose = true;
        }
        if (this.contact.workingHour.monFriClose === '1' || this.contact.workingHour.monFriClose === 1) {
          this.contact.workingHour.monFriClose = true;
        }
        if (this.contact.workingHour.fiveDays.moClose === '1' || this.contact.workingHour.fiveDays.moClose === 1) {
          this.contact.workingHour.fiveDays.moClose = true;
        }
        if (this.contact.workingHour.fiveDays.tuClose === '1' || this.contact.workingHour.fiveDays.tuClose === 1) {
          this.contact.workingHour.fiveDays.tuClose = true;
        }
        if (this.contact.workingHour.fiveDays.weClose === '1' || this.contact.workingHour.fiveDays.weClose === 1) {
          this.contact.workingHour.fiveDays.weClose = true;
        }
        if (this.contact.workingHour.fiveDays.thClose === '1' || this.contact.workingHour.fiveDays.thClose === 1) {
          this.contact.workingHour.fiveDays.thClose = true;
        }
        if (this.contact.workingHour.fiveDays.frClose === '1' || this.contact.workingHour.fiveDays.frClose === 1) {
          this.contact.workingHour.fiveDays.frClose = true;
        }

        this.selectedModule = this.contact.moduleId;
        this.selectedModuleItem = this.contact.itemId;
        if (this.selectedModuleItem || this.selectedModule) {
          this.relatedItem = false;
        }
        this.workingHour = this.contact.workingHour;
        this.loadModuleItems();

      })
      .catch(() => {
        // this.$router.push({name: 'customers'});
      });

  }

  // assets
  attachAssets(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.assetIds.includes(el.id));
    this.contact.asset = this.contact.asset.concat(newItems);
  }

  get assetIds() {
    return this.contact.asset.map((el: IItem) => el.id);
  }

  detachAsset(item: IItem) {
    this.contact.asset = this.contact.asset.filter((el: any) => el.id !== item.id);
  }

}
