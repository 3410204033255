
import { Component, Vue, Prop } from 'vue-property-decorator';
import stores from '@/stores';
import { SweetModal } from 'sweet-modal-vue';

@Component({
 components: {
   SweetModal,
 },
})

export default class CreateCategory extends Vue {
  @Prop()
  module!: IModule;

  @Prop({default: false})
  emitCreated!: boolean;

  newCategory: ICategories = {
    id: null,
    name: '',
    description: '',
    imageId: null,
    moduleId: null,
    active: true,
    sort: null,
  };

  nameRules = {};

  inserNewCategory() {
    if (this.newCategory) {
      return this.module.createNewCategory!((this.newCategory as ICategories))
        .then((response) => {
          this.cancel();
          if (this.emitCreated) {
            this.$emit('created', response.data);
            return;
          }
          this.$router.push({name: `${this.module.path}.categories.show`, params: {id: response.data.id}});
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
  cancel() {
    (document.getElementById('create-form') as HTMLFormElement).classList.remove('was-validated');
    // @ts-ignore
    this.$children[0].close();
  }

  mounted() {

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const form = document.getElementById('create-form') as HTMLFormElement;
    form.addEventListener('submit', (event: any) => {
      event.preventDefault();
      if (form.checkValidity() === true) {
        event.stopPropagation();
        this.inserNewCategory();
      }
      if (form.checkValidity() === false) {
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  }
}
