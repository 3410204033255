
  import {Component, Vue, Model, Prop} from 'vue-property-decorator';
  import MultiSelect from 'vue-multiselect';
  import virtualList from 'vue-virtual-scroll-list';
  import stores from '@/stores';
  import Axios from 'axios';
  import AttachImage from '@/modules/Media/Attach.vue';
  import Editor from '@tinymce/tinymce-vue';
  import Skeleton from '@/modules/Skeleton.vue';
  import NevronHeader from '@/components/NevronHeader.vue';
  import session from '@/stores/Session';
  import axiosInstance from '@/helpers/axios';
  import NevronInput from '@/components/NevronInput.vue';
  import SeriesModule from '@/stores/modules/Series';
  @Component({
    components: {
      virtualList,
      AttachImage,
      Editor,
      NevronHeader,
      Skeleton,
      // ClassicEditor,
      MultiSelect,
      NevronInput,
    },
  })
  export default class Show extends Vue {
    @Prop()
    module!: SeriesModule;

    parentTye: string = 'series';

    searchQuery: string = '';
    indexList: number[] = [];
    allChecked: boolean = false;
    // @ts-ignore
    // @ts-ignore
    crew: any[] = [];
    categories: any[] = [];

    searchInProgress: boolean = false;
    CancelToken: any = Axios.CancelToken;
    source: any;
    attachableContent: any = null;

    autocompleteItems: any[] = [];
    selectedFolderId: any = 1;
    contentUrl: any = '';
    // editor: any = ClassicEditor;
    imageUrl: any = '';
    autocompleteCatItems: any[] = [];
    catTag: string = '';

    attach(a: any) {
      // @ts-ignore
      this.application.tags.push({text: a.tag.text});
      this.catTag = '';
    }

    saveAndReload() {
      // @ts-ignore
      return this.module.saveCrewItems(this.crew)
        .then((response) => {
          this.crew = response;
          console.log('crew', this.crew);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    getFilePath(): string {
      return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
    }

    getHeaders() {
      return stores.File.getHeader();
    }
    getImages() {
      // @ts-ignore
      this.$refs.image.$children[0].open();
    }

    /**
     * Called when changes need to re-render
     */
    mounted() {
      console.log('module', this.module);
      return this.module.getCrewDetails(Number(this.$route.params.crewId))
        .then((res) => {
          this.crew = res;
          console.log('Crew found', this.crew);
        })
        .catch((error) => {
          console.log((error));
        });
    }

  }
