
import { Component, Vue } from 'vue-property-decorator';
import stores from '@/stores';
import NevronHeader from '@/components/NevronHeader.vue';
import FeatureCollection, {Feature} from "@/stores/Features";
import {showToaster} from "@/components/DashboardComposer/helpers";

@Component({
  components: {
    NevronHeader,
  },
})
export default class CustomRolesCreate extends Vue {
  role: any = {
    id: null,
    name: '',
    permissions: []
  };
  featuresList: Feature[] = [];
  error: string = '';
  nameRules = {};

  onSubmit() {
    this.error = '';
    stores.roles.create(this.role)
      .then((response) => {
        showToaster('success', Vue.prototype.translate(this.role.name), 'successfully created');
        this.$router.push({name: 'access.roles'});
    }).catch((error) => {
      showToaster('danger', Vue.prototype.translate(this.role.name), 'Failed to create');
      console.log('error2', error);
      this.error = error.errors;
    });
  }

  get groupedFeatures() {
    const groups: { [key: string]: IFeature[] } = {};
    this.featuresList.forEach((feature) => {
      if (!groups[feature.group]) {
        groups[feature.group] = [];
      }
      groups[feature.group].push(feature);
    });
    return groups;
  }

  getPermissionValue(permissionKey: string): any {
    return this.role.permissions.includes(permissionKey);
  }

  updatePermissionValue(permissionKey: string, value: any) {
    const index = this.role.permissions.indexOf(permissionKey);

    if (index !== -1) {
      if(value === false) {
        this.role.permissions.splice(index, 1);
      }
    } else {
      if(value === true) {
        this.role.permissions.push(permissionKey);
      }
    }
  }

  mounted() {
    stores.roles.getRoleValidationRules()
    .then((response) => {
      const validationRules = response;
      if (validationRules[`name`]) {
        this.nameRules = validationRules[`name`];
      }
    });

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');

    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event: any) => {
        event.preventDefault();
        if (form.checkValidity() === true) {
          event.stopPropagation();
          this.onSubmit();
        }
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });

    stores.features.getAll().then((response) => {
      this.featuresList = response;
    });

  }
}
