import Collection from './abstract/Collection';
import Module from './modules/Module';
import CatalogueModule from './modules/Catalogue';
import ServiceModule from './modules/Service';
import RecorderModule from './modules/Recorders';
import Axios from 'axios';
import StreamModule from './modules/Streams';
import TvModule from './modules/TvModule';
import VODModule from './modules/VODs';
import MODModule from './modules/MOD';
// @ts-ignore
import AODModule from './modules/AOD';
import ApplicationsModule from '@/stores/modules/Applications';

import TVApplicationsModule from '@/stores/modules/TVApplications';
import WebsiteApplicationsModule from '@/stores/modules/WebsiteApplications';
import ShipPositionApplicationModule from '@/stores/modules/ShipPositionApplication';

import WeatherLocationModule from './modules/WeatherLocationModule';
import WorldClockLocationModule from './modules/WorldClockLocation';
import axiosInstance from '@/helpers/axios';
import SeriesModule from '@/stores/modules/Series';
import {generateLog} from '@/helpers/functions';
import DigitalSignageModule from '@/stores/modules/DigitalSignage';

const CancelToken: any = Axios.CancelToken;
let cancel: any;

type ModuleTypes = { [key in ModuleType]?: Constructor; };
const moduleTypeMap: ModuleTypes = {
  TV_RADIO: TvModule,
  VIDEO: VODModule,
  MOD: MODModule,
  // @ts-ignore
  AOD: AODModule,
  TRANSCODER: StreamModule,
  RECORDER: RecorderModule,
  CATALOGUE: CatalogueModule,
  SERVICE: ServiceModule,
  WEATHER: WeatherLocationModule,
  TIME: WorldClockLocationModule,
  APPLICATION: ApplicationsModule,
  TV_APPLICATION: TVApplicationsModule,
  WEBSITE_APPLICATION: WebsiteApplicationsModule,
  SHIP_POSITION_APPLICATION: ShipPositionApplicationModule,
  SERIES: SeriesModule,
};

export default class ModulesCollection extends Collection<Module> {

  routeName = 'modules';

  get items() {
    return this.models;
  }

  url() {
    return '/modules';
  }

  model() {
    return Module;
  }

  /**
   * Overwritten, to allow dynamic models
   */
  build(attributes: { [key: string]: any } = {}) {
    // @ts-ignore
    const modelClass = moduleTypeMap[attributes.type] || this.model();
    return new modelClass(attributes, this);
  }

  modules() {
    return axiosInstance.get(`${this.url()}`)
      .then((response) => response.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getModule(id: any) {
    return axiosInstance.get(`${this.url()}/${id}`)
      .then((response) => {
        return response.data;
      }).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  fetchData(index: any = 1, search: any = '', perpage: any = 100000, optionalParams = '') {
    if (cancel !== undefined) {
      cancel();
    }
    return axiosInstance.get(`${this.url()}/list/?page=${index}&search=${search}&perpage=${perpage}&${optionalParams}`, {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    })
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  createNewModule(moduel: IModule) {
    console.log('res', moduel);
    return axiosInstance.post(`${this.url()}`, moduel)
      .then((response) => {
        return response.data;
      }).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  updateItem(id: any, data: any) {
    return axiosInstance.patch(`${this.url()}/${id}`, data)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getSourceTypes() {
    return axiosInstance.get(`/devices/types/all`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  deleteModule(module: IModule) {
    return axiosInstance.delete(`${this.url()}/${module.id}`);
  }

  deleteItem(id: any) {
    return axiosInstance.delete(`${this.url()}/${id}`);
  }

  deleteItems(ids: any[]) {
    return axiosInstance.post(`${this.url()}/delete`, {ids})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  updateModuleSetting(id: any, data: any) {
    return axiosInstance.post(`${this.url()}/${id}/settings`, data)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  updateModuleOption(id: any, data: any) {
    return axiosInstance.post(`${this.url()}/${id}/options`, data)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  fetchModuleSetting(id: any) {
    return axiosInstance.get(`${this.url()}/${id}/settings`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  fetchModuleOptions(id: any) {
    return axiosInstance.get(`${this.url()}/${id}/options`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  findModuleById(moduleId: number | string) {
    return this.items.find((id: any) => Number(id) === Number(moduleId));
  }
  getSpecificModule(type: any) {
    return axiosInstance.post(`${this.url()}/list/${type}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
  copyExistingItems(ids: any = [], moduleId: any) {
    return axiosInstance.post(`${this.url()}/copy/${moduleId}`, ids)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
  moveExistingItems(ids: any = [], moduleId: any) {
    return axiosInstance.post(`${this.url()}/move/${moduleId}`, ids)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
}
