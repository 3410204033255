
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
import axiosInstance from '../../../helpers/axios';
import session from '@/stores/Session';

@Component({
  components: {
    SweetModal,
  },
  mixins: [ Clickaway ],
})

export default class EditVOD extends Vue {
  @Prop()
    movie!: IVIDEO;

  tempMovie: IVIDEO = JSON.parse(JSON.stringify(this.movie));

  @Watch('movie', {immediate: true})
    setModuleFromProps() {
      this.tempMovie = JSON.parse(JSON.stringify(this.movie));
  }

  getHeaders() {
    return stores.File.getHeader();
  }

  removeVOD() {
    if (confirm('Are you sure you wish to remove this VOD item?')) {
      this.$emit('delete');
    }
  }

  openFolderSys() {
    this.$emit('openFileSys');
    // @ts-ignore
    this.$children[0].close();
  }

  sendReload(files: any, result: any) {
    this.tempMovie.imageId = result.id;
    this.$emit('sendReload', this.tempMovie);
  }

  saveEdit() {
    this.$emit('save', this.tempMovie);
    // @ts-ignore
    this.$children[0].close();
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  getFilePath(): string {
    return axiosInstance.defaults.baseURL + '/' + stores.File.uploadUrl();
  }
}
