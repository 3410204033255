
import {Component, Vue, Watch} from 'vue-property-decorator';
import AdminChangePassword from '@/modules/Admins/ChangePassword.vue';
import {Admin} from '../../stores/Admins';
import NevronHeader from '@/components/NevronHeader.vue';
import stores from '../../stores';
import Skeleton from '@/modules/Skeleton.vue';
import NevronInput from '@/components/NevronInput.vue';
import {Role} from "@/stores/Roles";
import {Feature} from "@/stores/Features";
import {showToaster} from "@/components/DashboardComposer/helpers";

@Component({
  components: {
    NevronHeader,
    Skeleton,
    NevronInput,
  },
})

export default class AdminDetails extends Vue {
  role: Role = new Role();
  featuresList: Feature[] = [];

  /*
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.fetchData(this.$route.params.id);
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.fetchData(this.$route.params.id);
  }

  get groupedFeatures() {
    const groups: { [key: string]: IFeature[] } = {};
    this.featuresList.forEach((feature) => {
      if (!groups[feature.group]) {
        groups[feature.group] = [];
      }
      groups[feature.group].push(feature);
    });
    return groups;
  }

  getPermissionValue(permissionKey: string): any {
    return this.role.permissions.includes(permissionKey);
  }

  updatePermissionValue(permissionKey: string, value: any) {
    const index = this.role.permissions.indexOf(permissionKey);

    if (index !== -1) {
      if(value === false) {
        this.role.permissions.splice(index, 1);
      }
    } else {
      if(value === true) {
        this.role.permissions.push(permissionKey);
      }
    }
  }

  /*
   *
   */
  fetchData(id: string) {
    Role.getById(id)
      .then((role) => {
        this.role = role;
        stores.features.getAll().then((response) => {
          this.featuresList = response;
        })
      })
      .catch((reason) => {
        console.log('Could not fetch role', reason);
        if (!this.role.id) {
          this.$router.push({name: 'access.roles'});
        }
      });
  }

  /*
   *
   */
  onSubmit() {
    const data = {
      id: this.role.id,
      name: this.role.name,
      permissions: this.role.permissions,
    };

    return stores.roles.update(data)
      .then(() => {
        showToaster('success', Vue.prototype.translate(this.role.name), 'successfully updated');
        console.log('success');
      }).catch((e: any) => {
        showToaster('danger', Vue.prototype.translate(this.role.name), 'failed to update');
        console.log(e);
      });
  }

  /*
   *
   */
  removeRole() {
    if (this.role.id) {
      return stores.roles.delete(this.role.id)
        .then(() => {
          console.log('Success');
          this.$router.push('/custom-roles?page=1');
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }


}
