
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';

import ItemsIndexGeneric from '@/components/ListNevronComponents/Items/IndexGeneric.vue';

@Component({
  components: {
    ItemsIndexGeneric,
  },
})

export default class CustomersIndex extends Vue {
  // Component logic goes here
  stores = stores;

};
