
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '../../stores';
import axiosInstance from '../../helpers/axios';
import CreateFolder from '@/modules/Media/CreateFolder.vue';
import EditFolder from '@/modules/Media/EditFolder.vue';
import NevronHeader from '@/components/NevronHeader.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import NevronEmpty from '@/components/NevronEmpty.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import Skeleton from '@/modules/Skeleton.vue';

@Component({
  components: {
    NevronHeader,
    NevronSearch,
    Skeleton,
    CreateFolder,
    EditFolder,
    NevronEmpty,
    NevronSearchNoResults,
  },
})

export default class MediaList extends Vue {

  openedItem: any = null;
  pathItems: any = null;
  allChecked: boolean = false;
  selectedFiles: number[] = [];
  selectedFolders: number[] = [];
  rootId: number = 0;

  search: any = {
    query: '',
    current: '',
  };
   isImageFile(fileExtension: any) {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'eps', 'bmp', 'tiff', 'tif', 'svg', 'raw'];
    return imageExtensions.includes(fileExtension);
  };
  isVideoFile(fileExtension: any) {
    const videoExtensions = ['mp4', 'mov', 'avi', 'wmv', 'webm', 'mkv', 'mpeg-4', 'ogg'];
    return videoExtensions.includes(fileExtension);
  };
  videoType(type: any) {
    return 'video/' + type;
  };
  getHeaders() {
    return stores.File.getHeader();
  }

  editFolder(folder: any) {
    // @ts-ignore
    return stores.Folder.editFolder(folder)
      .then((response: any) => {
        this.openedItem = response;
        // @ts-ignore
        this.$refs.edit.$children[0].close();
        this.getSubItems();
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  forward(fileID: number, e: any) {
    e.preventDefault();
    this.$router.push({name: 'media.folder', params: {folderId: String(fileID)}});
    this.getSubItems();
  }

  openItem(isFile: any, id: number) {
    if (isFile) {
      this.$router.push({name: 'media.file.show', params: {fileId: String(id)}});
    } else {
      this.$router.push({name: 'media.folder', params: {folderId: String(id)}});
      this.getSubItems();
    }
  }

  back(e: any = null) {
    if (e) {
      e.preventDefault();
    }
    this.$router.push({
      name: 'media.folder',
      params: {folderId: String(this.openedItem.mediaFolderId)},
    });
    this.getSubItems();
  }

  goRoot(e: any = null) {
    if (this.openedItem.id !== 0) {
      if (e) {
        e.preventDefault();
      }
      this.$router.push({name: 'media.folder', params: {folderId: String(0)}});
      this.getSubItems();
    }

  }

  transmitFinish() {
    this.getSubItems();
  }

  getSubItems() {
    return stores.Folder.getFolder(Number(this.$route.params.folderId), this.search.query)
      .then((response: any) => {
        if (response.mediaFolderId === '') {
          this.rootId = response.id;
        }
        if (response.mediaFolderId === this.rootId) {
          response.mediaFolderId = 0;
        }
        this.openedItem = response;
        console.log(response);
        this.openedItem.children = this.openedItem.folders.concat(this.openedItem.files);
        Vue.prototype.WebsiteTitle.setTranslatedPageName(this.openedItem.name);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.getSubItems();
    window.onpopstate = () => this.getSubItems();
    Vue.prototype.WebsiteTitle.setModuleName('media-library');
  }

  openFolder(id: any) {
    this.$router.push({name: 'media.folder', params: {folderId: id}});
  }

  @Watch('$route.params.folderId')
  fetch() {
    this.getSubItems();
  }

  beforeDestroy() {
    window.onpopstate = () => {
      return true;
    };
  }

  @Watch('openedItem')
  setBreadcrumb() {
    const path = this.openedItem.path;
    const pathArray = path.split('/');
    this.pathItems = pathArray.filter(Boolean);
  }

  getFilePath() {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }

  json(value: any) {
    return JSON.stringify(value, null, 2);
  }

  checkAll() {
    if (!this.allChecked) {
      this.selectedFiles = [];
      this.selectedFolders = [];
      for (const iterator of this.openedItem.files) {
        if (iterator.id) {
          this.selectedFiles.push(iterator.id);
        }
      }
      for (const iterator2 of this.openedItem.folders) {
        if (iterator2.id) {
          this.selectedFolders.push(iterator2.id);
        }
      }
      this.allChecked = true;
    } else {
      this.selectedFiles = [];
      this.selectedFolders = [];
      this.allChecked = false;
    }
  }

  updateCheck() {
    if (this.selectedLength === this.openedItem.children.length) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
  }

  @Watch('$route')
  resetCheck() {
    this.selectedFiles = [];
    this.selectedFolders = [];
    this.allChecked = false;
  }

  deleteSelected() {
    const removeFiles = this.selectedFiles.length > 0;
    const removeFolders = this.selectedFolders.length > 0;
    if (removeFiles) {
      stores.File.deleteFiles(this.selectedFiles)
        .then((response: any) => {
          if (!removeFolders) {
            this.resetCheck();
            this.getSubItems();
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
    if (removeFolders) {
      stores.Folder.deleteFolders(this.selectedFolders)
        .then((response: any) => {
          this.resetCheck();
          this.getSubItems();
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  get selectedLength() {
    return this.selectedFiles.length + this.selectedFolders.length;
  }
}
