
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import {mixin as Clickaway} from 'vue-clickaway';
import {SweetModal} from 'sweet-modal-vue';
import LanguageDropDown from '../../components/LanguageDropDown.vue';
// @ts-ignore
import i18n from '@/i18n';
// @ts-ignore
import CKEditor from 'ckeditor4-vue';

@Component({
  components: {
    SweetModal,
    LanguageDropDown,
    ckeditor: CKEditor.component,

  },
  mixins: [Clickaway],
})

export default class AttachUserTo extends Vue {
  @Prop()
  referenceText!: any;
  @Prop()
  referenceKey!: any;
  @Prop()
  textboxType!: any;

  userLanguage: ILanguage | null = null;
  selectedLanguage: ILanguage | null = null;

  translationValue = '';
  languages: ILanguage[] = [];
  targetAvailable: boolean = false;
  message: string = '';

  mounted() {
    this.$watch('$refs.modal.is_open', (oldState, newState) => {
      if (oldState === true && newState === false && this.languages.length === 0) {
        stores.Language.activeLanguages()
          .then((languages: any) => {
            stores.admin.me()
              .then((res) => {
                // @ts-ignore
                this.userLanguage = (res.language) ? res.language : this.languages.find((x) => x.language === i18n.locale);
                this.languages = languages;
                if (this.languages.length > 0) {
                  this.selectedLanguage = this.languages[0];
                }
              });
          });
      }
    });

  }

  @Watch('selectedLanguage')
  changeLangauge(language: ILanguage) {
    this.selectedLanguage = language;
    stores.Language.checkKey(this.referenceKey, this.selectedLanguage.id)
      .then((response: any) => {
        if (response && response.value) {
          this.translationValue = response.value;
          this.message = '';
        } else {
          const data = {
            'text': this.referenceText,
            'language': this.selectedLanguage?.shortCode
          };

          stores.Language.deepLTranslate(data).then((response) => {
            if (response !== 'error') {
              this.translationValue = response;
              this.message = 'Warning! The translation is autogenerated. Review it carefully before continuing!';
            } else {
              this.translationValue = '';
            }
          });
        }
      });
  }

  save() {
    if (!this.selectedLanguage) {
      this.$swal('Alert!', 'Something Went Wrong!', 'error');
      return;
    }
    const translation = {
      key: this.referenceKey,
      languageId: this.selectedLanguage.id,
      value: this.translationValue,
      static: false,
    };
    stores.Language.createTranslation(translation)
      .then((response: any) => {
        if (response && response.id) {
          this.$swal('Done!', 'Translation Successfully Created!', 'success');
        } else {
          this.$swal('Alert!', 'Something Went Wrong!', 'error');
        }
        this.cancel();
      }).catch((e: any) => {
      this.$swal('Alert!', 'Something Went Wrong!', 'error');
      this.cancel();
    });

  }

  deepLTranslation() {

  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
    this.message = '';
  }
}
