
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
// @ts-ignore
import Switches from 'vue-switches';
// @ts-ignore
import datetime from 'vuejs-datetimepicker';
import MultiSelect from 'vue-multiselect';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import Axios from 'axios';
import Edit from '@/modules/Pages/Items/Edit.vue';
import VueTagsInput from '@johmun/vue-tags-input';
import Editor from '@tinymce/tinymce-vue';
import CatalogueModule from '../../../stores/modules/Catalogue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import {Packages} from '@/stores/Package';
import NevronInput from '@/components/NevronInput.vue';
import NevronImageField from '@/components/NevronImageField.vue';
import TablePopupModal from '@/components/NevronTablePopupModal.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import {SweetModal} from 'sweet-modal-vue';
// import "@/styles/nevron/style.scss";
import TableGeneric from '@/components/NevronTableGeneric.vue';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import DynamicContent from '@/modules/Translation/DynamicContent.vue';
import NevronClickConfirm from '@/components/NevronClickConfirm.vue';
import {translatedDisplayName} from '@/helpers/functions';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
import { hasPermission } from '@/helpers/permissions';

@Component({
  components: {
    virtualList,
    Edit,
    VueTagsInput,
    Editor,
    NevronHeader,
    NevronFooter,
    Skeleton,
    MultiSelect,
    Switches,
    datetime,
    NevronInput,
    NevronImageField,
    AttachImage,
    TableGeneric,
    TableModalGeneric,
    SweetModal,
    TablePopupModal,
    DynamicContent,
    NevronClickConfirm,
    NevronAdvanceTextBox,
  },
})
export default class PagesDetails extends Vue {
  @Prop()
  module!: CatalogueModule;

  page: any = {
    moduleId: 0,
    name: null,
    description: null,
    shortDescription: null,
    imageId: null,
    imagType: null,
    active: false,
    sort: null,
    details: {
      price: null,
      isTaxEnabled: false,
      currency: '$',
      isOrderable: false,
      isBookable: false,
      isLinkable: false,
      orderDestination: null,
      orderEmailTo: null,
      deliveryOption: null,
      ValidFrom: '',
      ValidTo: '',
      validLimit: true,
      pmsId: null,
      posId: null,
      sort: null,
      productType: null,
      vendor: null,
      quantityBar: false,
      hasAccessories: false,
    },
    // @ts-ignore
    gallery: [],
    categories: [],
    packages: [],
    tags: [],
    accessories: [],
  };
  updateAccessory: boolean = false;
  localKey: any = '';
  localText: any = '';
  referenceKey: any = '';
  stores = stores;
  metaKey: string = 'linkModuleEdit';
  displayMainImage: any = {
    moduleId: this.module.id,
    key: 'display_main_image',
    value: true,
    isTranslatable: false,
  };
  displayGallery: any = {
    moduleId: this.module.id,
    key: 'display_gallery',
    value: true,
    isTranslatable: false,
  };
  displayDescription: any = {
    moduleId: this.module.id,
    key: 'display_description',
    value: true,
    isTranslatable: false,
  };
  actionLabel: any = {
    moduleId: this.module.id,
    key: 'action_label',
    value: '',
    isTranslatable: true,
  };
  quantityBarLabel: any = {
    moduleId: this.module.id,
    key: 'quantity_bar_label',
    value: '',
    isTranslatable: true,
  };
  quantitySelectionBar: any = {
    moduleId: this.module.id,
    key: 'quantity_selection_bar',
    value: false,
    isTranslatable: false,
  };
  accessoriesHeadlineLabel: any = {
    moduleId: this.module.id,
    key: 'accessories_headline_label',
    value: '',
    isTranslatable: false,
  };
  displaySelectAllAccessories: any = {
    moduleId: this.module.id,
    key: 'display_select_all_accessories',
    value: false,
    isTranslatable: false,
  };
  specialRequestInput: any = {
    moduleId: this.module.id,
    key: 'special_request_input',
    value: false,
    isTranslatable: false,
  };
  urgencyBar: any = {
    moduleId: this.module.id,
    key: 'urgency_bar',
    value: false,
    isTranslatable: false,
  };
  requireRoomNo: any = {
    moduleId: this.module.id,
    key: 'require_room_no',
    value: false,
    isTranslatable: false,
  };
  openingHours: any = {
    moduleId: this.module.id,
    key: 'opening_hours',
    value: '',
    isTranslatable: true,
  };
  duration: any = {
    moduleId: this.module.id,
    key: 'duration',
    value: '',
    isTranslatable: true,
  };
  staffEmail: IItemSetting = {
    moduleId: this.module.id,
    itemId: this.page.id,
    key: 'staff_email',
    value: '',
    isTransalatable: true,
  };

  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;

  allCategories: ICategories[] = [];
  allPackages: IPackage[] = [];

  popupTitle: string = 'Edit Item';
  popupLabel: string = 'Name';

  packages: Packages[] = [];
  categories: any[] = [];

  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  attachableContent: any = null;
  tag: any = '';
  tags: any[] = [];
  autocompleteItems: any[] = [];
  galleryOpen: boolean = false;
  fileExplorerOpen: boolean = false;
  // updates
  coverPhoto: any = '';
  selectedFolderId: any = 1;
  contentUrl: any = '';
  // editor: any = ClassicEditor;
  imageUrl: any = '';
  autocompleteCatItems: any[] = [];
  catTag: any = '';
  dragOver: boolean = false;
  preLeave: boolean = false;
  editItem: any = '';
  pivotValue: any = '';
  accessoriesIndex: any = '';
  openingHourLength = 190;
  displayLengthWarning: boolean = false;
  translatedDisplayName: any = '';
  accessoryItem: any = '';

  getTranslatedDisplayName(item: IItem) {
    return translatedDisplayName(item);
  }

  hasPermission(permission: string): boolean {
    return hasPermission(permission);
  }

  openModelTranslator(localText: any, localKey: any, referenceKey: any) {
    this.localText = localText;
    this.localKey = localKey;
    this.referenceKey = referenceKey;
    // @ts-ignore
    this.$refs.translator.$children[0].open();
  }

  updateAccessoriesModule(item: any) {

    if (this.updateAccessory) {
      this.stores.Accessories.updateItem(item.id, item).then((response) => {
        if (response.responseCode === 200) {
          showToaster('success', Vue.prototype.translate(item.name), 'Successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(item.name), 'Fail to update', response.code);
        }
        this.loadPage();
      });
    } else {
      this.$set(this.page.accessories, this.accessoriesIndex, item);
    }
  }

  checkOpeningHoursLength(data: any) {
    if (data.length >= this.openingHourLength) {
      this.displayLengthWarning = true;
    } else {
      this.displayLengthWarning = false;
    }
  }

  openAccessoriesUpdatePopup(item: any) {
    this.editItem = item;
    this.accessoriesIndex = this.page.accessories.findIndex((element: any) => element.id === item.id);
    this.metaKey = 'accessoriesEdit';
    this.updateAccessory = true;
    // @ts-ignore
    this.$refs.editaccessories.$children[0].open();
  }

  openAccessoriesPopup() {
    // @ts-ignore
    localStorage.setItem('moduleId', this.module.id);
    // @ts-ignore
    this.$refs.attachAccessories.$children[0].open();
  }

  // Checking Link Module Status
  checkLinkModuleStatus() {
    setTimeout(() => {
      if (this.page.details.isLinkable) {
        if ((this.page.linkModulesChild.length !== 0)) {
          showToaster('danger', '', '“Linked Modules Table is not empty - can’t remove the section!');
        } else {
          showToaster('success', '', 'Linked Modules section appended at the button of the page!');
        }
      } else {
        if ((this.page.linkModulesChild.length === 0)) {
          showToaster('success', '', 'Linked Modules section removed.');
        } else {
          showToaster('danger', '', '“Linked Modules Table is not empty - can’t remove the section!');
        }
      }
    }, 500);
  }

  // Checking Accessories Status
  checkAccessoriesStatus() {
    setTimeout(() => {
      if (this.page.details.hasAccessories) {
        if (this.page.accessories !== undefined) {
          if ((this.page.accessories.length !== 0)) {
            showToaster('danger', '', '“Accessories Modules Table is not empty - can’t remove the section!');
          } else {
            showToaster('success', '', 'Accessories Modules section appended at the button of the page!');
          }
        } else {
          if ((this.page.accessories.length === 0)) {
            showToaster('success', '', 'Accessories Modules section removed.');
          } else {
            showToaster('danger', '', '“Accessories Modules Table is not empty - can’t remove the section!');
          }
        }
      }

    }, 500);
  }

  // Modules
  attachModules(childModuleId: any) {
    const newItems = childModuleId.filter((el: IItem) => !this.moduleIds.includes(el.id));
    this.page.linkModulesChild = this.page.linkModulesChild.concat(newItems);
    this.saveAndReload(this.page);
  }

  // Accessories
  attachAccessories(accessories: any) {
    const newItems = accessories.filter((el: IItem) => !this.accessoriesIds.includes(el.id));
    this.page.accessories = this.page.accessories.concat(newItems);
  }

  get moduleIds() {
    return this.page.linkModulesChild.map((el: IItem) => el.id);
  }

  get accessoriesIds() {
    return this.page.accessories.map((el: IItem) => el.id);
  }

  imageSelected(image: IMediaItem) {
    if (this.page) {
      this.page.imageId = (image) ? image.id : null;
    }
  }

  detachLinkedModules(item: IItem) {
    this.page.linkModulesChild = this.page.linkModulesChild.filter((el: any) => el.id !== item.id);
  }

  detachAccessoriesModules(item: IItem) {
    this.page.accessories = this.page.accessories.filter((el: any) => el.id !== item.id);
  }

  next(nextId: string, e: any) {
    e.preventDefault();
    this.$router.push({name: 'catalogue.items.show', params: {id: nextId}});
    this.getPage(Number(nextId));
  }

  previous(previousId: string, e: any) {
    e.preventDefault();
    this.$router.push({name: 'catalogue.items.show', params: {id: previousId}});
    this.getPage(Number(previousId));
  }

  get filteredCatItems(): any {
    return this.autocompleteCatItems.filter((i) => {
      return i.text.toLowerCase().indexOf(this.catTag.toLowerCase()) !== -1;
    });
  }

  galleryDetach(element: any, e: any = null) {
    if (e) {
      e.preventDefault();
    }
    if (this.page && this.page.id) {
      return this.module
        .detachGallery(this.page.id, element.id)
        .then((response) => {
          // @ts-ignore
          this.page.gallery.splice(element.i, 1);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  galleryAttach(imgObj: any) {
    if (this.page) {
      if (this.page && this.page.id) {
        return this.module
          .attachGallery(this.page.id, imgObj.result.id)
          .then((response) => {
            // @ts-ignore
            this.page.gallery.push(imgObj.result);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  detachCategory(a: any) {
    // @ts-ignore
    this.page.tags.splice(a.Index, 1);
  }

  attach(a: any) {
    // @ts-ignore
    this.page.tags.push({text: a.tag.text});
    this.catTag = '';
  }

  get filteredItems() {
    return this.autocompleteItems.filter((i) => {
      return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
    });
  }

  saveAndReload(p: ICatalogueItem) {
    const settings = [];
    settings.push(this.displayMainImage);
    settings.push(this.displayGallery);
    settings.push(this.displayDescription);
    settings.push(this.actionLabel);
    settings.push(this.quantityBarLabel);
    settings.push(this.quantitySelectionBar);
    settings.push(this.accessoriesHeadlineLabel);
    settings.push(this.displaySelectAllAccessories);
    settings.push(this.specialRequestInput);
    settings.push(this.urgencyBar);
    settings.push(this.requireRoomNo);
    settings.push(this.openingHours);
    settings.push(this.duration);
    settings.push(this.staffEmail);

    if (!this.galleryOpen) {
      // @ts-ignore
      p.realId = p.imageId; // this is necesary becouse if in one instance you get the actual id from image, whereas you can get the the id from where the actual image is connected in folder
    }
    console.log(p);
    const coverModuleId = this.module.id;
    const parentItemId = Number(this.$route.params.id);
    this.page.linkModules = {cover_module_id: coverModuleId, parent_item_id: parentItemId};
    this.page.itemSettings = settings;
    return this.module.updateItem(p.id!, p)
      .then(async (response) => {
        if (response.responseCode === 200) {
          await this.getPage(Number(this.$route.params.id));
          showToaster('success', Vue.prototype.translate(this.page.name), 'successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.page.name), 'Fail to update', response.code);
        }
        this.loadPage();
      });
  }

  deletePage() {
    if (this.page && this.page.id) {
      return this.module.deleteItem(this.page.id)
        .then((response) => {
          // @ts-ignore
          this.$router.push({
            name: 'catalogue.items',
            query: {page: this.$route.query.lastpage, perpage: this.$route.query.perpage},
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getPage(id: number) {
    return this.module
      .getItem(id)
      .then((response) => {
        this.page = response;
        if (this.page && this.page.image) {
          // @ts-ignore
          this.coverPhoto = this.contentUrl + this.page.image.imageUrl;
          this.imageUrl = this.page.image.imageUrl;
        }
        this.page.categories?.forEach((category: ICategories) =>
          category.name = Vue.prototype.translate(category.name));

        this.page.packages?.forEach((item: IPackage) =>
          item.name = Vue.prototype.translate(item.name));

        // this.tags = [];
        // for (const iterator of response.categories) {
        //   this.tags.push(Vue.prototype.translate(iterator));
        // }
        // // @ts-ignore
        // this.$bus.$emit('updatePopup');
        this.itemSetting(response.itemSettings);

      })
      .catch((error) => {
        console.log((error));
      });
  }

  itemSetting(settings: any) {
    for (const index of settings) {
      if (index.key === 'display_main_image') {
        if (index.value === '1') {
          this.displayMainImage.value = true;
        } else {
          this.displayMainImage.value = false;
        }
      }
      if (index.key === 'display_gallery') {
        if (index.value === '1') {
          this.displayGallery.value = true;
        } else {
          this.displayGallery.value = false;
        }
      }
      if (index.key === 'display_description') {
        if (index.value === '1') {
          this.displayDescription.value = true;
        } else {
          this.displayDescription.value = false;
        }
      }
      if (index.key === 'action_label') {
        this.actionLabel.value = index.value;
      }
      if (index.key === 'quantity_bar_label') {
        this.quantityBarLabel.value = index.value;
      }
      if (index.key === 'quantity_selection_bar') {
        if (index.value === '1') {
          this.quantitySelectionBar.value = true;
        }
      }
      if (index.key === 'accessories_headline_label') {
        this.accessoriesHeadlineLabel.value = index.value;
      }
      if (index.key === 'display_select_all_accessories') {
        if (index.value === '1') {
          this.displaySelectAllAccessories.value = true;
        }
      }
      if (index.key === 'special_request_input') {
        if (index.value === '1') {
          this.specialRequestInput.value = true;
        }
      }
      if (index.key === 'urgency_bar') {
        if (index.value === '1') {
          this.urgencyBar.value = true;
        }
      }
      if (index.key === 'require_room_no') {
        if (index.value === '1') {
          this.requireRoomNo.value = true;
        }
      }
      if (index.key === 'opening_hours') {
        this.openingHours.value = index.value;
        this.openingHours.id = index.id;
      }
      if (index.key === 'duration') {
        this.duration.value = index.value;
        this.duration.id = index.id;
      }
      if(index.key == 'staff_email') {
        this.staffEmail.value = index.value;
      }
    }
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
      this.loadPage();
  }

  @Watch('$route')
  loadPage() {
    this.getPage(Number(this.$route.params.id));
    if (this.page && this.page.image) {
      this.imageUrl = this.page.image.imageUrl;
    }
    this.getPackages();
    this.getCategories();
    this.contentUrl = '';
  }

  getPackages() {
    stores.Package.getAllPackages()
      .then((response: any) => {
        this.allPackages = response;
        this.allPackages.forEach((item: IPackage) =>
          item.name = Vue.prototype.translate(item.name));
      });
  }

  getCategories() {
    this.module.getAllCategories()
      .then((response: any) => {
        this.allCategories = response.data;
        this.allCategories.forEach((category: ICategories) =>
          category.name = Vue.prototype.translate(category.name));
      });
  }

  openItem(item: IItem) {
    this.$router.push({name: `catalogue.items`, params: {moduleId: String(item.id)}});
  }

  openLinkModulePopup(item: any) {
    this.editItem = {
      action_label: item.pivot.actionLabel,
      name: item.name,
      sort: item.sort,
      active: item.active,
    };
    this.pivotValue = item;
    console.log(this.pivotValue);
    this.metaKey = 'linkModuleEdit';
    // @ts-ignore
    this.$refs.editPopup.$children[0].open();
  }

  updateLinkModule(item: any) {
    const linkModule = {
      actionLabel: item.action_label,
      id: this.pivotValue.pivot.id,
    };
    const module = {
      name: item.name,
      sort: item.sort,
      active: item.active,
    };
    stores.modules.updateItem(this.pivotValue.pivot.childModuleId, module);
    this.module.updatedLinkModules(linkModule)
      .then((response) => {
        if (response.responseCode === 200) {
          showToaster('success', Vue.prototype.translate(this.pivotValue.name), 'Successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.pivotValue.name), 'Fail to update');
        }
        this.loadPage();
      });
  }

  deleteItem(item: any) {
    this.accessoryItem = item;
    this.translatedDisplayName = this.getTranslatedDisplayName(item);
    // @ts-ignore
    this.$refs.deleteAccessory.$children[0].open();
  }

  editAccessory(item: any) {
    this.updateAccessory = true;
    this.editItem = item;
    // @ts-ignore
    this.$refs.editaccessories.$children[0].open();
  }

  deleteAccessory() {
    this.stores.Accessories.deleteItem(this.accessoryItem.id).then((response: any) => {
      if (response.responseCode && !response.success) {
        const error = response.error.message;
        console.log(error);
        showToaster('danger', '', error, response.statusCode);
        return;
      }
      showToaster('success', translatedDisplayName(this.accessoryItem), Vue.prototype.translate('successfully-deleted'));
    });
    // @ts-ignore
    this.$bus.$emit('loadAccessories');
  }
}
