import router from '@/router';
import {RawLocation, Route} from 'vue-router';
import {ModuleTypePathMap} from './constants';

interface IQueryParams {
  [key: string]: string | string[];
}

export function moduleIndexRoute(module: IModule, query?: IQueryParams): RawLocation {
  const routeName = ModuleTypePathMap[module.type as keyof typeof ModuleTypePathMap];
  return {
    name: routeName + '.index',
    params: {moduleId: String(module.id)},
    query: query ? query : undefined,
  };
}

export function goToModuleIndex(module: IModule, query?: IQueryParams): Promise<Route> {
  return router.push(moduleIndexRoute(module, query));
}
