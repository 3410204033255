
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import Skeleton from '@/modules/Skeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import NevronInput from '../../../components/NevronInput.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    Skeleton,
    NevronHeader,
    NevronFooter,
    NevronInput,
  },
})

export default class DeviceGroupDetails extends Vue {
  deviceGroup: any={};

  /**
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.reload(this.$route.params.id);
  }

  onSubmitStatus() {
    if (this.deviceGroup) {
      return stores.DeviceGroups.updateItem(this.deviceGroup.id, {active: this.deviceGroup.active})
        .then(() => {
          console.log('success');
        }).catch((e: any) => {
          console.log(e);
        });
    }
  }

  save() {
    if (this.deviceGroup) {
      const data = {
        name: this.deviceGroup.name,
        shortDescription: this.deviceGroup.shortDescription,
        type: this.deviceGroup.type,
        
      };
      return stores.DeviceGroups.updateItem(this.deviceGroup.id, data)
        .then(async (response: any) => {
          // console.log(response, 'responseresponse'); return;
          if (response.responseCode === 200) {
            await this.reload(this.$route.params.id);
            // @ts-ignore
            showToaster('success', Vue.prototype.translate(this.deviceGroup.name), 'successfully updated');
          } else {
            // @ts-ignore
            showToaster('danger',  Vue.prototype.translate(this.deviceGroup.name), 'Fail to update');
          }
        }).catch((e: any) => {
          console.log(e);
          // @ts-ignore
          showToaster('danger',  Vue.prototype.translate(this.deviceGroup.name), 'Fail to update');
        });
    }
  }


  removeType() {
      return stores.Devices.deleteDeviceType(this.deviceGroup?.id!)
        .then((response) => {
            this.$router.push({name: 'devices.types'});
        })
        .catch((e) => {
          console.log(e);
        });
  }

  reload(id: string) {
    return stores.DeviceGroups.editGroup(Number(id))
      .then((response:any) => {
        this.deviceGroup = response;
        console.log(this.deviceGroup,'this.deviceGroup');
      })
      .catch((e) => {
        console.log(e);
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.reload(this.$route.params.id);
  }
}
