
import { Component, Vue, Prop } from 'vue-property-decorator';
import stores from '@/stores';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {
    SweetModal,
  },
})

export default class CreateDevice extends Vue {

  @Prop({default: false})
  emitCreated!: boolean;

  device: IDevice = {
    apk: '',
    firmware: '',
    ip: '',
    locationId: 0,
    macEth: '',
    macWifi: '',
    serialNumber: '',
    timeZone: '',
    type: '',
    manufacturer: 'Nevron',
    id: null,
    model: '',
    switchMac: '',
    switchIp: '',
    name: '',
    deviceTypeId: 0,

  };
  deviceTypes: IDeviceType[] = [];

  invalidMac = true;
  macEthRules = {};
  macWifiRules = {};
  serialNumberRules = {};

  macRegex = '^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$';

  mounted() {
    this.getDeviceTypes();
    stores.Devices.getDeviceValidationRules()
    .then((response) => {
      const validationRules = response;
      if (validationRules[`mac_eth`]) {
        this.macEthRules = validationRules[`mac_eth`];
      }
      if (validationRules[`mac_wifi`]) {
        this.macWifiRules = validationRules[`mac_wifi`];
      }
      if (validationRules[`serial_number`]) {
        this.serialNumberRules = validationRules[`serial_number`];
      }
    });

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const form = document.getElementById('create-form-device')! as HTMLFormElement;
    // Loop over them and prevent submission
    const validation = form.addEventListener('submit', (event: any) => {
        event.preventDefault();
        /* if (this.invalidMac && this.device.serialNumber === '') {
          return;
        } */
        if (form.checkValidity() === true) {
          event.stopPropagation();
          this.save();
        }
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  timeZoneSelected(zone: string) {
    if (this.device) {
      this.device.timeZone = zone;
    }
  }

  typeChange(type: string) {
    console.log(type);
    if (this.device) {
      this.device.type = type;
    }
  }

  getDeviceTypes() {
    return stores.Devices.getAllDeviceTypesFull()
      .then((response) => {
        this.deviceTypes = response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  save() {
    if (this.device)  {
      return stores.Devices.createNewDevice(this.device)
        .then((response) => {
          this.cancel();
          if (this.emitCreated) {
            this.$emit('created', response.data);
            return;
          }
          this.$parent?.$emit('submit', response); // what is this for?
          this.$router.push({name: 'device.show', params: {id: response.data.id}});
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  macAddressValidation() {
    if (this.device.macEth.match(this.macRegex) == null && this.device.macWifi.match(this.macRegex) == null) {
      this.invalidMac = true;
    } else {
      this.invalidMac = false;
    }
  }
}
