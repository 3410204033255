
import {Component, Vue, Watch} from "vue-property-decorator";
import globalConfig from "../../stores/GlobalConfig";
import stores from "@/stores";
import {FormWizard, TabContent} from "vue-form-wizard";
import { excludedFeaturesForProjectType } from "./ProjectManagementConfig";

@Component({
  components: {FormWizard, TabContent}
})
export default class ProjectManagement extends Vue {
  config = globalConfig.config;

  project = {
    id: '',
    name: '',
    type: null as IProjectType | null,
    settings: [] as ISetting[]
  };

  error = false;
  errorMessage = '';
  loading = false;
  isEditMode = false;
  isCopyMode = false;

  logo: ISetting = {
    id: 0,
    value: '',
    key: '',
  };

  allSettings: ISetting[] = [];
  featuresList: IFeature[] = [];
  projectTypesList: IProjectType[] = [];
  projectTypesTranslations: any = null;
  selectedProjectGroup = '';

  catalogueOptions = [
    {key: 'module-type.CATALOGUE.reservations', name:'Reservartions', value: false},
    {key: 'module-type.CATALOGUE.orders', name:'Orders', value: false},
  ];

  catalogueLimit: any = null;
  onlinePayment: ISetting = {
    key: 'feature.SETTINGS.online-payments',
    value: false,
  };

  get groupedFeatures() {
    const groups: { [key: string]: IFeature[] } = {};
    this.featuresList.forEach((feature) => {
      if (!groups[feature.group]) {
        groups[feature.group] = [];
      }
      groups[feature.group].push(feature);
    });
    return groups;
  }

  get groupedProjectTypes() {
    const groups: { [key: string]: IProjectType[] } = {};
    this.projectTypesList.forEach((projectType) => {
      if (!groups[projectType.group]) {
        groups[projectType.group] = [];
      }
      groups[projectType.group].push(projectType);
    });
    return groups;
  }

  get showPayment() {
    if (this.getSettingValue('module-type.CATALOGUE.reservations') || this.getSettingValue('module-type.CATALOGUE.orders')) {
      return true;
    }
    return false;

  }

  @Watch('project.type')
  onProjectTypeChange(newType: IProjectType, oldType: IProjectType) {
    if (!newType) {
      return;
    }
    this.updateSettingValue('project_type', newType.key);
    if (this.isEditMode) {
      return;
    }
    this.prepareCheckboxesForProjectType(newType.key);
    this.$forceUpdate();
  }

  placeholderText(key: string) {
    if(key === 'feature.STB_DASHBOARDS' || key === 'feature.MOBILE_DASHBOARDS') {
      return 'Number of sections limit';
    }
    return 'Number of digital services limit';
  }
  validateForm(formRef: string): boolean {
    const form = this.$refs[formRef] as HTMLFormElement;
    form.classList.add('was-validated');
    return form.checkValidity();
  }

  async mounted() {
    if (this.$route.params.id) {
      this.$route.name === 'projects.copy' ?
        this.isCopyMode = true :
        this.isEditMode = true;

      await this.fetchData(this.$route.params.id);
    }

    await stores.master.getFeatures();
    this.projectTypesTranslations = await stores.master.getProjectTypesTranslations() || [];
    this.featuresList = (stores.master.features || [])
      .filter(item => item.permission !== "module-type.CATALOGUE.item");
    this.prepareSettings();

    this.projectTypesList = await stores.master.getProjectTypes() || [];
    if (this.project.type) {
      this.selectedProjectGroup = this.project.type.group || '';
    }

  }

  fetchData(id: string) {
    return stores.master.getSpecificProject(id)
      .then((response: any) => {
        this.project = response.data;
        this.project.id = id;
        this.project.settings = JSON.parse(response.data.settings);
        // loading catalogue additonal
        const orders = this.project.settings.find(item => item.key === 'module-type.CATALOGUE.orders');
        const reservations = this.project.settings.find(item => item.key === 'module-type.CATALOGUE.reservations');
        const payment = this.project.settings.find(item => item.key === 'feature.SETTINGS.online-payments');
        if(orders) {
          this.catalogueOptions[1].value = orders.value;
        }
        if(reservations) {
          this.catalogueOptions[0].value = reservations.value;
        }
        if(payment) {
          this.onlinePayment.value = payment.value;
        }
        const catalogueItem = this.project.settings.find(item=> item.key === 'module-type.CATALOGUE.item.limit');
        if (catalogueItem && catalogueItem.value) {
          this.catalogueLimit = catalogueItem.value;
        }
      })
      .then((response: any) => {
        if (this.$route.name === 'projects.copy') {
          this.project.name += " copy";
        }
      })
      .catch(() => {
      });
  }

  translateForProjectType(str: string): string {
    if (!this.project.type?.key) {
      return str;
    }
    const translations = this.projectTypesTranslations[this.project.type.key]['en_UK'];
    const strKey = Vue.prototype.makeKey(str);
    const translation = translations.find((item: any) => strKey == item.key);
    if (!translation) {
      return str;
    }
    return translation.value;
  }

  prepareSettings() {
    for (const feature of this.featuresList) {
      this.allSettings.push({
        key: feature.permission,
        value: undefined,
      });
      if (feature.settingKey) {
        this.allSettings.push({
          key: feature.settingKey,
          value: undefined,
        });
      }
    }
    for (const setting of this.project.settings) {
      this.updateSettingValue(setting.key, setting.value);
    }
  }

  prepareCheckboxesForProjectType(projectTypeKey: string) {
    if (!projectTypeKey) {
      return;
    }
    this.setValueToAllFeatures(true);
    const featuresToExclude = excludedFeaturesForProjectType[projectTypeKey] || [];
    for (const setting of this.allSettings) {
      if (featuresToExclude.includes(setting.key)) {
        setting.value = false;
      }
    }
  }

  allChecked() {
    for (const setting of this.allSettings) {
      if (setting.value === false || setting.value === undefined) {
        if (!setting.key.includes('.limit')) {
          return false;
        }
      }
    }

    return true;
  }

  allCheckedInGroup(group: any) {
    for (const feature of group) {
      if (this.getSettingValue(feature.permission) === false || this.getSettingValue(feature.permission) === undefined) {
        return false;
      }
    }

    return true;
  }

  setValueToAllFeatures(value: boolean) {
    for (let index = 0; index < this.allSettings.length; index++) {
      const setting = this.allSettings[index];
      if (setting.key.includes('.limit')) {
        continue;
      }
      setting.value = value;
    }
  }

  setValueToAllFeaturesInGroup(group: any, value: boolean) {
    for (const feature of group) {
      this.updateSettingValue(feature.permission, value);
    }
  }

  getSettingValue(settingKey: string): any {
    const setting = this.allSettings.find((setting: ISetting) => setting.key == settingKey);
    return setting?.value;
  }

  updateSettingValue(settingKey: string, value: any) {
    const index = this.allSettings.findIndex((setting: ISetting) => setting.key == settingKey);
    // If setting doesn't exist, create it
    if (index === -1) {
      this.allSettings.push({key: settingKey, value: value});
      return;
    }
    // Update value of the setting
    this.allSettings[index].value = value;
  }

  onSubmit() {
    if (this.validateForm('formStep1') === false) {
      return;
    }
    this.loading = true;
    this.updateSettingValue('project_type', this.project.type?.key);
    this.updateSettingValue('feature.SETTINGS.online-payments', this.onlinePayment.value);
    this.updateSettingValue('module-type.CATALOGUE.item.limit', this.catalogueLimit);

    this.project.settings = this.allSettings.filter(setting => setting.value);
    // preparing catalogue limitation options
    const aditionalOptions = this.catalogueOptions.filter((setting: any) => setting.value)
      .map(({key, value}: { key: string; value: any }) => ({key, value}));
    if (this.showPayment && this.onlinePayment.value) {
      aditionalOptions.push(this.onlinePayment);
    }
    aditionalOptions.push({key : 'module-type.CATALOGUE.item.limit', value: this.catalogueLimit});
    this.project.settings = [...this.allSettings.filter(setting => setting.value), ...aditionalOptions];

    if (this.isCopyMode && !this.isEditMode) {
      stores.master.copyProject(this.project.id, this.project)
        .then((response) => {
          if (response.error !== null) {
            this.error = true;
            this.errorMessage = response.message;
            this.loading = false;
          } else {
            window.location.href = `${window.location.origin}/admin/projects/finish/duplicated`;
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.error = true;
            this.errorMessage = error.response.data.message;
            this.loading = false;
          }
        });
    }else if (!this.isCopyMode && this.isEditMode) {
      stores.master.updateProject(this.project.id, this.project)
        .then((response) => {
          if (response.error !== null) {
            this.error = true;
            this.errorMessage = response.message;
            this.loading = false;
          } else {
            window.location.href = `${window.location.origin}/admin/projects/finish/edited`;
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.error = true;
            this.errorMessage = error.response.data.message;
            this.loading = false;
          }
        });
    } else {
      stores.master.createProject(this.project)
        .then((response) => {
          if (!response.hasOwnProperty('tenantId')) {
            this.error = true;
            this.errorMessage = response.message;
            this.loading = false;
          } else {
            window.location.href = `${window.location.origin}/admin/projects/finish/created`;
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.error = true;
            this.errorMessage = error.response.data.message;
            this.loading = false;
          }
        });
    }
  }
}
