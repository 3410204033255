
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import stores from "@/stores";
import {SweetModal} from "sweet-modal-vue";
import {hasModulePermissionForType} from "@/helpers/permissions";
import router from '@/router';
// @ts-ignore
import EventBus from "../../EventBus";
import {goToModuleIndex} from "@/helpers/routing";

Vue.prototype.$bus = EventBus;

@Component({
  methods: {
    hasModulePermissionForType,
  },
  components: {
    SweetModal,
  },
})
export default class CreateModule extends Vue {
  newModule: IModule = this.getInitialModuleState();

  moduleName: boolean = false;

  @Prop() moduleType?: string;

  moduleTypeText = {
    TV_RADIO: "tv",
    PVR: "recording",
    MOD: "movies-on-demand",
    SERIES: "series-on-demand",
    SERVICE: "service",
    TIME: "time-h",
    WEATHER: "weather",
    TV_APPLICATION: "tv-application",
    WEBSITE_APPLICATION: "website-application",
    SHIP_POSITION_APPLICATION: "ship-position-application",
    CATALOGUE: "catalog",
    AOD: "aod",
    VIDEO: "video",
  };

  errors: string[] = [];

  get allowedModuleTypes() {
    if (this.moduleType == 'VOD') {
      return ['MOD', 'SERIES'];
    } else if (this.moduleType == 'WIDGETS') {
      return ['TIME', 'WEATHER'];
    } else if (this.moduleType == 'APPLICATION') {
      return ['WEBSITE_APPLICATION', 'TV_APPLICATION'];
    }

    return null;
  }

  get isSelectDisabled(): boolean {
    return Boolean(this.moduleType) && !Boolean(this.allowedModuleTypes);
  }

  get filteredModuleTypes(): ModuleType[] {
    if (!this.allowedModuleTypes || this.allowedModuleTypes.length === 0) {
      return Object.keys(this.moduleTypeText).filter((type) => hasModulePermissionForType(type)) as ModuleType[];
    }
    return this.allowedModuleTypes.filter((type) => hasModulePermissionForType(type)) as ModuleType[];
  }

  getInitialModuleState(): IModule {
    return {
      description: "",
      id: null,
      name: "",
      note: "",
      status: "ENABLED",
      type: "TV_RADIO",
    };
  }

  onModalOpen() {
    this.onModuleTypeChanged();
  }

  mounted() {
    const form = document.getElementById('create-form-module') as HTMLFormElement;
    form.addEventListener('submit', (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      this.save();
    }, false);
  }

  @Watch("moduleType")
  onModuleTypeChanged() {
    if (this.moduleType && this.filteredModuleTypes.includes(this.moduleType as ModuleType)) {
      this.newModule.type = this.moduleType as ModuleType;
    } else if (this.filteredModuleTypes.length > 0) {
      this.newModule.type = this.filteredModuleTypes[0];
    } else {
      this.newModule.type = "TV_RADIO";
    }
  }

  cancel() {
    this.errors = [];
    this.newModule = this.getInitialModuleState();
    const form = document.getElementById('create-form-module') as HTMLFormElement;
    form.classList.remove('was-validated');
    // @ts-ignore
    this.$children[0].close();
  }

  save() {
    this.errors = [];
    const form = document.getElementById('create-form-module') as HTMLFormElement;
    if (form.checkValidity() === true) {
      stores.modules.createNewModule(this.newModule)
        .then((response: any) => {
          if (!response.success) {
            throw new Error(response.error.message);
          }
          this.cancel();
          stores.modules.getData().then((r) => {
            goToModuleIndex(response.data, {tab: 'settings'});
          });
        })
        .catch((e: any) => {
          this.errors.push(e.message);
        });
    }
    form.classList.add('was-validated');
  }
}
