
import { Component, Vue, Prop } from 'vue-property-decorator';
// @ts-ignore
import stores from '@/stores';
import TvModule from '@/stores/modules/TvModule';
import session from '@/stores/Session';
import WeatherLocationModule from '../../stores/modules/WeatherLocationModule';
import axiosInstance from '../../helpers/axios';
import Countries from '@/modules/Countries.vue';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {
    Countries,
    SweetModal,
  },
})
export default class CreateWeatherLocation extends Vue {
  @Prop()
  module!: WeatherLocationModule;

  newWeatherlocation: IWeatherLocation = {
    id: null,
    moduelId: this.module.id,
    city: '',
    country: '',
    latitude: 0,
    longitude: 0,
  };

  cityRules = {};
  countryRules = {};
  latitudeRules = {};
  longitudeRules = {};

  invalidLatitude: boolean | null = null;
  invalidLongitude: boolean | null = null;

  countrySelected(country: string) {
    this.newWeatherlocation.country = country;
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  roundLat(val: any) {
    // @ts-ignore
    this.newWeatherlocation.latitude = parseFloat(parseFloat(val).Math.trunc(2));
  }

  roundLong(val: any) {
    // @ts-ignore
    this.newWeatherlocation.longitude = parseFloat(parseFloat(val).Math.trunc(2));
  }

  inserNewWeatherlocation() {
    return this.module.createNewItem(this.newWeatherlocation)
      .then((response) => {
        this.cancel();
        this.$emit('created', response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  mounted() {
    this.newWeatherlocation = {
      id: null,
      moduelId: this.module.id,
      city: '',
      country: '',
      latitude: 0,
      longitude: 0,
    };
    this.module.getWeatherLocationsRules()
    .then((response) => {
      const validationRules = response;
      if (validationRules[`city`]) {
        this.cityRules = validationRules[`city`];
      }
      if (validationRules[`country`]) {
        this.countryRules = validationRules[`country`];
      }
      if (validationRules[`latitude`]) {
        this.latitudeRules = validationRules[`latitude`];
      }
      if (validationRules[`longitude`]) {
        this.longitudeRules = validationRules[`longitude`];
      }
    });

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event: any) => {
        event.preventDefault();
        if (form.checkValidity() === true) {
          event.stopPropagation();
          this.inserNewWeatherlocation();
        }
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }
}
