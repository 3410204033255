import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import {generateLog} from '@/helpers/functions';
import Axios from "axios";
let cancel: any;
const CancelToken: any = Axios.CancelToken;

const URL = '/notifications';
export class Notification extends Model<ICustomerAddress> implements ICustomerAddress {

    address!: string;
    address2!: string;
    city!: string;
    country!: string;
    id!: number | null;
    customerId!: number | null;
    post!: string;
    default!: number;
    firstName!: string;
    lastName!: string;
    company!: string;

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {...{address: '', address2: '', city: '', country: '', id: 0, customerId: 0, post: '', default: 0, company: '', firstName: '', lastName: ''}, ...attributes}, // Default values
      parentCollection,
    );
  }
  urlRoot(): string {
    return URL;
  }
}

export default class NotificationCollection extends Collection<Notification> {

  routeName = 'notifications';
  url(): string {
    return URL;
  }

  model(): Constructor<Notification> {
    return Notification;
  }
  deleteItem(id: number) {
    return axiosInstance.delete(`${this.url()}/${id}`)
      .then((response) => response.data);
  }
  deleteItems(ids: any[]) {
    return axiosInstance.post(`${this.url()}/delete`, {ids})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
  fetchData(index: number | null, search: string | null, perpage: number | 10) {
    if (cancel !== undefined) {
      cancel();
    }

    if (index === null) {
      index = 1;
    }

    if (search) {
      return axiosInstance.get(`${this.url()}?search=${search}&page=${index}&perpage=${perpage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    } else {
      return axiosInstance.get(`${this.url()}?page=${index}&perpage=${perpage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    }
  }

  createNotification(notification: INotification) {
    return axiosInstance.post(`${this.url()}`, notification)
      .then((response) => {
        return response.data;
      }).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }


  getNotificationValidationRules() {
    return axiosInstance.get(`notification/validation`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

}
