import { render, staticRenderFns } from "./VueBootstrapTypeahead.vue?vue&type=template&id=40bc6264&scoped=true&"
import script from "./VueBootstrapTypeahead.vue?vue&type=script&lang=js&"
export * from "./VueBootstrapTypeahead.vue?vue&type=script&lang=js&"
import style0 from "./VueBootstrapTypeahead.vue?vue&type=style&index=0&id=40bc6264&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40bc6264",
  null
  
)

export default component.exports