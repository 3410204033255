
import { Component, Vue, Prop } from 'vue-property-decorator';
import stores from '@/stores';
import {SweetModal} from 'sweet-modal-vue';
import {goToModuleIndex} from '@/helpers/routing';

@Component({
  components: {
    SweetModal,
  },
})
export default class CreateCategory extends Vue {
  @Prop()
  module!: IModule;

  @Prop()
  parentId!: number | string;

  @Prop({default: false})
  emitCreated!: boolean;

  newCategory: ICategoriesMulti = this.emptyCategory();

  emptyCategory() {
        return {
        id: null,
        parentId: null,
        name: '',
        description: '',
        imageId: null,
        moduleId: null,
        active: true,
        sort: null,
        subitems: [],
        subcategories: [],
    };
  }

  insertNewCategory() {
    if (this.newCategory) {
        this.newCategory.parentId = this.parentId;
        return this.module.createNewCategory!(this.newCategory as ICategories)
            .then((response) => {
              this.cancel();
              if (this.emitCreated) {
                this.$emit('created', response.data);
                return;
              }
              const categoryId = String(response.data.id);
              goToModuleIndex(this.module, {tab: 'configuration', categoryId: categoryId});
            })
            .catch((e) => {
            console.log(e);
            });
    }
  }
  cancel() {
    (document.getElementById('create-form-category') as HTMLFormElement).classList.remove('was-validated');
    this.newCategory = this.emptyCategory();
    // @ts-ignore
    this.$children[0].close();
  }

  mounted() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const form = document.getElementById('create-form-category') as HTMLFormElement;
    form.addEventListener(
      'submit',
      (event: any) => {
        event.preventDefault();
        if (form.checkValidity() === true) {
          event.stopPropagation();
          this.insertNewCategory();
        }
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      },
      false,
    );
  }
}
