
import { Component, Vue, Watch } from 'vue-property-decorator';
import Edit from '@/modules/Packages/Edit.vue';
import stores from '@/stores';
import AttachItem from '@/modules/Packages/Attachpackageitem.vue';
import vPagination from '@/components/VuePlainPagination.vue';
import Axios from 'axios';
import AttachImage from '@/modules/Media/Attach.vue';
import collection from '@/stores/abstract/Collection.ts';
import session from '../../stores/Session';
import axiosInstance from '../../helpers/axios';
import NevronHeader from '@/components/NevronHeader.vue';

@Component({
  components: {
    Edit,
    AttachItem,
    AttachImage,
    vPagination,
    NevronHeader,
  },
})

export default class PackagesDetails extends Vue {
  selectedPackage: IPackage | null = null;
  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;
  moduleItems: any[] = [];
  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  attachableContent: any = null;
  modules: any[] = [];
  dragOver: boolean = false;
  preLeave: boolean = false;

  /**
   *
   */
  @Watch('$route', { immediate: true, deep: true })
  changePage() {
    this.refresh(Number(this.$route.params.id));
  }

  dragEnter(num: number) {
    this.dragOver = true;
    this.preLeave  = true;
    console.log('ENTER', num);
  }

  dragLeave(num: number) {
    if (this.preLeave) {
      this.preLeave = false;
    } else {
      this.preLeave = false;
      this.dragOver = false;
    }
    console.log('LEAVE', num);
  }

  onSubmit() {
    if (this.selectedPackage) {
      const data = {
        name: this.selectedPackage.name,
        comment: this.selectedPackage.comment,
      };

      return stores.Package.update(this.selectedPackage.id, data)
        .then((response) => {
          console.log('Success');
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  onSubmitStatus() {
    if (this.selectedPackage) {
      return stores.Package.update(this.selectedPackage.id, {active: this.selectedPackage.active})
        .then(() => {
          console.log('success');
        }).catch((e: any) => {
          console.log(e);
        });
    }
  }

  /**
   *
   */
  getFilePath(): string {
      return axiosInstance.defaults.baseURL + '/' + stores.File.uploadUrl();
  }

  /**
   *
   */
  getHeaders() {
    return stores.File.getHeader();
  }

  /**
   *
   */
  transmitFinish(files: any, res: any) {
    this.preLeave = false;
    this.dragOver = false;
    this.attachWithImage({result: res});
  }
  /**
   *
   */
  attachWithImage(imgObj: any) {
    if (this.selectedPackage) {
      this.saveEmited(this.selectedPackage);
    }
  }

  removePackageItem(item: IPackageItem, index: number,  e: any) {
    e.preventDefault();
    if (confirm('Do you really want to remove this package item?')) {
      return stores.PackageItems.deleteItem(Number(item.id))
        .then((response) => {
          console.log('Success!');
          if (this.selectedPackage) {
            this.selectedPackage.items.splice(index, 1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getItemsForModule(moduleObject: any) {
    // @ts-ignore
    return stores.Package.getItemsForModule(moduleObject.id, moduleObject.type, this.selectedPackage ? this.selectedPackage.id : 0)
      .then((response) => {
        this.moduleItems = response;
      })
      .catch((error) => {
        alert(error);
      });
  }

  searchItemsToAttach(query: string) {
    // this.searchQuery = query;
    // this.CancelToken = Axios.CancelToken;
    // this.source = this.CancelToken.source();
    // console.log('query', this.searchQuery);
    // console.log('search requested');
    // if (!this.searchInProgress) {
    //   this.searchInProgress = true;
    //   setTimeout(() => {
    //     return stores.Package.search(this.searchQuery, this.fullResponse.currentPage, this.source.token)
    //       .then((response) => {
    //         console.log(this.searchQuery);
    //         this.fullResponse = response;
    //         if (this.searchQuery !== '') {
    //           this.$router.push({query: {query: this.searchQuery, page: String(this.fullResponse.currentPage)}});
    //         } else {
    //           this.$router.push({query: {page: String(this.fullResponse.currentPage)}});
    //         }
    //         console.log('search executed');
    //         this.searchInProgress = false;
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //       });
    //   }, 500);
    // } else {
    //   this.source.cancel();
    // }
  }

  attachSaveEmited(ret: any) {
    if (this.selectedPackage && this.selectedPackage.id) {
      console.log(ret);
      return stores.PackageItems.attachItem(ret.list, this.selectedPackage.id, ret.modelId)
        .then((response) => {
          console.log('Successfully attached devices with IDs ' + response);
          // @ts-ignore
          this.$refs.attach.$children[0].close();
          if (this.selectedPackage && this.selectedPackage.id) {
            this.refresh(this.selectedPackage.id);
          }
          this.moduleItems = [];
          // this.getDevices();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  back() {
    return stores.Folder.getFolder(this.attachableContent.mediaFileId)
      .then((response) => {
        this.attachableContent = response;
        this.attachableContent.children = this.attachableContent.folders.concat(this.attachableContent.files);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  forward(id: number) {
    return stores.Folder.getFolder(id)
      .then((response) => {
        this.attachableContent = response;
        this.attachableContent.children = this.attachableContent.folders.concat(this.attachableContent.files);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getImages() {
    return stores.Folder.getFolder(1)
      .then((response) => {
        this.attachableContent = response;
        this.attachableContent.children = this.attachableContent.folders.concat(this.attachableContent.files);
        // @ts-ignore
        this.$refs.image.$children[0].open();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  next(nextId: string, e: any) {
    e.preventDefault();
    this.$router.push({name: 'packages.show', params: {id: nextId}});
    this.refresh(Number(nextId)); // TODO: FIX
  }

  previous(previousId: string, e: any) {
    e.preventDefault();
    this.$router.push({name: 'packages.show', params: {id: previousId}});
    this.refresh(Number(previousId)); // TODO: FIX
  }

  saveEmited(newPackage: IPackage) {
    return stores.Package.updateItem(newPackage.id!, newPackage)
      .then((response: any) => {
        console.log('You have successfully edited this package');
        if (newPackage.id) {
          this.refresh(newPackage.id);
        }
        // @ts-ignore
        this.$refs.edit.$children[0].close();
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  checkAll() {
    if (!this.allChecked && this.selectedPackage) {
      this.indexList = [];
      for (let index = 0; index < this.selectedPackage.items.length; index++) {
        this.indexList.push(index);
      }
      this.allChecked = true;
    } else {
      this.indexList = [];
      this.allChecked = false;
    }
  }

  updateCheck() {
      if (this.selectedPackage && this.indexList.length === this.selectedPackage.items.length) {
         this.allChecked = true;
      } else {
         this.allChecked = false;
      }
  }

  search() { // TODO: Implement
    // this.CancelToken = Axios.CancelToken;
    // this.source = this.CancelToken.source();
    // if (!this.searchInProgress) {
    //   this.searchInProgress = true;
    //   setTimeout(() => {
    //     if (this.selectedPackage && this.selectedPackage.erpId) {
    //       return stores.Account.searchAccountsDevices(this.selectedPackage.erpId, this.searchQuery, this.fullResponse.currentPage, this.source.token)
    //         .then((response) => {
    //           // console.log(this.searchQuery);
    //           this.fullResponse = response;
    //           if (this.selectedPackage) {
    //             this.selectedPackage.devices = this.fullResponse.data;
    //           }
    //           if (this.searchQuery !== '') {
    //             this.$router.push({query: {query: this.searchQuery, page: String(this.fullResponse.currentPage)}});
    //           } else {
    //             this.$router.push({query: {page: String(this.fullResponse.currentPage)}});
    //           }
    //           this.searchInProgress = false;
    //         })
    //         .catch((e) => {
    //           console.log(e);
    //         });
    //     }
    //   }, 500);
    // } else {
    //   this.source.cancel();
    //   // console.error('Cancle');
    //   // this.searchInProgress = false;
    // }
  }

  getItems() { // TODO: Implement
    // return stores.Account.getAccountDevices(this.$route.params.id)
    //   .then((response) => {
    //     if (this.selectedPackage) {
    //       this.selectedPackage.devices = response;
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }

  removePackage() {
    if (this.selectedPackage && this.selectedPackage.id ) {
      return stores.Package.deleteItem(this.selectedPackage.id)
        .then((response: any) => {
          console.log('You have successfully deleted an account');
          // @ts-ignore
          this.$router.push({name: 'packages'});
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }

  refresh(id: number) {
    return stores.Package.getSelectedPackage(id)
      .then((response) => {
        this.selectedPackage = response;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.refresh(Number(this.$route.params.id));
    stores.modules.getData()
    .then(() => {
      this.modules = stores.modules.models;
    });
    return new Promise((resolve, reject) => {
      stores.modules.getData().catch((e) => {
        reject(e);
      });
    });
  }
}
