
import {Component, Vue} from 'vue-property-decorator';
import NevronHeader from '@/components/NevronHeader.vue';
import stores from '../../stores';
import Skeleton from '@/modules/Skeleton.vue';
// @ts-ignore
import Switches from 'vue-switches';
import {Setting} from "@/stores/Setting";

@Component({
  components: {
    NevronHeader,
    Skeleton,
    Switches,
  },
})

export default class PaymentsDetails extends Vue {
  mail: any = '';
  response: any = '';

  request_check_out: ISetting = {
    key: 'request_check_out',
    value: "",
  };
  request_checkout_email: ISetting = {
    key: 'request_checkout_email',
    value: [],
  };
  survey: ISetting = {
    key: 'survey',
    value: "",
  };
  view_bill: ISetting = {
    key: 'view_bill',
    value: "",
  };
  casting: ISetting = {
    key: 'casting',
    value: "",
  };

  /**
   * Called when changes need to re-render
   */
  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('payment-configuration');
    this.fetch();
  }

  fetch() {
    stores.Setting.getSettings()
      .then((response) => {
        this.settings(response);
        console.log(response);
      });
  }

  settings(data: ISetting[]) {
    // @ts-ignore
    for (const index in data) {
      if (data[index].key === 'request_check_out') {
        this.request_check_out = data[index];
        this.request_check_out.value = this.request_check_out.value !== '0';
      } else if (data[index].key === 'request_checkout_email') {
        this.request_checkout_email = data[index];
        this.request_checkout_email.value = data[index].value;
      } else if (data[index].key === 'survey') {
        this.survey = data[index];
        this.survey.value = this.survey.value !== '0';
      }  else if (data[index].key === 'view_bill') {
        this.view_bill = data[index];
        this.view_bill.value = this.view_bill.value !== '0';
      } else if (data[index].key === 'casting') {
        this.casting = data[index];
        this.casting.value = this.casting.value !== '0';
      }
    }
  }

  /*
   *
   */
  onSubmit(id: any, value: any) {
    stores.Setting.update(id, {value})
      .then((response) => {
        console.log(response);

        this.fetch();
        console.log('setting updated successfully..');
      });
  }

  updateMultiple() {
    const array = [this.request_check_out, this.request_checkout_email];
    console.log(array);
    for (const index in array) {
      if (index) {
        console.log(array[index]);
        this.onSubmit(array[index].id, array[index])
      }
    }

  }
}
