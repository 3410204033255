
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import Axios from 'axios';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
import vPagination from '@/components/VuePlainPagination.vue';
import NevronSearch from '../../components/NevronSearch.vue';
import NevronSearchNoResults from '../../components/NevronSearchNoResults.vue';
import _ from 'lodash';
@Component({
  components: {
    SweetModal,
    vPagination,
    NevronSearch,
    NevronSearchNoResults,
  },
  mixins: [ Clickaway ],
})
export default class AttachItemToPackage extends Vue {

  @Prop({default: null})
  package!: IPackage;

  response: any = null;
  items: any[] = [];

  // map of  module: [item_ids] key value pairs
  indexMap = new Map<number, number[]>();
  indexMapSize = 0;
  // item_ids array for specific module
  indexList: any[] = [];
  allChecked = false;
  searchQuery: string = '';
  modules: IModule[] = [];
  selectedModule: IModule | null = null;
  allowedModules = ['TV_RADIO', 'VIDEO', 'MOD', 'CATALOGUE', 'SERVICE', 'APPLICATION', 'SERIES','AOD'];

  searchItems = _.debounce(() => { this.getItemsForModule(1); }, 400); // fetches data with debounce (ms)

  /**
   * Called when changes need to re-render
   */
  mounted() {
      stores.modules.getData()
          .then(() => {
              // filter modules that should be shown
              this.modules = stores.modules.models.filter((mod) => (this.allowedModules.includes(mod.type)));
              if (this.modules.length > 0) {
                this.selectedModule = this.modules[0];
                for (const mod of this.modules) {
                  // @ts-ignore
                  this.indexMap[mod.id!] = [];
                }
                // fetch items for selected module
                this.getItemsForModule(1)
                  .then((response: any) => {
                    this.$watch('response.currentPage', this.changedPage);
                    if (this.package) {
                      this.$watch('$refs.modal.is_open', (oldState, newState) => {
                        if (oldState === true && newState === false) {
                          this.getItemsForModule(1);
                        }
                      });
                    }
                  });
              }
          });
  }

  getItemsForModule(index = 1) {
      if (this.package) {
        // @ts-ignore
        return stores.Package.getItemsForModule(this.selectedModule!, this.package.id, Number(index), this.searchQuery)
            .then((response: any) => {
              this.response = response;
              this.items = response.data;
              console.log(this.items);
              this.updateCheck();
            })
            .catch((error: any) => {
              console.log(error);
            });
      } else {
        // @ts-ignore
        return this.selectedModule!.fetchData(Number(index), this.searchQuery, 10)
          .then((response: any) => {
            this.response = response;
            this.items = response.data;
            this.updateCheck();
          });
      }

  }

  changedPage() {
    this.getItemsForModule(this.response.currentPage);
  }

  selectModule(mod: any) {
    if (this.selectedModule !== mod) {
      this.selectedModule = mod;
      // @ts-ignore
      this.indexList = this.indexMap[this.selectedModule!.id!];
      this.getItemsForModule(1);
    }
  }

  save() {
    this.$emit('save', this.indexMap);
    this.cancel();
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
    this.allChecked = false;
    this.searchQuery = '';
    this.indexList = [];
    for (const [moduleId, indexList] of Object.entries(this.indexMap)) {
      // @ts-ignore
      this.indexMap[moduleId] = [];
    }
  }

  checkAll() {
    if (!this.allChecked) {
      this.indexList = Array.from(new Set([...this.indexList, ...this.items]));
      this.allChecked = true;
    } else {
      this.indexList = this.indexList.filter((item) => !this.items.includes(item));
      this.allChecked = false;
    }
    this.updateIndexMap();
  }
  updateCheck() {
    if (this.items.every((item: any) => this.indexList.includes(item))) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
    this.updateIndexMap();
  }
  updateIndexMap() {
    // @ts-ignore
    this.indexMap[this.selectedModule.id] = this.indexList;
    let count = 0;
    for (const indexList of Object.values(this.indexMap)) {
      count += indexList.length;
    }
    this.indexMapSize = count;
  }

  sortCheck(itemId: number, action: string) {
    return;
  }

}
