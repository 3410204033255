
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

import MailConfiguration from '@/modules/MailConfiguration/Index.vue';
import SmsConfiguration from '@/modules/SmsConfiguration/Index.vue';
import PaymentsConfiguration from '@/modules/Payments/Index.vue';
import Other from '@/modules/Settings/Other.vue';
import File from '@/modules/File/Index.vue';
import TimeServices from '@/modules/Settings/TimeServices.vue';
import {hasPermission, hasModulePermissionForType} from '@/helpers/permissions';
import EpgSources from '@/modules/Epgs/Index.vue';

@Component({
  methods: {hasModulePermissionForType},
  components: {
    MailConfiguration,
    SmsConfiguration,
    PaymentsConfiguration,
    Other,
    File,
    TimeServices,
    EpgSources
  },
})

export default class SystemService extends Vue {
  // Component logic goes here
  hasPermission(permission: string): boolean {
    return hasPermission(permission);
  }
};
