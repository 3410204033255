
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import Axios from 'axios';
import {mixin as Clickaway} from 'vue-clickaway';
import {SweetModal} from 'sweet-modal-vue';
import vPagination from '@/components/VuePlainPagination.vue';
import CreateUser from './Create.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import _ from 'lodash';
import axiosInstance from '@/helpers/axios';
import CreateCrewCast from '@/modules/Series/List/CreateCrewCast.vue';
import SeriesModules from '@/stores/modules/Series';
import AODModule from "@/stores/modules/AOD";

@Component({
  components: {
    CreateCrewCast,
    SweetModal,
    vPagination,
    NevronSearchNoResults,
    NevronSearch,
  },
  mixins: [Clickaway],
})

export default class AttachCrewCastTo extends Vue {
  @Prop()
  module!: AODModule;
  @Prop()
  fullRes!: any;

  @Prop()
  accId!: number;

  response: any = null;
  items: any = null;
  crewIndexList: number[] = [];
  castIndexList: number[] = [];
  allChecked: boolean = false;
  searchQuery: string = '';
  searchUsers = _.debounce((res: any) => {
    this.fetchCreworCast(res);
  }, 400);

  mounted() {
    this.fetchCreworCast();
  }

  save() {
    this.attachCrew(this.crewIndexList);
    this.attachCast(this.castIndexList);

    // this.$emit('attach', this.crewIndexList);
    // this.cancel();
  }

  attachCrew(ids: number[]) {
    return this.module.attachCrew(ids, Number(this.$route.params.id))
      .then((response: any) => {
        this.$emit('attach', this.crewIndexList);
        this.cancel();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  attachCast(ids: number[]) {
    return this.module.attachCast(ids, Number(this.$route.params.id))
      .then((response: any) => {
        this.$emit('attach', this.castIndexList);
        this.cancel();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  cancel() {
    this.$emit('attach');
    // @ts-ignore
    this.$children[0].close();
    this.allChecked = false;
  }

  @Watch('response.currentPage')
  fetchCreworCast(index = 1) {
    // @ts-ignore
    return this.module.fetchCrewCastData(index, this.searchQuery, 10)
      .then((response: any) => {
        this.response = response;
        this.items = response;
        console.log(this.items);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }
}
