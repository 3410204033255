import {DashboardPanel, PanelElement} from '@/stores/Dashboard';
import {BToast} from 'bootstrap-vue';
// @ts-ignore
import i18n from './i18n.js';

export function createItemPanelElement(module: IModule, item: any, type: string = '') {
  const panelElement = new PanelElement();
  panelElement.linkModuleId = module.id!;
  panelElement.linkElementId = type === 'category' ? item.id.toString() : item.id;
  panelElement.name = item.name!;
  panelElement.linkLayout = 'element';
  panelElement.linkAction = type === 'category' ? type : 'play';
  panelElement.image = item.image ? item.image.imageUrl : null;
  panelElement.imageId = item.image ? item.image.id : null;
  panelElement.active = true;
  if (module.name === 'guestFlow') {
    panelElement.linkAction = module.name;
    // @ts-ignore
    panelElement.linkModuleId = module.moduleId;
  }

  if (module.name === 'contact-module') {
    panelElement.linkAction = 'contact-module';
    panelElement.linkModuleId = item.id;
    panelElement.linkElementId = '';

  }

  if (module.type === 'APPLICATION') {
    panelElement.linkLayout = 'application';
    panelElement.linkAction = item.packageName;
    panelElement.linkType = item.type;
    // panelElement.linkElementId = item.packageName;
  } else if (module.type === 'SERIES') {
    panelElement.linkLayout = 'series';
  }
  return panelElement;
}

export function createModulePanelElement(module: IModule) {
  console.log('module', module);
  const panelElement = new PanelElement();
  panelElement.name = module.name;
  // @ts-ignore
  panelElement.linkModuleId = module.id;
  // @ts-ignore
  if (module.name === 'contact-module') {
    panelElement.linkAction = 'ContactModule';
  } else {
    // @ts-ignore
    panelElement.linkAction = typeof module.licenseKey !== 'undefined' ? 'guestFlow' : null;
  }
  panelElement.linkLayout = 'element';
  // @ts-ignore
  panelElement.imageId = module.imageId;
  // @ts-ignore
  panelElement.image = module.image ? module.image.imageUrl : '';
  panelElement.active = true;
  return panelElement;
}

export function createModulesPanel(modules: IModule[], elementType = 'image') {
  const panel = new DashboardPanel();
  panel.name = 'eServices';
  panel.elementType = elementType;
  for (const module of modules) {
    const panelElement = new PanelElement({
      name: module.name,
      linkModuleId: module.id,
      linkLayout: 'element',
      active: true,
    });
    panel.elements.push(panelElement);
  }
  return panel;

}

export function showToaster(variant: any, name: any, message: any, statusCode: any = 500) {
  const bootStrapToaster = new BToast();
  bootStrapToaster.$bvToast.toast(variant === 'success' ? name + ' ' + message : message + ' ' + name, {
    title: variant === 'success' ? 'Success' : 'Error' + ' (' + statusCode + ')',
    variant,
    solid: true,
  });
}
