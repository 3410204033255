import '@babel/polyfill';
import 'axios-progress-bar/dist/nprogress.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import './helpers/brodcastPolyfill.js';
import 'bootstrap-vue';
import {ButtonGroupPlugin, ButtonPlugin, VBPopoverPlugin} from 'bootstrap-vue';
import '@/design/index.scss';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import globalConfig from '@/helpers/globalConfig';
// @ts-ignore
import i18n from './i18n.js';
import App from './App.vue';
import Vue from 'vue';
import VueTransmit from 'vue-transmit';
import draggable from 'vuedraggable';
import router from './router';
import BootstrapVue from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import VueSweetalert2 from 'vue-sweetalert2';
import NevronIcons from '@/components/NevronIcons.vue';
import WebsiteTitle from '@/helpers/WebsiteTitle';
import axios from 'axios';
import stores from '@/stores';
// register the plugin on vue

Vue.use(VueTransmit);
Vue.use(VBPopoverPlugin);
Vue.use(ButtonPlugin);
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'customkey',
  wsHost: 'na.devel.beta.nevron.tv',
  encrypted: true,
  forceTLS: false,
  wsPort: 6001,
  wssPort: 6001,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
});

// translate global function
Vue.use({
  install(vue: any, options: any) {
    Vue.prototype.translate = (key: string, fallBack = null) => {
      if (!i18n.te(key)) {
        if (globalConfig.env === 'development') {
          return `$${key}$`;  // $...$ - sign that localization is not supported
        }
        return '';
      } else {
        return i18n.t(key);
      }
    };
    Vue.prototype.makeKey = (key: string) => {
      return key.toLowerCase().replace(' ', '-');
    };
    Vue.prototype.WebsiteTitle = WebsiteTitle;
  },
});
Vue.config.productionTip = false;
Vue.component('apexchart', VueApexCharts);
Vue.component('icon', NevronIcons);
Vue.use(VueSweetalert2);
Vue.use(BootstrapVue);


new Vue({
  // @ts-ignore
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');

