
import { Component, Vue } from 'vue-property-decorator';
import stores from '@/stores';
import NevronHeader from '@/components/NevronHeader.vue';
import { DashboardPanel, Dashboards } from '@/stores/Dashboard';
import Axios from 'axios';
import AttachImage from '@/modules/Media/Attach.vue';
import axiosInstance from '../../../helpers/axios';
import session from '@/stores/Session';

@Component({
  components: {
    AttachImage,
    NevronHeader,
  },
})
export default class CreatePanelElement extends Vue {
  panel: DashboardPanel = new DashboardPanel();
  dashboard: Dashboards = new Dashboards();
  ele: IPanelElement = {
    id: null,
    dashboardPanelId: null,
    name: '',
    timebar: false,
    linkModuleId: '',
    linkLayout: '',
    linkAction: '',
    linkElementId: '',
    dashboardBackground: null,
    active: true,
    sort: 0,
    image: '',
    imageId: null,
    description: '',
    videoLink: '',
  };
  nameRules = {};
  elementId: any | null;
  activeRules = {};
  sortRules = {};
  imageUrl: string = '';
  attachableContent: any = null;
  contentUrl: any = '';
  selectedFolderId: any = 1;

  mounted() {
    // this.contentUrl = session.project.contentUrl;
    stores.dashboards.getPanelElementValidationRules().then((response) => {
      const validationRules = response;
      if (validationRules[`name`]) {
        this.nameRules = validationRules[`name`];
      }
      if (validationRules[`active`]) {
        this.activeRules = validationRules[`active`];
      }
    });

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');

    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener(
        'submit',
        (event: any) => {
          event.preventDefault();
          if (form.checkValidity() === true) {
            event.stopPropagation();
            this.save();
          }
          if (form.checkValidity() === false) {
            event.stopPropagation();
          }
          form.classList.add('was-validated');
        },
        false,
      );
    });

    this.fetchDashboardPanel(this.$route.params.id);
  }
  // open pop-up to create folder or directory
  folderRequest(res: any) {
    this.selectedFolderId = res.folderId;
    // @ts-ignore
    this.$refs.image.$children[0].close();
    // @ts-ignore
    this.$refs.create.$children[0].open();
  }

  // attach image into panel
  attachWithImage(res: any) {
    this.imageUrl = res.result.imageUrl;
    this.ele.image = this.imageUrl;
    // @ts-ignore
    this.$refs.image.$children[0].close();
    this.getImage(res.result.id);
  }
  getHeaders() {
    return stores.File.getHeader();
  }
  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }
  sendReload(files: any, result: any) {
    this.imageUrl = files.dataUrl;
    this.imageUrl = this.contentUrl + this.imageUrl;
    this.getImage(result.id);
  }
  // get single image
  getImage(id: number) {
    return stores.File.getImage(id)
      .then((response) => {
        this.imageUrl = response.imageUrl;
        this.imageUrl = this.contentUrl + this.imageUrl;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // fetch images for image pop-up
  getImages() {
    return stores.Folder.getFolder(1)
      .then((response) => {
        this.attachableContent = response;
        this.attachableContent.children = this.attachableContent.folders.concat(
          this.attachableContent.files,
        );
        // @ts-ignore
        this.$refs.image.$children[0].open();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // fetch panel data
  fetchDashboardPanel(id: any) {
    stores.dashboards
      .getDashboardPanel(id)
      .then((response) => {
        this.panel = response;
        this.fetchDashboard(this.panel.dashboardId);
      })
      .catch((reason) => {
        console.log('Could Not Found', reason);
        if (!this.panel.id) {
          this.$router.go(-1);
        }
      });
  }
  // fetch Dashboard data
  fetchDashboard(id: any) {
    stores.dashboards.getDashboard(id)
        .then((dashboard) => {
          this.dashboard = dashboard;
        })
        .catch((reason) => {
          console.log('Dashboard Could not found', reason);
        });
  }
  // send data to backend
  save() {
    // console.log(this.ele);
    return stores.dashboards
      .createPanelElement(this.ele, this.panel.id)
      .then((response) => {
        // console.log(response);
        this.elementId = response.id;
        console.log('You have successfully created a new dashboard panel!');
        this.$router.push({ name: 'panel.element.show', params: { id: this.elementId } });
      })
      .catch((e) => {
        console.log(e);
      });
  }
}
