
import {Component, Vue, Watch} from 'vue-property-decorator';
import EditContact from '@/modules/Customers/EditContact.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import PreEdit from '@/modules/Customers/Address/PreEdit.vue';
import {mixin as Clickaway} from 'vue-clickaway';
import AttachImage from '@/modules/Media/Attach.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import _ from 'lodash';
import {deletePath} from '../../../../helpers/DeleteHelper';
import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead.vue';
import session from '../../../../stores/Session';
import axiosInstance from '../../../../helpers/axios';
import NevronInput from '@/components/NevronInput.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';

@Component({
  components: {
    EditContact,
    virtualList,
    PreEdit,
    AttachImage,
    NevronHeader,
    Skeleton,
    NevronSearch,
    VueBootstrapTypeahead,
    NevronInput,
    NevronFooter,
    NevronAdvanceTextBox,
  },
  mixins: [Clickaway],
})

export default class CommentProposalDetails extends Vue {
  commentProposal: any = null;
  document!: IDocument;
  fullRes: any = null;
  fullResponse: any = null;

  tempAcc!: IAccount;

  accountItems: IAccount[] = [];

  response: any = null;
  frontUrl: any  = null;
  backUrl: any  = null;
  search: any = {
    query: '',
  };

  defaultAddress: ICustomerAddress | null = null;
  selectedContact: IContact | null = null;

  indexAccountList: number[] = [];
  allAccountsChecked: boolean = false;
  searchInProgress: boolean = false;
  dragOver: boolean = false;
  preLeave: boolean = false;
  attachableContent: any = null;

  /**
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.fetchData(Number(this.$route.params.id));
  }
  onSubmitStatus() {
    // @ts-ignore
    return stores.Media.updatecommentProposal(this.commentProposal.id, this.commentProposal)
      .then(async (response) => {
        if (response.responseCode === 200) {
          await this.fetchData(Number(this.$route.params.id));
          showToaster('success', Vue.prototype.translate(this.commentProposal.name), 'successfully updated');
        } else {
          showToaster('danger',  Vue.prototype.translate(this.commentProposal.name), 'Fail to update', response.code);
        }
      }).catch((e: any) => {
        console.log(e);
        showToaster('danger',  Vue.prototype.translate(this.commentProposal.name), 'Fail to update');
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.fetchData(Number(this.$route.params.id));
  }

  loadPage() {
    this.fetchData(Number(this.$route.params.id));
  }

  /**
   *
   */
  popoverMethod() {
    if (this.commentProposal && this.commentProposal.id) {
      return deletePath(this.commentProposal.id);
    }
  }
  deleteCommentProposal() {
    return stores.Media.deleteCommentProposal(this.commentProposal.id)
      .then((response: any) => {
        this.$router.go(-1);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
  fetchData(id: number) {
    return stores.Media.getcommentProposalDetail(id)
      .then((response: any) => {
        this.commentProposal = response.data;
        console.log('this.commentProposal', this.commentProposal);
      })
      .catch(() => {
        // this.$router.push({name: 'customers'});
      });

  }

  updateCheck() {
    if (this.indexAccountList.length === this.commentProposal.accounts.length) {
      this.allAccountsChecked = true;
    } else {
      this.allAccountsChecked = false;
    }
  }

}
