
import {Component} from 'vue-property-decorator';
import TabManager from '@/mixins/TabManager';

import ItemsIndex from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
import stores from "@/stores";

@Component({
  computed: {
    stores() {
      return stores
    }
  },
  components: {
    ItemsIndex,
  },
})
export default class DigitalSignageIndex extends TabManager {

  get TAB_KEYS() {
    return ['playlists', 'settings'];
  }

}
