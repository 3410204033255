
import { Component, Vue, Prop } from 'vue-property-decorator';
// @ts-ignore
import stores from '@/stores';
import session from '@/stores/Session';
import axiosInstance from '../../helpers/axios';
import {SweetModal} from 'sweet-modal-vue';

@Component({
  components: {
    SweetModal,
  },
})
export default class CreateEpgSource extends Vue {

  newEpgSource: IEpgSource = {
    id: null,
    source: '',
    active: true,
  };

  createNewEpgSource() {
    return stores.epg.createEpgSource(this.newEpgSource)
    .then((response) => {
        this.cancel();
        this.$emit('created');
    });
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  mounted() {
// Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event: any) => {
        event.preventDefault();
        if (form.checkValidity() === true) {
          event.stopPropagation();
          this.createNewEpgSource();
        }
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }
}
