
import {Component, Vue, Model, Prop, Watch} from 'vue-property-decorator';
import MultiSelect from 'vue-multiselect';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import Axios from 'axios';
import Editor from '@tinymce/tinymce-vue';
import ApplicationsModule from '../../../stores/modules/Applications';
import Skeleton from '@/modules/Skeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import session from '@/stores/Session';
import axiosInstance from '@/helpers/axios';
import NevronInput from '@/components/NevronInput.vue';
import NevronImageField from '@/components/NevronImageField.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';

@Component({
  components: {
    virtualList,
    Editor,
    NevronHeader,
    NevronFooter,
    Skeleton,
    // ClassicEditor,
    MultiSelect,
    NevronInput,
    NevronImageField,
    NevronAdvanceTextBox,
  },
})
export default class ApplicationDetails extends Vue {
  @Prop()
  module!: ApplicationsModule;

  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;

  application: any = {
    moduleId: 0,
    name: null,
    description: null,
    shortDescription: null,
    logo: null,
    active: false,
    image: null,
    };

  allCategories: ICategories[] = [];
  allPackages: IPackage[] = [];

  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  attachableContent: any = null;

  autocompleteItems: any[] = [];
  selectedFolderId: any = 1;
  contentUrl: any = '';
  // editor: any = ClassicEditor;
  imageUrl: any = '';
  autocompleteCatItems: any[] = [];
  catTag: string = '';
  staffEmail: IItemSetting = {
    moduleId: this.module.id,
    itemId: this.application.id,
    key: 'staff_email',
    value: '',
    isTransalatable: true,
  };

  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.loadPage();
  }

  imageSelected(image: IMediaItem) {
    if (this.application) {
      this.application.imageId = (image) ? image.id : null;
    }
  }

  next(nextId: string, e: any) {
    e.preventDefault();
    this.$router.push({ name: 'applications.items.show', params: { id: nextId } });
    this.fetchApplication(Number(nextId));
  }

  previous(previousId: string, e: any) {
    e.preventDefault();
    this.$router.push({ name: 'applications.items.show', params: { id: previousId } });
    this.fetchApplication(Number(previousId));
  }

 get filteredCatItems(): any {
    return this.autocompleteCatItems.filter((i)  => {
        return i.text.toLowerCase().indexOf(this.catTag.toLowerCase()) !== -1;
      });
  }

  detachCategory(a: any) {
   // @ts-ignore
    this.application.tags.splice(a.index, 1);
  }

  attach(a: any) {
     // @ts-ignore
    this.application.tags.push({text: a.tag.text});
    this.catTag = '';
  }

  itemSetting(settings: any) {
    console.log("ItemSettings are here", settings);
    for (const index of settings) {
      if(index.key == 'staff_email') {
        this.staffEmail.value = index.value;
      }
    }
  }

  saveAndReload(p: IApplicationsItem) {
    const settings = [];
    settings.push(this.staffEmail);
    p.itemSettings = settings;
    
    return this.module
      .updateItem(p.id!, p)
      .then(async (response) => {
        if (response.responseCode === 200) {
          await this.fetchApplication(Number(this.$route.params.id));
          // @ts-ignore
          showToaster('success', Vue.prototype.translate(this.application.name), 'successfully updated');
        } else {
          // @ts-ignore
          showToaster('danger',  Vue.prototype.translate(this.application.name), 'Fail to update', response.code);
        }
        this.loadPage();
      })
      .catch((error) => {
        console.log(error);
        showToaster('danger',  Vue.prototype.translate(this.application.name), 'Fail to update');
      });
  }

  getHeaders() {
    return stores.File.getHeader();
  }
  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }
  sendReload(files: any, result: any) {
    this.getImage(result.id);
  }
  getImages() {
    // @ts-ignore
    this.$refs.image.$children[0].open();
  }
  getImage(id: number) {
    return stores.File.getImage(id)
      .then((response) => {
        this.application.image = response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.loadPage();
  }

  loadPage() {
    this.fetchApplication(Number(this.$route.params.id));
    this.getPackages();
    this.getCategories();
    this.contentUrl = '';
  }

  getPackages() {
    stores.Package.getAllPackages()
      .then((response: any) => {
        this.allPackages = response;
        this.allPackages.forEach((item: IPackage) =>
           item.name = Vue.prototype.translate(item.name));
      });
  }

  getCategories() {
    this.module.getAllCategories()
      .then((response: any) => {
        this.allCategories = response.data;
        this.allCategories.forEach((category: ICategories) =>
           category.name = Vue.prototype.translate(category.name));
      });
  }

  fetchApplication(id: number) {
    return this.module.getApplicationDetails(id)
      .then((response) => {
        this.application = response;

        this.application.categories?.forEach( (category: ICategories) =>
           category.name = Vue.prototype.translate(category.name));

        this.application.packages?.forEach( (item: IPackage) =>
           item.name = Vue.prototype.translate(item.name));
           
        // loading Item settings
        this.itemSetting(response.itemSettings);
      })
      .catch((error) => {
        console.log((error));
      });
  }

  removeItem() {
    return this.module.deleteItem(this.application.id)
      .then((response: any) => this.$router.push({name: 'applications.items'}));
  }
}
