import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import {generateLog} from '@/helpers/functions';
import Axios from 'axios';
let cancel: any;
const CancelToken: any = Axios.CancelToken;

const URL: string = '/devices/groups';
// @ts-ignore
export class DeviceGroups extends Model<IDeviceGroup> implements IDeviceGroup {

  id!: number;
  name!: string;
  default!: string;
  active!: boolean;
  sort!: any;
  short_description!: any;
  type!: any;  

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {...{id: 0, name: '', default: '', active: false}, ...attributes}, // Default values
      parentCollection,
    );
  }
  urlRoot(): string {
    return URL;
  }
}

export default class DeviceGroupCollection extends Collection<DeviceGroups> {

  routeName = 'deviceGroup';

  model(): Constructor<DeviceGroups> {
    return DeviceGroups;
  }

  url(): string {
    return URL;
  }

  fetchData(index: number | 1, search: string | null, perpage: number | 10) {
    if (cancel !== undefined) {
      cancel();
    }
    if (index === null) {
      index = 1;
    }
    if (search) {
      return axiosInstance.get(`${this.url()}?search=${search}&page=${index}&perpage=${perpage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    } else {
      return axiosInstance.get(`${this.url()}?page=${index}&perpage=${perpage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    }
  }
  updateItem(id: any, data: any) {
    return axiosInstance.patch(`${this.url()}/${id}`, data)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
  deleteItem(id: number) {
    return axiosInstance.delete(`${this.url()}/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
  editGroup(id: number) {
    return axiosInstance.get(`${this.url()}/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  deleteItems(ids: number[]) {
    return axiosInstance.post(`${this.url()}/delete`, ids)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
  createDeviceGroup(type: IDeviceGroup) {
    return axiosInstance.post(`${this.url()}`, type)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
}
