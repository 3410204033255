
import {Component, Vue, Watch} from 'vue-property-decorator';
import EditContact from '@/modules/Customers/EditContact.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import EditCustomer from '@/modules/Customers/EditCustomer.vue';
import PreEdit from '@/modules/Customers/Address/PreEdit.vue';
import {mixin as Clickaway} from 'vue-clickaway';
import CustomerAddressEdit from '@/modules/Customers/Address/Edit.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import _ from 'lodash';
import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead.vue';
import NevronInput from '@/components/NevronInput.vue';

import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import axiosInstance from '@/helpers/axios';
import session from '@/stores/Session';
import NevronImageField from '@/components/NevronImageField.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';

@Component({
  components: {
    EditContact,
    virtualList,
    EditCustomer,
    PreEdit,
    // AccountAttach,
    CustomerAddressEdit,
    AttachImage,
    NevronHeader,
    Skeleton,
    NevronSearch,
    VueBootstrapTypeahead,
    NevronInput,

    NevronFooter,
    NevronImageField,
    NevronAdvanceTextBox,
  },
  mixins: [Clickaway],
})

export default class CustomerDetails extends Vue {
  types: any = null;
  dragOver: boolean = false;
  preLeave: boolean = false;
  module = stores.StayTypes;

  /**
   *
   */
  /**
   *
   */
  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }

  imageSelected(image: IMediaItem) {
    if (this.types) {
      this.types.imageId = (image) ? image.id : null;
    }
  }

  getHeaders() {
    return stores.File.getHeader();
  }
  transmitFinish(files: any, res: any) {
    this.preLeave = false;
    this.dragOver = false;
    this.attachWithImage({result: res});
  }
  dragLeave(num: number) {
    if (this.preLeave) {
      this.preLeave = false;
    } else {
      this.preLeave = false;
      this.dragOver = false;
    }
    console.log('LEAVE', num);
  }
  dragEnter(num: number) {
    this.dragOver = true;
    this.preLeave = true;
    console.log('ENTER', num);
  }
  attachWithImage(imgObj: any) {
    const data = {
      imageId: null,
    };
    if (imgObj) {
      this.types.imageId = imgObj.result.id;
      this.types.image = this.types.image || {};
      this.types.image.imageUrl = imgObj.result.imageUrl;
      // @ts-ignore
      this.$refs.image.$children[0].close();
      data.imageId = imgObj.result.id;
    } else {
      this.types.imageId = null;
      this.types.image = null;
    }
    // @ts-ignore
    return stores.Stays.updateCategory(this.types.id, data)
      .then((response: any) => {
        console.log('Success');
        this.loadPage();
      })
      .catch((e: any) => {
        console.log(e);
      });
  }
  /**
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.fetchData(Number(this.$route.params.id));
  }
  /*
   *
   */
  onSubmitStatus() {
    // @ts-ignore
    return stores.Stays.updateCategory(this.types.id, this.types)
      .then(async (response) => {
        if (response.responseCode === 200) {
          await this.fetchData(Number(this.$route.params.id));
          showToaster('success', Vue.prototype.translate(this.types.name), 'successfully updated');
        } else {
          showToaster('danger',  Vue.prototype.translate(this.types.name), 'Fail to update', response.code);
        }
        this.loadPage();
      }).catch((e: any) => {
        console.log(e);
        showToaster('danger',  Vue.prototype.translate(this.types.name), 'Fail to update');
      });
  }

  mounted() {
    this.fetchData(Number(this.$route.params.id));
  }

  loadPage() {
    this.fetchData(Number(this.$route.params.id));
  }

  fetchData(id: number) {
    return stores.Stays.getTypesDetail(id)
      .then((response: any) => {
        this.types = response.data;
        console.log('this.types', this.types);
      })
      .catch(() => {
        // this.$router.push({name: 'customers'});
      });

  }
  deleteTypes() {
    return stores.Stays.deleteCategory(Number(this.$route.params.id))
      .then(() => {
        this.$router.push({name: 'stayTypes'});
      })
      .catch((reason) => {
        console.error('Could not delete', reason);
      });
  }
}
