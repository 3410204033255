import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import {generateLog} from '@/helpers/functions';
import Axios from 'axios';
let cancel: any;
const CancelToken: any = Axios.CancelToken;

const URL: string = '/devices';
// @ts-ignore
export class DeviceTypes extends Model<IDeviceType> implements IDeviceType {

  id!: number;
  name!: string;
  default!: DeviceType;
  active!: boolean;

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {...{id: 0, name: '', default: '', active: false}, ...attributes}, // Default values
      parentCollection,
    );
  }
  urlRoot(): string {
    return URL;
  }
}

export default class DeviceTypesCollection extends Collection<DeviceTypes> {

  routeName = 'deviceType';

  model(): Constructor<DeviceTypes> {
    return DeviceTypes;
  }

  url(): string {
    return URL;
  }

  fetchData(index: number | 1, search: string | null, perpage: number | 10) {
    if (cancel !== undefined) {
      cancel();
    }
    if (index === null) {
      index = 1;
    }
    if (search) {
      return axiosInstance.get(`${this.url()}/types?search=${search}&page=${index}&perpage=${perpage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    } else {
      return axiosInstance.get(`${this.url()}/types?page=${index}&perpage=${perpage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    }
  }
  updateItem(id: any, data: any) {
    return axiosInstance.patch(`${this.url()}/types/${id}`, data)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
  deleteItem(id: number) {
    console.log("delecting item");
    return axiosInstance.delete(`${this.url()}/types/${id}`)
      .then((response) => {
        console.log("delected item", response.data);
        return response.data;
      }).catch((e) => {
        console.log("not delected item", e);
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  deleteItems(ids: number[]) {
    return axiosInstance.post(`${this.url()}/types/delete`, ids)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
  createDeviceType(type: IDeviceType) {
    return axiosInstance.post(`${this.url()}/types`, type)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
}
