import Axios from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
const URL: string = '/configuration/list';

export class MailConfiguration extends Model<IMailConfiguration> implements IMailConfiguration {
  id!: number|null;
  mailType!: string;
  mailHost!: string | null;
  mailPort!: number | null;
  mailUsername!: string;
  mailPassword!: string;
  mailEncryption!: string;
  mailFromName!: string;
  mailFromAddress!: string;
  mailToAddress!: string;

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      // {...{id: 0, email: '', enabled: false, languageId: 0}, ...attributes}, // Default values
      {...{id: 0, mailType: '', mailHost: '', mailPort: '', mailUsername: '', mailPassword: null, mailEncryption: '', mailFromName: '', mailFromAddress: '', mailToAddress: ''}, ...attributes}, // Default values
      parentCollection,
    );
  }

  urlRoot(): string {
    return URL;
  }
}

export default class MailCollection extends Collection<MailConfiguration> {

  model(): Constructor<MailConfiguration> {
    return MailConfiguration;
  }

  url(): string {
    return URL;
  }

  fetchMailData() {
    return axiosInstance.get(`${this.url()}/mail`)
      .then((response) => response.data);
  }

  fetchSmsData() {
    return axiosInstance.get(`${this.url()}/sms`)
      .then((response) => response.data);
  }

  update(mail: any) {
    return axiosInstance.post(`configuration/save`, mail)
      .then((response) => response.data);
  }

}
