
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
})
export default class VODSources extends Vue {
  @Prop() source!: IVIDEOSource | null;
  @Prop() imageSource!: any;
  newSource: IVIDEOSource | null = null;

  @Watch('source', {immediate: true})
  setModuleFromProps() {

    if (this.source) {
      this.newSource = JSON.parse(JSON.stringify(this.source));
    } else {
      this.newSource = {
        id: null,
        movieId: null,
        source: '',
        quality: null,
        mediaType: 'stream-url',
        sort: null,
      };
    }
  }

  openMediaLibrary() {
    this.$emit('openMediaLibrary');
  }

  checkStreamUrl() {
    this.newSource!.source = '';
  }

  save(): void {
    if (this.newSource) {
      if (this.source === null) {
        this.$emit('saveNew', this.newSource);
      } else {
        this.$emit('saveEdit', this.newSource);
      }
      // @ts-ignore
      this.$children[0].close();
    }
  }

  cancel(): void {
    this.$emit('cancel');
    // @ts-ignore
    this.$children[0].close();
  }

  @Watch('imageSource', {immediate: true})
  imageSources() {
    this.newSource!.source = this.imageSource;
  }

}
