
  import {Component, Vue} from 'vue-property-decorator';
  import stores from '@/stores';
  import NevronHeader from '@/components/NevronHeader.vue';
  import {DashboardPanel, Dashboards, PanelElement} from '@/stores/Dashboard';
  import NevronClickConfirm from '@/components/NevronClickConfirm.vue';
  import Axios from 'axios';
  import AttachImage from '@/modules/Media/Attach.vue';
  import axiosInstance from '../../../helpers/axios';
  import session from '@/stores/Session';
  import NevronInput from '@/components/NevronInput.vue';

  @Component({
    components: {
      NevronHeader,
      NevronClickConfirm,
      AttachImage,
      NevronInput,
    },
  })
  export default class ShowPanelElement extends Vue {
    ele: PanelElement = new PanelElement();
    panel: DashboardPanel = new DashboardPanel();
    dashboard: Dashboards = new Dashboards();
    imageUrl: string = '';
    attachableContent: any = null;
    contentUrl: any = '';
    selectedFolderId: any = 1;

    mounted() {
      this.fetchData(this.$route.params.id);
      // this.contentUrl = session.project.contentUrl;
    }

    onSubmit() {
      if (this.ele) {
        // preparing data to send
        const data = {
          name: this.ele.name,
          active: this.ele.active,
          linkModuleId: this.ele.linkModuleId,
          linkElementId: this.ele.linkElementId,
          linkLayout: this.ele.linkLayout,
          linkAction: this.ele.linkAction,
          image: this.ele.image,
          sort: this.ele.sort,
          videoLink: this.ele.videoLink,
        };

        // request to backend
        return stores.dashboards
          .updatePanelElement(this.ele.id, data)
          .then((response) => {
            console.log('Success');
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }

    fetchData(id: string) {
      stores.dashboards
        .getPanelElement(id)
        .then((elementResponse) => {
          this.ele = elementResponse;
          this.fetchPanel(elementResponse.dashboardPanelId);
          if (this.ele.image) {
            this.imageUrl = this.ele.image;
          }
        })
        .catch((reason) => {
          console.log('Panel Element Could not found', reason);
          if (!this.ele.id) {
            this.$router.go(-1);
          }
        });
    }

    fetchPanel(id: string) {
      stores.dashboards
        .getDashboardPanel(id)
        .then((panel) => {
          this.panel = panel;
          this.fetchDashboard(panel.dashboardId);
        })
        .catch((reason) => {
          console.log('Panel Could not found', reason);
        });
    }

    fetchDashboard(id: string) {
      stores.dashboards
        .getDashboard(id)
        .then((dashboard) => {
          this.dashboard = dashboard;
        })
        .catch((reason) => {
          console.log('dashboard Could not found', reason);
        });
    }

    // open pop-up to create folder or directory
    folderRequest(res: any) {
      this.selectedFolderId = res.folderId;
      // @ts-ignore
      this.$refs.image.$children[0].close();
      // @ts-ignore
      this.$refs.create.$children[0].open();
    }

    // attach image into panel
    attachWithImage(res: any) {
      this.imageUrl = res.result.imageUrl;
      this.ele.image = this.imageUrl;
      // @ts-ignore
      this.$refs.image.$children[0].close();
      this.getImage(res.result.id);
    }

    getHeaders() {
      return stores.File.getHeader();
    }

    getFilePath(): string {
      return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
    }

    sendReload(files: any, result: any) {
      this.imageUrl = files.dataUrl;
      this.imageUrl = this.contentUrl + this.imageUrl;
      this.getImage(result.id);
    }

    // get single image
    getImage(id: number) {
      return stores.File.getImage(id)
        .then((response) => {
          this.imageUrl = response.imageUrl;
          this.imageUrl = this.contentUrl + this.imageUrl;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // fetch images for image pop-up
    getImages() {
      return stores.Folder.getFolder(1)
        .then((response) => {
          this.attachableContent = response;
          this.attachableContent.children = this.attachableContent.folders.concat(
            this.attachableContent.files,
          );
          // @ts-ignore
          this.$refs.image.$children[0].open();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
