
import {Component, Vue} from 'vue-property-decorator';
import stores from '../../stores';
import axiosInstance from '../../helpers/axios';
import editMedia from '@/modules/Media/Edit.vue';
import NevronHeader from '@/components/NevronHeader.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    editMedia,
    NevronHeader,
  },
})

export default class MediaDetails extends Vue {
  image: any = null;
  imageDetails: IMediaItem = {
    id: null,
    updatedAt: '',
    type: 'file',
    mediaFolderId: 0,
    name: '',
    path: '',
    children: [],
    folderName: null,
    fileName: null,
    active: true,
    imageUrl: '',
  };
  contentUrl: any = '';
  newName: string = '';

  get showPath() {
    const pathArray = this.imageDetails.path.split('/');
    pathArray.pop();
    return (pathArray.length > 1) ? pathArray.join('/') + '/' : '/';
  }

  getHeaders() {
    return stores.File.getHeader();
  }
  isImageFile(fileExtension: any) {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'eps', 'bmp', 'tiff', 'tif', 'svg', 'raw'];
    return imageExtensions.includes(fileExtension);
  };
  isVideoFile(fileExtension: any) {
    const videoExtensions = ['mp4', 'mov', 'avi', 'wmv', 'webm', 'mkv', 'mpeg-4', 'ogg'];
    return videoExtensions.includes(fileExtension);
  };
  videoType(type: any) {
    return 'video/' + type;
  }
  remove() {
    if (confirm('Are you certain you want to delete this image?') && this.imageDetails.id) {
      return stores.File.deleteFiles(this.imageDetails.id)
        .then((response: any) => {
          this.$router.push({
            name: 'media.folder',
            params: {folderId: String(this.imageDetails.mediaFolderId)},
          });
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  getImage(id: number) {
    return stores.File.getImage(id)
      .then((response: any) => {
        this.imageDetails = response;
        this.image = response.imageUrl;
        this.imageDetails.updatedAt = this.imageDetails.updatedAt.split('.')[0];
        Vue.prototype.WebsiteTitle.setModuleName('media-library');
        Vue.prototype.WebsiteTitle.setTranslatedPageName(this.imageDetails.name);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  save() {
    return stores.File.updateImage(this.imageDetails)
      .then((response: any) => {
        this.imageDetails = response.data;
        this.image = response.data.imageUrl;
        this.imageDetails.updatedAt = this.imageDetails.updatedAt.split('.')[0];
        showToaster('success', Vue.prototype.translate(this.imageDetails.name), 'successfully updated');
      })
      .catch((error: any) => {
        showToaster('danger', Vue.prototype.translate(this.imageDetails.name), 'Fail to update');
        console.log(error);
      });
  }

  getFilePath(id: number | null): string {
    return axiosInstance.defaults.baseURL + '/' + stores.File.uploadUrl() + '/' + id;
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.getImage(Number(this.$route.params.fileId));
    // this.contentUrl = session.project.contentUrl;
  }

  /**
   * Called when changes invalid image source url
   */
  onImageLoadFailure(event: any) {
    event.target.src = require('../../../assets/no-image.png');
  }
}
